import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

export const getCheckInDetails = async ({ id }) => {
  const response = await adminAPI.get(`${ENDPOINT.CHECK_INS}/${id}`, {
    headers: authHeader()
  });

  return response;
};

export default getCheckInDetails;
