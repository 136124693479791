import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

/**
 * Handle request server to get list of spots
 * @param {*} param
 * @returns response
 */
export const getListPrices = async ({ key = '', offset = 0, limit = 10 }) => {
  const response = await adminAPI.get(ENDPOINT.PRICES, {
    params: { key, offset, limit },
    headers: authHeader()
  });

  return response;
};

export const getPriceDetails = async ({ priceId, stripeId }) => {
  const response = await adminAPI.get(`${ENDPOINT.PRICES}/${priceId}?sk=${stripeId}`, {
    headers: authHeader()
  });

  return response;
};

/**
 * Handle request server update profile for user
 * @param {*} param0
 * @returns
 */
export const updatePrice = async ({
  priceID,
  tier,
  stripeId,
}) => {
  const response = await adminAPI.put(
    `${ENDPOINT.PRICES}/${priceID}`,
    {
      tier,
      stripeId,
    },
    { headers: authHeader() }
  );

  return response;
};
