import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

import { Types } from '../actions/serviceFeeOffsetsAction';

export const INITIAL_STATE = Immutable({
  isLoading: false,
  serviceFeeOffsetsData: [],
  serviceFeeOffsetData: {},
  error: null,
});

const getListServiceFeeOffsetsRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListServiceFeeOffsetsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    serviceFeeOffsetsData: data
  });
};

const getListServiceFeeOffsetsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    serviceFeeOffsetsData: []
  });
};

const getServiceFeeOffsetRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getServiceFeeOffsetSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    serviceFeeOffsetData: data
  });
};

const getServiceFeeOffsetFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    serviceFeeOffsetData: {}
  });
};

const updateServiceFeeOffsetRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const updateServiceFeeOffsetSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    serviceFeeOffsetData: data
  });
};

const updateServiceFeeOffsetFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    serviceFeeOffsetData: {}
  });
};

const createServiceFeeOffsetRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null,
    serviceFeeOffsetData: {}
  });
};

const createServiceFeeOffsetSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    serviceFeeOffsetData: data
  });
};

const createServiceFeeOffsetFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    serviceFeeOffsetData: {}
  });
};

const removeServiceFeeOffsetRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null,
    serviceFeeOffsetData: {}
  });
};

const removeServiceFeeOffsetSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    serviceFeeOffsetData: data
  });
};

const removeServiceFeeOffsetFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    serviceFeeOffsetData: {}
  });
};

export const HANDLERS = {
  [Types.GET_LIST_SERVICE_FEE_OFFSETS_REQUEST]: getListServiceFeeOffsetsRequest,
  [Types.GET_LIST_SERVICE_FEE_OFFSETS_SUCCESS]: getListServiceFeeOffsetsSuccess,
  [Types.GET_LIST_SERVICE_FEE_OFFSETS_FAILED]: getListServiceFeeOffsetsFailed,

  [Types.GET_SERVICE_FEE_OFFSET_REQUEST]: getServiceFeeOffsetRequest,
  [Types.GET_SERVICE_FEE_OFFSET_SUCCESS]: getServiceFeeOffsetSuccess,
  [Types.GET_SERVICE_FEE_OFFSET_FAILED]: getServiceFeeOffsetFailed,

  [Types.UPDATE_SERVICE_FEE_OFFSET_REQUEST]: updateServiceFeeOffsetRequest,
  [Types.UPDATE_SERVICE_FEE_OFFSET_SUCCESS]: updateServiceFeeOffsetSuccess,
  [Types.UPDATE_SERVICE_FEE_OFFSET_FAILED]: updateServiceFeeOffsetFailed,

  [Types.CREATE_SERVICE_FEE_OFFSET_REQUEST]: createServiceFeeOffsetRequest,
  [Types.CREATE_SERVICE_FEE_OFFSET_SUCCESS]: createServiceFeeOffsetSuccess,
  [Types.CREATE_SERVICE_FEE_OFFSET_FAILED]: createServiceFeeOffsetFailed,

  [Types.REMOVE_SERVICE_FEE_OFFSET_REQUEST]: removeServiceFeeOffsetRequest,
  [Types.REMOVE_SERVICE_FEE_OFFSET_SUCCESS]: removeServiceFeeOffsetSuccess,
  [Types.REMOVE_SERVICE_FEE_OFFSET_FAILED]: removeServiceFeeOffsetFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
