import ENDPOINT from './ApiEndpoint';
import { exhibServiceAdminAPI, authHeader } from './apiExhibitorAdminConfig';

export const getServiceFeeOffsets = async () => {
  const response = await exhibServiceAdminAPI.get(`v1${ENDPOINT.SERVICE_FEE}${ENDPOINT.OFFSETS}`, {
    headers: authHeader()
  });

  return response;
};

export const getServiceFeeOffset = async ({ id }) => {
  const response = await exhibServiceAdminAPI.get(`v1${ENDPOINT.SERVICE_FEE}${ENDPOINT.OFFSETS}/${id}`, {
    headers: authHeader()
  });

  return response;
};

export const updateServiceFeeOffset = async ({
  id,
  typeId,
  type,
  offset,
}) => {
  const response = await exhibServiceAdminAPI.put(
    `v1${ENDPOINT.SERVICE_FEE}${ENDPOINT.OFFSETS}/${id}`, {
      typeId,
      type,
      offset,
    },
    { headers: authHeader() }
  );

  return response;
};

export const createServiceFeeOffset = async ({
  typeId,
  type,
  offset,
}) => {
  const response = await exhibServiceAdminAPI.post(
    `v1${ENDPOINT.SERVICE_FEE}${ENDPOINT.OFFSETS}`, {
      typeId,
      type,
      offset,
    },
    { headers: authHeader() }
  );

  return response;
};

export const removeServiceFeeOffset = async ({
  id
}) => {
  const response = await exhibServiceAdminAPI.delete(
    `v1${ENDPOINT.SERVICE_FEE}${ENDPOINT.OFFSETS}/${id}`, {
      headers: authHeader()
    }
  );

  return response;
};

export const getServiceFeeForTheater = async ({
  id,
}) => {
  const response = await exhibServiceAdminAPI.get(
    `v1/${ENDPOINT.THEATERS}/${id}${ENDPOINT.SERVICE_FEE}`,
    { headers: authHeader() }
  );

  return response;
};
