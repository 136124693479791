import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

/**
 * Handle request server to get list of surveys
 * @param {*} param
 * @returns response
 */
export const getListSurveys = async ({ key = '', offset = 0, limit = 10 }) => {
  const response = await adminAPI.get(ENDPOINT.SURVEYS, {
    params: { key, offset, limit },
    headers: authHeader()
  });

  return response;
};

/**
 * Handle request server to get survey details
 * @param {*} param
 * @returns response
 */
export const getSurveyDetails = async ({ surveyId }) => {
  const response = await adminAPI.get(`${ENDPOINT.SURVEYS}/${surveyId}`, {
    headers: authHeader()
  });

  return response;
};

/**
 * Handle request server to update basic info of Survey (update name)
 * @param {*} param
 * @returns response
 */
export const updateNameSurvey = async ({ id, name }) => {
  const response = await adminAPI.put(
    `${ENDPOINT.SURVEYS}/${id}/name`,
    {
      name
    },
    { headers: authHeader() }
  );

  return response;
};
