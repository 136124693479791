import { connect } from 'react-redux';
import DashboardLayout from 'src/layouts/DashboardLayout';

// reducers
import { Creators as CreatorsAuth } from 'src/redux/actions/authAction';

const mapStateToProps = ({ authReducer }) => {
  const {
    user: { name }
  } = authReducer;

  return {
    name
  };
};

const mapDispatchToProps = {
  logoutRequest: CreatorsAuth.logoutRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
