// libs
import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getListOffersRequest: ['params'],
  getListOffersSuccess: ['data'],
  getListOffersFailed: ['error'],

  updateOfferRequest: ['params', 'callback'],
  updateOfferSuccess: ['data'],
  updateOfferFailed: ['error'],

  addPreshowToOfferRequest: ['params', 'callback'],
  addPreshowToOfferSuccess: ['data'],
  addPreshowToOfferFailed: ['error'],

  getOfferDetailRequest: ['params'],
  getOfferDetailSuccess: ['data'],
  getOfferDetailFailed: ['error']
});
