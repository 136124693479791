import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getListItemsRequest: ['params'],
  getListItemsSuccess: ['data'],
  getListItemsFailed: ['error'],

  getOffersForItemRequest: ['params'],
  getOffersForItemSuccess: ['data'],
  getOffersForItemFailed: ['error'],

  getItemDetailsRequest: ['params'],
  getItemDetailsSuccess: ['data'],
  getItemDetailsFailed: ['error']
});
