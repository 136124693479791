import { put, call, takeLatest } from 'redux-saga/effects';

// reducers
import { Types, Creators } from 'src/redux/actions/theaterChainsAction';

// apis
import {
  getListTheaterChains,
  addTheaterChain,
  updateTheaterChain,
  getListTheatersForTheaterChain,
} from 'src/api-client/theaterChainsApi';

function* getListTheaterChainsRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getListTheaterChains, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getListTheaterChainsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListTheaterChainsFailed(error || e));
  }
}

function* addTheaterChainRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(addTheaterChain, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.addTheaterChainSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.addTheaterChainFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* updateTheaterChainRequest(action) {
  const { params } = action;

  try {
    const response = yield call(updateTheaterChain, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.updateTheaterChainSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateTheaterChainFailed(error || e));
  }
}

function* getListTheatersForTheaterChainRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getListTheatersForTheaterChain, params);
    yield put(Creators.getListTheatersForTheaterChainSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateTheaterFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

export default function* watchTheaterChainsRequest() {
  yield takeLatest(Types.GET_LIST_THEATER_CHAINS_REQUEST, getListTheaterChainsRequest);
  yield takeLatest(Types.ADD_THEATER_CHAIN_REQUEST, addTheaterChainRequest);
  yield takeLatest(Types.UPDATE_THEATER_CHAIN_REQUEST, updateTheaterChainRequest);
  yield takeLatest(Types.GET_LIST_THEATERS_FOR_THEATER_CHAIN_REQUEST,
    getListTheatersForTheaterChainRequest);
}
