// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from '../actions/moviesAction';

export const INITIAL_STATE = Immutable({
  error: null,
  isLoading: false,
  moviesData: {
    total: 0,
    movies: []
  },
  movieDetails: {},
  moviesHomeList: {},
  moviesCarouselList: [],
  addMovieHomeListError: null,
  addMovieCarouselError: null,
  addMoviePosterError: null
});

const getListMoviesRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListMoviesSuccess = (state, action) => {
  const {
    data: { total, movies }
  } = action;

  return merge(state, {
    isLoading: false,
    moviesData: {
      total,
      movies
    }
  });
};

const getListMoviesFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    moviesData: {
      total: 0,
      movies: []
    },
    movieDetails: {}
  });
};

const getMovieRequest = (state) => {
  return merge(state, {
    isLoading: true,
    movieDetails: {},
  });
};

const getMovieSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    movieDetails: data
  });
};

const getMovieFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    movieDetails: {}
  });
};

const updateMovieRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null,
  });
};

const updateMovieSuccess = (state, action) => {
  const { data } = action;
  const { id } = data;

  const movieIndex = state.moviesData.movies.findIndex((movie) => movie.id === id);
  if (movieIndex === -1) {
    return state;
  }

  const updatedMovie = data;

  const updatedMovies = [
    ...state.moviesData.movies.slice(0, movieIndex),
    updatedMovie,
    ...state.moviesData.movies.slice(movieIndex + 1)
  ];

  return merge(state, {
    moviesData: {
      ...state.moviesData,
      movies: updatedMovies
    },
    movieDetails: updatedMovie,
    isLoading: false,
  });
};

const updateMovieFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    movieDetails: {},
    isLoading: false
  });
};

const getMoviesHomeListRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getMoviesHomeListSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    moviesHomeList: data
  });
};

const getMoviesHomeListFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    moviesHomeList: {}
  });
};

const addMoviesHomeListRequest = (state) => {
  return merge(state, {
    isLoading: true,
    addMovieHomeListError: null
  });
};

const addMoviesHomeListSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    moviesHomeList: data,
    isLoading: false,
    addMovieHomeListError: null
  });
};

const addMoviesHomeListFailed = (state) => {
  return merge(state, {
    moviesHomeList: {},
    addMovieHomeListError: 'Error',
    isLoading: false
  });
};

/**
 * Handle request add a movie home list
 * @param {*} state
 * @param {*} action
 * @returns
 */
const addMoviePosterRequest = (state) => {
  return merge(state, {
    isLoading: true,
    addMoviePosterError: null
  });
};

const addMoviePosterSuccess = (state, action) => {
  console.log(`action: ${action}`);

  return merge(state, {
    isLoading: false,
    addMoviePosterError: null
  });
};

const addMoviePosterFailed = (state) => {
  return merge(state, {
    addMoviePosterError: 'Error',
    isLoading: false
  });
};

const getMoviesCarouselRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getMoviesCarouselSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    moviesCarousel: data
  });
};

const getMoviesCarouselFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    moviesCarousel: {}
  });
};

const addMoviesCarouselRequest = (state) => {
  return merge(state, {
    isLoading: true,
    addMovieCarouselError: null
  });
};

const addMoviesCarouselSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    moviesHomeList: data,
    isLoading: false,
    addMovieCarouselError: null
  });
};

const addMoviesCarouselFailed = (state) => {
  return merge(state, {
    moviesHomeList: {},
    addMovieCarouselError: 'Error',
    isLoading: false
  });
};

const updateMovieStatusRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const updateMovieStatusSuccess = (state, action) => {
  const { data } = action;
  const { movieId, status } = data;

  const movieIndex = state.moviesData.movies.findIndex((movie) => movie.id === movieId);
  if (movieIndex === -1) {
    return state;
  }

  const updatedMovie = {
    ...state.moviesData.movies[movieIndex],
    status
  };

  const updatedMovies = [
    ...state.moviesData.movies.slice(0, movieIndex),
    updatedMovie,
    ...state.moviesData.movies.slice(movieIndex + 1)
  ];

  return merge(state, {
    moviesData: {
      ...state.moviesData,
      movies: updatedMovies
    },
    isLoading: false,
  });
};

const updateMovieStatusFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
  });
};

export const HANDLERS = {
  [Types.GET_LIST_MOVIES_REQUEST]: getListMoviesRequest,
  [Types.GET_LIST_MOVIES_SUCCESS]: getListMoviesSuccess,
  [Types.GET_LIST_MOVIES_FAILED]: getListMoviesFailed,

  [Types.GET_MOVIE_REQUEST]: getMovieRequest,
  [Types.GET_MOVIE_SUCCESS]: getMovieSuccess,
  [Types.GET_MOVIE_FAILED]: getMovieFailed,

  [Types.UPDATE_MOVIE_REQUEST]: updateMovieRequest,
  [Types.UPDATE_MOVIE_SUCCESS]: updateMovieSuccess,
  [Types.UPDATE_MOVIE_FAILED]: updateMovieFailed,

  [Types.GET_MOVIES_HOME_LIST_REQUEST]: getMoviesHomeListRequest,
  [Types.GET_MOVIES_HOME_LIST_SUCCESS]: getMoviesHomeListSuccess,
  [Types.GET_MOVIES_HOME_LIST_FAILED]: getMoviesHomeListFailed,

  [Types.ADD_MOVIES_HOME_LIST_REQUEST]: addMoviesHomeListRequest,
  [Types.ADD_MOVIES_HOME_LIST_SUCCESS]: addMoviesHomeListSuccess,
  [Types.ADD_MOVIES_HOME_LIST_FAILED]: addMoviesHomeListFailed,

  [Types.ADD_MOVIE_POSTER_REQUEST]: addMoviePosterRequest,
  [Types.ADD_MOVIE_POSTER_SUCCESS]: addMoviePosterSuccess,
  [Types.ADD_MOVIE_POSTER_FAILED]: addMoviePosterFailed,

  [Types.GET_MOVIES_CAROUSEL_REQUEST]: getMoviesCarouselRequest,
  [Types.GET_MOVIES_CAROUSEL_SUCCESS]: getMoviesCarouselSuccess,
  [Types.GET_MOVIES_CAROUSEL_FAILED]: getMoviesCarouselFailed,

  [Types.ADD_MOVIES_CAROUSEL_REQUEST]: addMoviesCarouselRequest,
  [Types.ADD_MOVIES_CAROUSEL_SUCCESS]: addMoviesCarouselSuccess,
  [Types.ADD_MOVIES_CAROUSEL_FAILED]: addMoviesCarouselFailed,

  [Types.UPDATE_MOVIE_STATUS_REQUEST]: updateMovieStatusRequest,
  [Types.UPDATE_MOVIE_STATUS_SUCCESS]: updateMovieStatusSuccess,
  [Types.UPDATE_MOVIE_STATUS_FAILED]: updateMovieStatusFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
