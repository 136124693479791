import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

import cleanObject from '../helper/cleanObject';

/**
 * Handle request server to get list of offer
 * @param {*} param
 * @returns response
 */
export const getListOffers = async ({
  key = '',
  status = '',
  offset = 0,
  limit = 10
}) => {
  const params = { key, offset, limit, status };
  const newParams = cleanObject(params, 'status');
  const response = await adminAPI.get(ENDPOINT.OFFERS, {
    params: newParams,
    headers: authHeader()
  });

  return response;
};

/**
 * Handle request server update offers
 * @param {*} params
 * @returns response
 */
export const updateOffers = async ({ id, name, itemId, description }) => {
  const response = await adminAPI.put(
    `${ENDPOINT.OFFERS}/${id}`,
    {
      id,
      name,
      itemId,
      description
    },
    { headers: authHeader() }
  );

  return response;
};

/**
 * Handle add preshow to offer
 * @param {*} param0
 * @returns
 */
export const addPreshowToOffer = async ({
  preshowId,
  offerId,
  name,
  currency,
  amount,
  expirationDate
}) => {
  const response = await adminAPI.post(
    ENDPOINT.ADD_OFFER_REWARDS,
    {
      preshowId,
      offerId,
      name,
      currency,
      amount,
      expirationDate
    },
    { headers: authHeader() }
  );

  return response;
};

export const getOfferDetail = async ({ offerID }) => {
  const response = await adminAPI.get(`${ENDPOINT.OFFERS}/${offerID}/detail`, {
    headers: authHeader()
  });

  return response;
};
