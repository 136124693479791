import { put, call, takeLatest } from 'redux-saga/effects';

// reducers
import { Types, Creators } from 'src/redux/actions/pricesAction';

// apis
import { getListPrices, getPriceDetails, updatePrice } from 'src/api-client/pricesApi';

/**
 * Handle get list of Postal Codes
 * @param {*} action
 */
function* getListPricesRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getListPrices, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getListPricesSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListPricesFailed(error || e));
  }
}

/**
 * Handle get Postal Code details
 * @param {*} action
 */
function* getPriceDetailsRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getPriceDetails, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getPriceDetailsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getPriceDetailsFailed(error || e));
  }
}

/**
 * Handle update postal code
 */
function* updatePriceRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updatePrice, params);
    yield put(Creators.updatePriceSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updatePriceFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

export default function* watchPricesRequest() {
  yield takeLatest(Types.GET_LIST_PRICES_REQUEST, getListPricesRequest);
  yield takeLatest(Types.GET_PRICE_DETAILS_REQUEST, getPriceDetailsRequest);
  yield takeLatest(Types.UPDATE_PRICE_REQUEST, updatePriceRequest);
}
