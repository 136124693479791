import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from '../actions/ticketTypesAction';

export const INITIAL_STATE = Immutable({
  error: null,
  isLoading: false,
  ticketTypes: [],
  ticketTypesForTheater: [],
  updateTicketTypeError: null,
  ticketTypesForTheaterError: null,
  importTicketTypesError: null,
});

const getListTicketTypesRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListTicketTypesSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    ticketTypes: data
  });
};

const getListTicketTypesFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    ticketTypes: []
  });
};

const updateTicketTypeRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const updateTicketTypeSuccess = (state, action) => {
  const { data } = action;
  const newTicketTypes = [...state.ticketTypes];
  const updatedTicketType = newTicketTypes.map((ticketType) =>
    (ticketType.ticketTypeID === data.id && ticketType.price === data.price &&
      ticketType.theaterId === data.ticketType) ? {
        status: data.status,
        credits: data.credits,
        ticketBucket: data.ticketBucket,
        ...data
      } : ticketType
  );

  return merge(state, {
    ticketTypes: updatedTicketType,
    isLoading: false,
    updateTicketTypeError: null
  });
};

const updateTicketTypeFailed = (state) => {
  return merge(state, {
    updateTicketTypeError: 'Error',
    isLoading: false
  });
};

const getListTicketTypesForProviderTheaterRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListTicketTypesForProviderTheaterSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    ticketTypesForTheater: data
  });
};

const getListTicketTypesForProviderTheaterFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    ticketTypesForTheater: [],
    ticketTypesForTheaterError: 'error'
  });
};

const importTicketTypesRequest = (state) => {
  return merge(state, {
    isLoading: true,
    importTicketTypesError: null
  });
};

const importTicketTypesSuccess = (state, action) => {
  const { data } = action;
  console.log(`data: ${data}`);

  return merge(state, {
    isLoading: false,
  });
};

const importTicketTypesFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    ticketTypesForTheater: [],
    importTicketTypesError: 'error'
  });
};

export const HANDLERS = {
  [Types.GET_LIST_TICKET_TYPES_REQUEST]: getListTicketTypesRequest,
  [Types.GET_LIST_TICKET_TYPES_SUCCESS]: getListTicketTypesSuccess,
  [Types.GET_LIST_TICKET_TYPES_FAILED]: getListTicketTypesFailed,

  [Types.UPDATE_TICKET_TYPE_REQUEST]: updateTicketTypeRequest,
  [Types.UPDATE_TICKET_TYPE_SUCCESS]: updateTicketTypeSuccess,
  [Types.UPDATE_TICKET_TYPE_FAILED]: updateTicketTypeFailed,

  [Types.GET_LIST_TICKET_TYPES_FOR_PROVIDER_THEATER_REQUEST]:
    getListTicketTypesForProviderTheaterRequest,
  [Types.GET_LIST_TICKET_TYPES_FOR_PROVIDER_THEATER_SUCCESS]:
    getListTicketTypesForProviderTheaterSuccess,
  [Types.GET_LIST_TICKET_TYPES_FOR_PROVIDER_THEATER_FAILED]:
    getListTicketTypesForProviderTheaterFailed,

  [Types.IMPORT_TICKET_TYPES_REQUEST]: importTicketTypesRequest,
  [Types.IMPORT_TICKET_TYPES_SUCCESS]: importTicketTypesSuccess,
  [Types.IMPORT_TICKET_TYPES_FAILED]: importTicketTypesFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
