import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';
import { Types } from '../actions/cardsAction';

export const INITIAL_STATE = Immutable({
  isLoading: false,
  card: {},
  cardTransactionsData: [],
  errorRetrieveCardTransactions: null,
  error: null,
});

const getListCardTransactionsRequest = (state) => {
  return merge(state, {
    isLoading: true,
    errorRetrieveCardTransactions: null
  });
};

const getListCardTransactionsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    cardTransactionsData: data
  });
};

const getListCardTransactionsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    errorRetrieveCardTransactions: error,
    cardTransactionsData: []
  });
};

const getCardRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getCardSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    card: data
  });
};

const getCardFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    card: {},
    error,
  });
};

const searchCardByProviderIdRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const searchCardByProviderIdSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    card: data
  });
};

const searchCardByProviderIdFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    card: {},
    error,
  });
};

const changeCardStatusRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const changeCardStatusSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    card: data
  });
};

const changeCardStatusFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    card: {},
    error,
  });
};

const reassociateCardRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const reassociateCardSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    card: data
  });
};

const reassociateCardFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    card: {},
    error,
  });
};

export const HANDLERS = {
  [Types.GET_LIST_CARD_TRANSACTIONS_REQUEST]: getListCardTransactionsRequest,
  [Types.GET_LIST_CARD_TRANSACTIONS_SUCCESS]: getListCardTransactionsSuccess,
  [Types.GET_LIST_CARD_TRANSACTIONS_FAILED]: getListCardTransactionsFailed,

  [Types.GET_CARD_REQUEST]: getCardRequest,
  [Types.GET_CARD_SUCCESS]: getCardSuccess,
  [Types.GET_CARD_FAILED]: getCardFailed,

  [Types.SEARCH_CARD_BY_PROVIDER_ID_REQUEST]: searchCardByProviderIdRequest,
  [Types.SEARCH_CARD_BY_PROVIDER_ID_SUCCESS]: searchCardByProviderIdSuccess,
  [Types.SEARCH_CARD_BY_PROVIDER_ID_FAILED]: searchCardByProviderIdFailed,

  [Types.CHANGE_CARD_STATUS_REQUEST]: changeCardStatusRequest,
  [Types.CHANGE_CARD_STATUS_SUCCESS]: changeCardStatusSuccess,
  [Types.CHANGE_CARD_STATUS_FAILED]: changeCardStatusFailed,

  [Types.REASSOCIATE_CARD_REQUEST]: reassociateCardRequest,
  [Types.REASSOCIATE_CARD_SUCCESS]: reassociateCardSuccess,
  [Types.REASSOCIATE_CARD_FAILED]: reassociateCardFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
