import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  // get list of brands
  getListBrandsRequest: ['params'],
  getListBrandsSuccess: ['data'],
  getListBrandsFailed: ['error'],

  // create brand
  createBrandRequest: ['params', 'callback'],
  createBrandSuccess: ['data'],
  createBrandFailed: ['error'],

  // update a brand
  updateBrandRequest: ['params', 'callback'],
  updateBrandSuccess: ['data'],
  updateBrandFailed: ['error'],
});
