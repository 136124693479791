import { put, call, takeLatest } from 'redux-saga/effects';

// reducers
import { Types, Creators } from 'src/redux/actions/usersAction';

// apis
import {
  searchUser,
  changePlanForUser,
  getUser,
  getCreditsForUser,
  getCreditBalanceAdjustments,
  changePassword,
  getCheckIns,
  getReservations,
  getSubscriptions,
  getCardDetails,
  getCardsForUser,
  getActivePlanForUser,
  getPlansForUser,
  updateProfileUser,
  updateAdjustCredits,
  removeCancellation,
  resetPassword,
  cancelUser,
  backdateCancelUser,
  getActiveUserAddress,
  updateActiveUserAddress,
  createCardForUser,
  activateUser,
  retryPaymentForUser,
  getFriendInvitations,
  getFriendConversions,
  startSubscriptionEarlyForFreeTrialUser,
  getUserBenefitHistory,
} from 'src/api-client/usersApi';

// get data authentication for request API

function* searchUserRequest(action) {
  const { params } = action;

  try {
    const response = yield call(searchUser, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.searchUserSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.searchUserFailed(error));
  }
}

function* changePlanForUserRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(changePlanForUser, params);

    if (response.status === 200) {
      yield put(Creators.changePlanForUserSuccess(params));
    } else {
      yield put(Creators.changePlanForUserFailed(response.data));
    }

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.changePlanForUserFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* getUserRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getUser, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getUserSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getUserFailed(error));
  }
}

function* getCreditsRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getCreditsForUser, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getCreditsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getCreditsFailed(error));
  }
}

function* getCreditBalanceAdjustmentsRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getCreditBalanceAdjustments, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getCreditBalanceAdjustmentsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getCreditBalanceAdjustmentsFailed(error));
  }
}

/**
 * Handle get data Check Ins by userID
 * @param {*} action
 */
function* getCheckInsRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getCheckIns, params);

    if (response.status === 200) {
      yield put(Creators.getCheckInsSuccess(response.data));
    } else {
      yield put(Creators.getCheckInsFailed(response.data));
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getCheckInsFailed(error));
  }
}

function* getReservationsRequest(action) {
  const { data } = action;

  try {
    const response = yield call(getReservations, data);
    if (response.status === 200) {
      yield put(Creators.getReservationsSuccess(response.data));
    } else {
      yield put(Creators.getReservationsFailed(response.data));
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getReservationsFailed(error));
  }
}

function* getSubscriptionsRequest(action) {
  const { data } = action;

  try {
    const response = yield call(getSubscriptions, data);
    if (response.status === 200) {
      yield put(Creators.getSubscriptionsSuccess(response.data));
    } else {
      yield put(Creators.getSubscriptionsFailed(response.data));
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getSubscriptionsFailed(error));
  }
}

function* getCardDetailsRequest(action) {
  const { userID } = action;

  try {
    const response = yield call(getCardDetails, { userID });
    if (response.status === 200) {
      yield put(Creators.getCardDetailsSuccess(response.data));
    } else {
      yield put(Creators.getCardDetailsFailed(response.data));
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getCardDetailsFailed(error));
  }
}

function* getCardsForUserRequest(action) {
  const { userID } = action;

  try {
    const response = yield call(getCardsForUser, { userID });
    if (response.status === 200) {
      yield put(Creators.getCardsForUserSuccess(response.data));
    } else {
      yield put(Creators.getCardsForUserFailed(response.data));
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getCardsForUserFailed(error));
  }
}

function* getActivePlanForUserRequest(action) {
  const { userID } = action;

  try {
    const response = yield call(getActivePlanForUser, { userID });
    if (response.status === 200) {
      yield put(Creators.getActivePlanForUserSuccess(response.data));
    } else {
      yield put(Creators.getActivePlanForUserFailed(response.data));
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getActivePlanForUserFailed(error));
  }
}

function* getPlansForUserRequest(action) {
  const { userID, callback } = action;

  try {
    const response = yield call(getPlansForUser, { userID });
    if (response.status === 200) {
      if (callback) {
        callback(response.data, null);
      }

      yield put(Creators.getPlansForUserSuccess(response.data));
    } else {
      yield put(Creators.getPlansForUserFailed(response.data));
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getPlansForUserFailed(error));
  }
}

function* updateProfileRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateProfileUser, params);
    yield put(Creators.updateProfileSuccess(params));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateProfileFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* updateAdjustCreditsRequest(action) {
  const { params, callback } = action;
  try {
    const response = yield call(updateAdjustCredits, params);
    yield put(Creators.updateAdjustCreditsSuccess(response));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateAdjustCreditsFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* changeUserPasswordRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(changePassword, params);
    yield put(Creators.changeUserPasswordSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.changeUserPasswordFailed(error || e));

    if (callback) {
      callback(null, error || e);
    }
  }
}

/**
 * Handle reset password request
 */
function* resetPasswordRequest(action) {
  const { params } = action;

  try {
    const response = yield call(resetPassword, params);
    yield put(Creators.resetPasswordSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.resetPasswordFailed(error || e));
  }
}

function* cancelUserRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(cancelUser, params);
    yield put(Creators.cancelUserSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.cancelUserFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* backdateCancelUserRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(backdateCancelUser, params);
    yield put(Creators.backdateCancelUserSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.backdateCancelUserFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* removeCancellationRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(removeCancellation, params);
    yield put(Creators.removeCancellationSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.removeCancellationFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* getActiveAddressDetailsRequest(action) {
  const { userID } = action;

  console.log(`getActiveAddress: ${JSON.stringify(userID)}`);

  try {
    const response = yield call(getActiveUserAddress, { userID });
    if (response.status === 200) {
      yield put(Creators.getActiveAddressDetailsSuccess(response.data));
    } else {
      yield put(Creators.getActiveAddressDetailsFailed(response.data));
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getActiveAddressDetailsFailed(error));
  }
}

function* updateActiveUserAddressRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateActiveUserAddress, params);
    yield put(Creators.updateActiveUserAddressSuccess(params));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateActiveUserAddressFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* createCardForUserRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(createCardForUser, params);
    yield put(Creators.createCardForUserSuccess(params));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.createCardForUserFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* activateUserRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(activateUser, params);
    yield put(Creators.activateUserSuccess(params));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.activateUserFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* retryPaymentForUserRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(retryPaymentForUser, params);
    yield put(Creators.retryPaymentForUserSuccess(params));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.retryPaymentForUserFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* getFriendInvitationsRequest(action) {
  const { userID, callback } = action;

  try {
    const response = yield call(getFriendInvitations, userID);
    if (response.status === 200) {
      yield put(Creators.getFriendInvitationsSuccess(response.data));
    } else {
      yield put(Creators.getFriendInvitationsFailed(response.data));
    }

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getFriendInvitationsFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* getFriendConversionsRequest(action) {
  const { userID, callback } = action;

  try {
    const response = yield call(getFriendConversions, userID);
    if (response.status === 200) {
      yield put(Creators.getFriendConversionsSuccess(response.data));
    } else {
      yield put(Creators.getFriendConversionsFailed(response.data));
    }

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getFriendConversionsFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* startSubscriptionEarlyForFreeTrialUserRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(startSubscriptionEarlyForFreeTrialUser, params);

    if (response.status === 200) {
      yield put(Creators.startSubscriptionEarlyForFreeTrialUserSuccess(params));
    } else {
      yield put(Creators.startSubscriptionEarlyForFreeTrialUserFailed(response.data));
    }

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.startSubscriptionEarlyForFreeTrialUserFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* getUserBenefitHistoryRequest(action) {
  const { userID } = action;

  try {
    const response = yield call(getUserBenefitHistory, userID);
    if (response.status === 200) {
      yield put(Creators.getUserBenefitHistorySuccess(response.data));
    } else {
      yield put(Creators.getUserBenefitHistoryFailed(response.data));
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getUserBenefitHistoryFailed(error));
  }
}

export default function* watchUsersRequest() {
  yield takeLatest(Types.SEARCH_USER_REQUEST, searchUserRequest);
  yield takeLatest(Types.CHANGE_PLAN_FOR_USER_REQUEST, changePlanForUserRequest);
  yield takeLatest(Types.GET_USER_REQUEST, getUserRequest);
  yield takeLatest(Types.GET_CREDITS_REQUEST, getCreditsRequest);
  yield takeLatest(Types.GET_CREDIT_BALANCE_ADJUSTMENTS_REQUEST,
    getCreditBalanceAdjustmentsRequest);
  yield takeLatest(Types.CHANGE_USER_PASSWORD_REQUEST, changeUserPasswordRequest);
  yield takeLatest(Types.GET_CHECK_INS_REQUEST, getCheckInsRequest);
  yield takeLatest(Types.GET_RESERVATIONS_REQUEST, getReservationsRequest);
  yield takeLatest(Types.GET_SUBSCRIPTIONS_REQUEST, getSubscriptionsRequest);
  yield takeLatest(Types.GET_CARD_DETAILS_REQUEST, getCardDetailsRequest);
  yield takeLatest(Types.GET_CARDS_FOR_USER_REQUEST, getCardsForUserRequest);
  yield takeLatest(Types.GET_ACTIVE_PLAN_FOR_USER_REQUEST, getActivePlanForUserRequest);
  yield takeLatest(Types.GET_PLANS_FOR_USER_REQUEST, getPlansForUserRequest);
  yield takeLatest(Types.UPDATE_PROFILE_REQUEST, updateProfileRequest);
  yield takeLatest(Types.UPDATE_ADJUST_CREDITS_REQUEST, updateAdjustCreditsRequest);
  yield takeLatest(Types.RESET_PASSWORD_REQUEST, resetPasswordRequest);
  yield takeLatest(Types.CANCEL_USER_REQUEST, cancelUserRequest);
  yield takeLatest(Types.BACKDATE_CANCEL_USER_REQUEST, backdateCancelUserRequest);
  yield takeLatest(Types.REMOVE_CANCELLATION_REQUEST, removeCancellationRequest);
  yield takeLatest(Types.GET_ACTIVE_ADDRESS_DETAILS_REQUEST, getActiveAddressDetailsRequest);
  yield takeLatest(Types.UPDATE_ACTIVE_USER_ADDRESS_REQUEST, updateActiveUserAddressRequest);
  yield takeLatest(Types.CREATE_CARD_FOR_USER_REQUEST, createCardForUserRequest);
  yield takeLatest(Types.ACTIVATE_USER_REQUEST, activateUserRequest);
  yield takeLatest(Types.RETRY_PAYMENT_FOR_USER_REQUEST, retryPaymentForUserRequest);
  yield takeLatest(Types.GET_FRIEND_INVITATIONS_REQUEST, getFriendInvitationsRequest);
  yield takeLatest(Types.GET_FRIEND_CONVERSIONS_REQUEST, getFriendConversionsRequest);
  yield takeLatest(Types.START_SUBSCRIPTION_EARLY_FOR_FREE_TRIAL_USER_REQUEST,
    startSubscriptionEarlyForFreeTrialUserRequest);
  yield takeLatest(Types.GET_USER_BENEFIT_HISTORY_REQUEST, getUserBenefitHistoryRequest);
}
