import ENDPOINT from './ApiEndpoint';
import { exhibServiceAdminAPI, authHeader } from './apiExhibitorAdminConfig';

export const getCreditBands = async () => {
  const response = await exhibServiceAdminAPI.get(`v1${ENDPOINT.CREDITS}${ENDPOINT.BANDS}`, {
    headers: authHeader()
  });

  return response;
};

export const createCreditBand = async ({
  name,
  startHour,
  zones
}) => {
  const response = await exhibServiceAdminAPI.post(
    `v1${ENDPOINT.CREDITS}${ENDPOINT.BANDS}`, {
      name,
      startHour,
      zones
    },
    { headers: authHeader() }
  );

  return response;
};
