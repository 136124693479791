import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getListProviderQualifiersRequest: ['params', 'callback'],
  getListProviderQualifiersSuccess: ['data'],
  getListProviderQualifiersFailed: ['error'],

  getProviderQualifierRequest: ['params', 'callback'],
  getProviderQualifierSuccess: ['data'],
  getProviderQualifierFailed: ['error'],

  createProviderQualifierRequest: ['params', 'callback'],
  createProviderQualifierSuccess: ['data'],
  createProviderQualifierFailed: ['error'],

  updateProviderQualifierRequest: ['params', 'callback'],
  updateProviderQualifierSuccess: ['data'],
  updateProviderQualifierFailed: ['error'],

  getListUnifiedQualifiersRequest: ['params', 'callback'],
  getListUnifiedQualifiersSuccess: ['data'],
  getListUnifiedQualifiersFailed: ['error'],

  getUnifiedQualifierRequest: ['params', 'callback'],
  getUnifiedQualifierSuccess: ['data'],
  getUnifiedQualifierFailed: ['error'],

  createUnifiedQualifierRequest: ['params', 'callback'],
  createUnifiedQualifierSuccess: ['data'],
  createUnifiedQualifierFailed: ['error'],

  updateUnifiedQualifierRequest: ['params', 'callback'],
  updateUnifiedQualifierSuccess: ['data'],
  updateUnifiedQualifierFailed: ['error'],
});
