import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getListTicketTypesRequest: ['params'],
  getListTicketTypesSuccess: ['data'],
  getListTicketTypesFailed: ['error'],

  updateTicketTypeRequest: ['params', 'callback'],
  updateTicketTypeSuccess: ['data'],
  updateTicketTypeFailed: ['error'],

  getListTicketTypesForProviderTheaterRequest: ['params'],
  getListTicketTypesForProviderTheaterSuccess: ['data'],
  getListTicketTypesForProviderTheaterFailed: ['error'],

  importTicketTypesRequest: ['params', 'callback'],
  importTicketTypesSuccess: ['data'],
  importTicketTypesFailed: ['error'],
});
