// libs
import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getListSpotsRequest: ['params'],
  getListSpotsSuccess: ['data'],
  getListSpotsFailed: ['error'],

  getSpotDetailsRequest: ['params'],
  getSpotDetailsSuccess: ['data'],
  getSpotDetailsFailed: ['error']
});
