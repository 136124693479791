import { put, call, takeLatest } from 'redux-saga/effects';
import { Types, Creators } from 'src/redux/actions/corporatePartnersAction';
import {
  getCorporatePartners,
  getCorporatePartner,
  createCorporatePartner,
  updateCorporatePartner,
  getCorporatePartnerUsers,
  getCorporateCoupons,
  uploadCSV,
  getCSVUploads,
  getCSVJobUploadStatus,
} from 'src/api-client/corporatePartnersApi';

export function* getListCorporatePartnersRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getCorporatePartners, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getListCorporatePartnersSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.getListCorporatePartnersFailed(error || e));
  }
}

export function* getCorporatePartnerRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getCorporatePartner, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getCorporatePartnerSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.getCorporatePartnerFailed(error || e));
  }
}

export function* createCorporatePartnerRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(createCorporatePartner, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.createCorporatePartnerSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.createCorporatePartnerFailed(error || e));
  }
}

export function* updateCorporatePartnerRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateCorporatePartner, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.updateCorporatePartnerSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.updateCorporatePartnerFailed(error || e));
  }
}
export function* getListCorporatePartnerUsersRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getCorporatePartnerUsers, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getListCorporatePartnerUsersSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.getListCorporatePartnerUsersFailed(error || e));
  }
}

export function* getListCorporateCouponsRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getCorporateCoupons, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getListCorporateCouponsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.getListCorporateCouponsFailed(error || e));
  }
}

export function* uploadCsvRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(uploadCSV, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.uploadCsvSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.uploadCsvFailed(error || e));
    if (callback) {
      callback(null, error || e);
    }
  }
}

export function* getCSVUploadsRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getCSVUploads, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getCSVUploadsSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getCSVUploadsFailed(error || e));
    if (callback) {
      callback(null, error || e);
    }
  }
}

export function* getCSVJobUploadStatusRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getCSVJobUploadStatus, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getCSVJobUploadStatusSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getCSVJobUploadStatusFailed(error || e));
    if (callback) {
      callback(null, error || e);
    }
  }
}

export default function* watchCorporatePartnersRequest() {
  yield takeLatest(Types.GET_LIST_CORPORATE_PARTNERS_REQUEST, getListCorporatePartnersRequest);
  yield takeLatest(Types.GET_CORPORATE_PARTNER_REQUEST, getCorporatePartnerRequest);
  yield takeLatest(Types.CREATE_CORPORATE_PARTNER_REQUEST, createCorporatePartnerRequest);
  yield takeLatest(Types.UPDATE_CORPORATE_PARTNER_REQUEST, updateCorporatePartnerRequest);
  yield takeLatest(Types.GET_LIST_CORPORATE_PARTNER_USERS_REQUEST,
    getListCorporatePartnerUsersRequest);
  yield takeLatest(Types.GET_LIST_CORPORATE_COUPONS_REQUEST, getListCorporateCouponsRequest);
  yield takeLatest(Types.UPLOAD_CSV_REQUEST, uploadCsvRequest);
  yield takeLatest(Types.GET_CSV_UPLOADS_REQUEST, getCSVUploadsRequest);
  yield takeLatest(Types.GET_CSV_JOB_UPLOAD_STATUS_REQUEST, getCSVJobUploadStatusRequest);
}
