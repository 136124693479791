// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from '../actions/surveysAction';

export const INITIAL_STATE = Immutable({
  error: null,
  isLoading: false,
  surveysData: {
    total: 0,
    surveys: []
  },
  surveyDetail: {},
  updateError: null
});

const getListSurveysRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListSurveysSuccess = (state, action) => {
  const {
    data: { total, surveys }
  } = action;

  return merge(state, {
    isLoading: false,
    surveysData: {
      total,
      surveys
    }
  });
};

const getListSurveysFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    surveysData: {
      total: 0,
      surveys: []
    }
  });
};

// get survey detail request
const getSurveyDetailsRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getSurveyDetailsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    surveyDetail: data
  });
};

const getSurveyDetailsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    surveyDetail: {}
  });
};

// Update name of survey
const updateNameSurveyRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const updateNameSurveySuccess = (state) => {
  return merge(state, {
    isLoading: false
  });
};

const updateNameSurveyFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    updateError: error
  });
};

export const HANDLERS = {
  [Types.GET_LIST_SURVEYS_REQUEST]: getListSurveysRequest,
  [Types.GET_LIST_SURVEYS_SUCCESS]: getListSurveysSuccess,
  [Types.GET_LIST_SURVEYS_FAILED]: getListSurveysFailed,

  [Types.GET_SURVEY_DETAILS_REQUEST]: getSurveyDetailsRequest,
  [Types.GET_SURVEY_DETAILS_SUCCESS]: getSurveyDetailsSuccess,
  [Types.GET_SURVEY_DETAILS_FAILED]: getSurveyDetailsFailed,

  [Types.UPDATE_NAME_SURVEY_REQUEST]: updateNameSurveyRequest,
  [Types.UPDATE_NAME_SURVEY_SUCCESS]: updateNameSurveySuccess,
  [Types.UPDATE_NAME_SURVEY_FAILED]: updateNameSurveyFailed
};

export default createReducer(INITIAL_STATE, HANDLERS);
