// libs
import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getListOfferWallsRequest: ['params'],
  getListOfferWallsSuccess: ['data'],
  getListOfferWallsFailed: ['error'],

  addSectionOfferWallRequest: ['params'],
  addSectionOfferWallSuccess: ['data'],
  addSectionOfferWallFailed: ['error'],

  refreshCategoriesRequest: [],
  refreshCategoriesSuccess: ['data'],
  refreshCategoriesFailed: ['error'],

  updateOfferWallRequest: ['params'],
  updateOfferWallSuccess: ['data'],
  updateOfferWallFailed: ['data'],

  deleteSectionOfferWallRequest: ['params'],
  deleteSectionOfferWallSuccess: ['data'],
  deleteSectionOfferWallFailed: ['error'],

  updateSectionOfferWallRequest: ['params'],
  updateSectionOfferWallSuccess: ['data'],
  updateSectionOfferWallFailed: ['error']
});
