import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getListAdvertisingsRequest: ['params', 'callback'],
  getListAdvertisingsSuccess: ['data'],
  getListAdvertisingsFailed: ['error'],

  createAdvertisingRequest: ['params', 'callback'],
  createAdvertisingSuccess: ['data'],
  createAdvertisingFailed: ['error'],

  getAdvertisingRequest: ['params', 'callback'],
  getAdvertisingSuccess: ['data'],
  getAdvertisingFailed: ['error'],

  updateAdvertisingRequest: ['params', 'callback'],
  updateAdvertisingSuccess: ['data'],
  updateAdvertisingFailed: ['error'],

  deleteAdvertisingRequest: ['params', 'callback'],
  deleteAdvertisingSuccess: ['data'],
  deleteAdvertisingFailed: ['error'],

  getListAdvertisingProvidersRequest: ['params', 'callback'],
  getListAdvertisingProvidersSuccess: ['data'],
  getListAdvertisingProvidersFailed: ['error'],

  createAdvertisingProviderRequest: ['params', 'callback'],
  createAdvertisingProviderSuccess: ['data'],
  createAdvertisingProviderFailed: ['error'],

  getAdvertisingProviderRequest: ['params', 'callback'],
  getAdvertisingProviderSuccess: ['data'],
  getAdvertisingProviderFailed: ['error'],

  updateAdvertisingProviderRequest: ['params', 'callback'],
  updateAdvertisingProviderSuccess: ['data'],
  updateAdvertisingProviderFailed: ['error'],

  deleteAdvertisingProviderRequest: ['params', 'callback'],
  deleteAdvertisingProviderSuccess: ['data'],
  deleteAdvertisingProviderFailed: ['error'],
});
