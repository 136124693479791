import axios from 'axios';
import { store } from 'src/store';
import { destroyCookie, parseCookies } from 'nookies';

const {
  REACT_APP_EXHIB_API_ENDPOINT,
  REACT_APP_API_TOKEN,
  REACT_APP_API_USER
} = process.env;

export const exhibServiceAdminAPI = axios.create({
  baseURL: `${REACT_APP_EXHIB_API_ENDPOINT}/rest/admin/`,
  timeout: 30000
});

exhibServiceAdminAPI.interceptors.response.use(
  (response) => {
    return response;
  }, (error) => {
    const cookies = parseCookies();

    if (error.message.includes('403') && !error.config.url.includes('session') && !!cookies.token) {
      destroyCookie(null, 'token');
    }

    return Promise.reject(error);
  }
);

export const authHeader = () => {
  const auth = store.getState().authReducer || {};

  return {
    api_token: `${REACT_APP_API_TOKEN}`,
    api_user: `${REACT_APP_API_USER}`,
    token: `${auth.user.token}`,
    user_id: `${auth.user.id}`
  };
};

export const authHeaderFile = () => {
  const auth = store.getState().authReducer || {};

  return {
    api_token: `${REACT_APP_API_TOKEN}`,
    api_user: `${REACT_APP_API_USER}`,
    token: `${auth.user.token}`,
    user_id: `${auth.user.id}`,
    'Content-Type': 'multipart/form-data'
  };
};
