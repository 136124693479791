import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getListCardTransactionsRequest: ['params', 'callback'],
  getListCardTransactionsSuccess: ['data'],
  getListCardTransactionsFailed: ['error'],

  getCardRequest: ['params', 'callback'],
  getCardSuccess: ['data'],
  getCardFailed: ['error'],

  searchCardByProviderIdRequest: ['params', 'callback'],
  searchCardByProviderIdSuccess: ['data'],
  searchCardByProviderIdFailed: ['error'],

  changeCardStatusRequest: ['params', 'callback'],
  changeCardStatusSuccess: ['data'],
  changeCardStatusFailed: ['error'],

  reassociateCardRequest: ['params', 'callback'],
  reassociateCardSuccess: ['data'],
  reassociateCardFailed: ['error'],
});
