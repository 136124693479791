import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

export const getPollByKey = async ({ key }) => {
  const response = await adminAPI.get(`${ENDPOINT.POLL}/${key}`, {
    headers: authHeader()
  });

  return response;
};

export const createPoll = async (params) => {
  const { id, data } = params;
  const response = await adminAPI.post(`${ENDPOINT.POLL}/${id}`, data, {
    headers: authHeader()
  });

  return response;
};
