// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from '../actions/checkInsAction';

export const INITIAL_STATE = Immutable({
  error: null,
  isLoading: false,
  checkInDetail: {},
});

const getCheckInDetailsRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getCheckInDetailsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    checkInDetail: data
  });
};

const getCheckInDetailsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    checkInDetail: {}
  });
};

export const HANDLERS = {
  [Types.GET_CHECK_IN_DETAILS_REQUEST]: getCheckInDetailsRequest,
  [Types.GET_CHECK_IN_DETAILS_SUCCESS]: getCheckInDetailsSuccess,
  [Types.GET_CHECK_IN_DETAILS_FAILED]: getCheckInDetailsFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
