import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

export const getVipUsers = async () => {
  const response = await adminAPI.get(`${ENDPOINT.VIP_USERS}`, {
    headers: authHeader()
  });

  return response;
};

export const createVipUser = async ({
  email,
  password,
  firstName,
  lastName,
  genderValue,
  selectedDOB,
  role,
}) => {
  const response = await adminAPI.post(
    `${ENDPOINT.VIP_USER}`,
    {
      email,
      password,
      firstName,
      lastName,
      gender: genderValue,
      dateOfBirth: selectedDOB.getTime(),
      role,
    },
    { headers: authHeader() }
  );

  return response;
};
