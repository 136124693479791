// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from '../actions/authAction';

export const INITIAL_STATE = Immutable({
  isAuthenticated: false,
  isLoading: false,
  loginError: null,
  resetEmail: null,
  resetRequestError: null,
  submitRequestError: null,
  user: {}
});

const loginRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const loginSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    isAuthenticated: true,
    user: data
  });
};

const loginFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    isAuthenticated: false,
    loginError: error
  });
};

const requestPasswordResetRequest = (state) => {
  return merge(state, {
    isLoading: true,
    resetRequestError: null,
    loginError: null
  });
};

const requestPasswordResetSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    resetEmail: data
  });
};

const requestPasswordResetFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    resetRequestError: error,
    resetEmail: null
  });
};

const submitPasswordResetRequest = (state) => {
  return merge(state, {
    isLoading: true,
    submitRequestError: null,
    loginError: null
  });
};

const submitPasswordResetSuccess = (state) => {
  return merge(state, {
    isLoading: false,
    resetEmail: null,
    loginError: null
  });
};

const submitPasswordResetFailed = (state, action) => {
  const { error } = action;
  const { message } = error;

  return merge(state, {
    isLoading: false,
    submitRequestError: message
  });
};

/**
 * logout account
 * @param {*} state
 * @returns
 */
export const logoutRequest = (state) => {
  return merge(state, {
    isAuthenticated: false,
    user: {},
    loginError: null
  });
};

export const HANDLERS = {
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILED]: loginFailed,
  [Types.REQUEST_PASSWORD_RESET_REQUEST]: requestPasswordResetRequest,
  [Types.REQUEST_PASSWORD_RESET_SUCCESS]: requestPasswordResetSuccess,
  [Types.REQUEST_PASSWORD_RESET_FAILED]: requestPasswordResetFailed,
  [Types.SUBMIT_PASSWORD_RESET_REQUEST]: submitPasswordResetRequest,
  [Types.SUBMIT_PASSWORD_RESET_SUCCESS]: submitPasswordResetSuccess,
  [Types.SUBMIT_PASSWORD_RESET_FAILED]: submitPasswordResetFailed,
  [Types.LOGOUT_REQUEST]: logoutRequest
};

export default createReducer(INITIAL_STATE, HANDLERS);
