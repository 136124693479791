import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

export const searchWaitlistUser = async ({ key = '' }) => {
  const response = await adminAPI.get(ENDPOINT.WAITLIST_USERS, {
    params: { key },
    headers: authHeader()
  });

  return response;
};

export const addWaitlistUser = async ({
  email,
  formerMember,
  postalCode,
}) => {
  const response = await adminAPI.post(
    `${ENDPOINT.WAITLIST_USERS}`,
    {
      email,
      formerMember,
      postalCode,
    },
    { headers: authHeader() }
  );

  return response;
};

export const getWaitlistUser = async ({ id }) => {
  const response = await adminAPI.get(`${ENDPOINT.WAITLIST_USERS}/${id}`, {
    headers: authHeader()
  });

  return response;
};

export const updateWaitlistUser = async ({
  waitlistUserId,
  email,
  formerMember,
  postalCode,
  statusValue,
}) => {
  const response = await adminAPI.put(
    `${ENDPOINT.WAITLIST_USERS}/${waitlistUserId}`,
    {
      email,
      formerMember,
      postalCode,
      status: statusValue,
    },
    { headers: authHeader() }
  );

  return response;
};

export const inviteWaitlistUsers = async ({
  waitlistUserIds,
}) => {
  const response = await adminAPI.post(
    `${ENDPOINT.WAITLIST_USER_INVITATIONS}`,
    {
      waitlistUserIds,
    },
    { headers: authHeader() }
  );

  return response;
};

export const getWaitlistUsersToInvite = async ({
  networkId,
  count,
}) => {
  const response = await adminAPI.get(`${ENDPOINT.WAITLIST_USER_INVITATIONS}?networkId=${networkId}&count=${count}`, {
    headers: authHeader()
  });

  return response;
};

export const getWaitlistUsersInNetworkStats = async ({
  networkId,
}) => {
  const response = await adminAPI.get(`${ENDPOINT.WAITLIST_USER_INVITATIONS}${ENDPOINT.NETWORK}?networkId=${networkId}`, {
    headers: authHeader()
  });

  return response;
};

export const inviteAllInNetwork = async ({
  networkId
}) => {
  const response = await adminAPI.post(`${ENDPOINT.WAITLIST_USER_INVITATIONS}${ENDPOINT.NETWORK}`,
    { networkId },
    { headers: authHeader() });

  return response;
};
