import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

/**
 * Handle request server to get list of spot actions
 * @param {*} param
 * @returns response
 */
const getListSpotActions = async ({ key = '', offset = 0, limit = 10 }) => {
  const response = await adminAPI.get(ENDPOINT.SPOT_ACTIONS, {
    params: { key, offset, limit },
    headers: authHeader()
  });

  return response;
};

export default getListSpotActions;
