// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from '../actions/offersAction';

export const INITIAL_STATE = Immutable({
  error: null,
  isLoading: false,
  offersData: {
    total: 0,
    offers: []
  },
  offerDetail: {},
  updateError: null,
  errorOfferDetail: null
});

const getListOffersRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListOffersSuccess = (state, action) => {
  // TODO: Mocking to testing. Waiting for update from BE
  const {
    data: { total, offers, items }
  } = action;

  return merge(state, {
    isLoading: false,
    offersData: {
      total,
      offers,
      items
    }
  });
};

const getListOffersFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    offersData: {
      total: 0,
      offers: [],
      items: {}
    }
  });
};

const updateOfferRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const updateOfferSuccess = (state) => {
  return merge(state, {
    isLoading: false,
    updateError: null
  });
};

const updateOfferFailed = (state) => {
  return merge(state, {
    isLoading: false,
    updateError: 'Error'
  });
};

const addPreshowToOfferRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const addPreshowToOfferSuccess = (state) => {
  return merge(state, {
    isLoading: false
  });
};

const addPreshowToOfferFailed = (state, action) => {
  const { error } = action;
  return merge(state, {
    isLoading: false,
    error
  });
};

const getOfferDetailRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null,
    updateError: null,
    errorOfferDetail: null
  });
};

const getOfferDetailSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    offerDetail: data
  });
};

const getOfferDetailFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    errorOfferDetail: error,
    offerDetail: {}
  });
};

export const HANDLERS = {
  [Types.GET_LIST_OFFERS_REQUEST]: getListOffersRequest,
  [Types.GET_LIST_OFFERS_SUCCESS]: getListOffersSuccess,
  [Types.GET_LIST_OFFERS_FAILED]: getListOffersFailed,

  [Types.UPDATE_OFFER_REQUEST]: updateOfferRequest,
  [Types.UPDATE_OFFER_SUCCESS]: updateOfferSuccess,
  [Types.UPDATE_OFFER_FAILED]: updateOfferFailed,

  [Types.ADD_PRESHOW_TO_OFFER_REQUEST]: addPreshowToOfferRequest,
  [Types.ADD_PRESHOW_TO_OFFER_SUCCESS]: addPreshowToOfferSuccess,
  [Types.ADD_PRESHOW_TO_OFFER_FAILED]: addPreshowToOfferFailed,

  [Types.GET_OFFER_DETAIL_REQUEST]: getOfferDetailRequest,
  [Types.GET_OFFER_DETAIL_SUCCESS]: getOfferDetailSuccess,
  [Types.GET_OFFER_DETAIL_FAILED]: getOfferDetailFailed
};

export default createReducer(INITIAL_STATE, HANDLERS);
