import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

import { Types } from '../actions/creditOffsetsAction';

export const INITIAL_STATE = Immutable({
  isLoading: false,
  creditOffsetsData: [],
  creditOffsetData: {},
  error: null,
});

const getListCreditOffsetsRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListCreditOffsetsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    creditOffsetsData: data
  });
};

const getListCreditOffsetsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    creditOffsetsData: []
  });
};

const getCreditOffsetRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getCreditOffsetSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    creditOffsetData: data
  });
};

const getCreditOffsetFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    creditOffsetData: {}
  });
};

const updateCreditOffsetRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const updateCreditOffsetSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    creditOffsetData: data
  });
};

const updateCreditOffsetFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    creditOffsetData: {}
  });
};

const createCreditOffsetRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null,
    creditOffsetData: {}
  });
};

const createCreditOffsetSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    creditOffsetData: data
  });
};

const createCreditOffsetFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    creditOffsetData: {}
  });
};

export const HANDLERS = {
  [Types.GET_LIST_CREDIT_OFFSETS_REQUEST]: getListCreditOffsetsRequest,
  [Types.GET_LIST_CREDIT_OFFSETS_SUCCESS]: getListCreditOffsetsSuccess,
  [Types.GET_LIST_CREDIT_OFFSETS_FAILED]: getListCreditOffsetsFailed,

  [Types.GET_CREDIT_OFFSET_REQUEST]: getCreditOffsetRequest,
  [Types.GET_CREDIT_OFFSET_SUCCESS]: getCreditOffsetSuccess,
  [Types.GET_CREDIT_OFFSET_FAILED]: getCreditOffsetFailed,

  [Types.UPDATE_CREDIT_OFFSET_REQUEST]: updateCreditOffsetRequest,
  [Types.UPDATE_CREDIT_OFFSET_SUCCESS]: updateCreditOffsetSuccess,
  [Types.UPDATE_CREDIT_OFFSET_FAILED]: updateCreditOffsetFailed,

  [Types.CREATE_CREDIT_OFFSET_REQUEST]: createCreditOffsetRequest,
  [Types.CREATE_CREDIT_OFFSET_SUCCESS]: createCreditOffsetSuccess,
  [Types.CREATE_CREDIT_OFFSET_FAILED]: createCreditOffsetFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
