import { put, call, takeLatest } from 'redux-saga/effects';
import { Types, Creators } from 'src/redux/actions/campaignsAction';
import {
  getCampaigns,
  getCampaign,
  createCampaign,
  updateCampaign,
  getCampaignUsers,
  addCampaignUser,
  removeCampaignUser,
} from 'src/api-client/campaignsApi';

export function* getListCampaignsRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getCampaigns, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getListCampaignsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.getListCampaignsFailed(error || e));
  }
}

export function* getCampaignRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getCampaign, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getCampaignSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.getCampaignFailed(error || e));
  }
}

export function* createCampaignRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(createCampaign, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.createCampaignSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.createCampaignFailed(error || e));
  }
}

export function* updateCampaignRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateCampaign, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.updateCampaignSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.updateCampaignFailed(error || e));
  }
}
export function* getListCampaignUsersRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getCampaignUsers, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getListCampaignUsersSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.getListCampaignUsersFailed(error || e));
  }
}

export function* addUserToCampaignRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(addCampaignUser, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.addUserToCampaignSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.addUserToCampaignFailed(error || e));
  }
}

export function* removeUserFromCampaignRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(removeCampaignUser, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.removeUserFromCampaignSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.removeUserFromCampaignFailed(error || e));
  }
}

export default function* watchCampaignsRequest() {
  yield takeLatest(Types.GET_LIST_CAMPAIGNS_REQUEST, getListCampaignsRequest);
  yield takeLatest(Types.GET_CAMPAIGN_REQUEST, getCampaignRequest);
  yield takeLatest(Types.CREATE_CAMPAIGN_REQUEST, createCampaignRequest);
  yield takeLatest(Types.UPDATE_CAMPAIGN_REQUEST, updateCampaignRequest);
  yield takeLatest(Types.GET_LIST_CAMPAIGN_USERS_REQUEST, getListCampaignUsersRequest);
  yield takeLatest(Types.ADD_USER_TO_CAMPAIGN_REQUEST, addUserToCampaignRequest);
  yield takeLatest(Types.REMOVE_USER_FROM_CAMPAIGN_REQUEST, removeUserFromCampaignRequest);
}
