import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

export const getCardTransaction = async ({ id }) => {
  const response = await adminAPI.get(`${ENDPOINT.CARD_TRANSACTIONS}/${id}`, {
    headers: authHeader()
  });

  return response;
};

export default getCardTransaction;
