import React, { useCallback } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  makeStyles
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { MENU, NESTED_MENU } from 'src/constants/menuList';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 231,
    backgroundColor: theme.palette.background.paper,
    zIndex: 9999,
    position: 'absolute',
    top: 174,
    left: 32,
    fontSize: 16,
    paddingBottom: 0,
    border: '0.5px solid #D7D7D7',
    boxShadow: '1px 2px 2px 0 rgba(169,169,169,0.5)'
  },
  nested: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    top: 0,
    left: 232,
    width: 231,
    border: '0.5px solid #D7D7D7',
    boxShadow: '1px 2px 2px 0 rgba(169,169,169,0.5)'
  },
  listItemText: {
    fontSize: 16,
    paddingLeft: theme.spacing(3.4)
  }
}));

const CreateList = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <List component="nav" className={classes.root}>
      {MENU.map((item) => (
        <ListItem button key={item.id}>
          <ListItemText
            primary={item.name}
            classes={{ primary: classes.listItemText }}
          />
        </ListItem>
      ))}
      <ListItem button onClick={handleClick}>
        <ListItemText
          classes={{ primary: classes.listItemText }}
          primary="More"
        />
        {open ? <ExpandLess /> : <ExpandMore />}
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          className={classes.nested}
        >
          <List component="div">
            {NESTED_MENU.map((item) => (
              <ListItem button key={item.id}>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary={item.name}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </ListItem>
    </List>
  );
};

export default CreateList;
