import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from '../actions/theaterChainsAction';

export const INITIAL_STATE = Immutable({
  error: null,
  isLoading: false,
  theaterChains: [],
  theaters: [],
  addTheaterChainError: null,
  updateTheaterChainError: null,
});

const getListTheaterChainsRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListTheaterChainsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    theaterChains: data
  });
};

const getListTheaterChainsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    theaterChains: []
  });
};

const addTheaterChainRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const addTheaterChainSuccess = (state, action) => {
  const { data } = action;
  const newTheaterChains = state.theaterChains;
  const updatedTheaterChains = newTheaterChains.map((theaterChain) =>
    theaterChain.id === data.id ? { id: theaterChain.id, ...data } : theaterChain
  );

  return merge(state, {
    theaterChains: updatedTheaterChains,
    isLoading: false,
    addTheaterChainError: null
  });
};

const addTheaterChainFailed = (state) => {
  return merge(state, {
    addTheaterChainError: 'Error',
    isLoading: false
  });
};

const updateTheaterChainRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const updateTheaterChainSuccess = (state, action) => {
  const { data } = action;
  const newTheaterChains = [...state.theaterChains];
  const updatedTheaterChains = newTheaterChains.map((theaterChain) =>
    theaterChain.id === data.id ? { id: theaterChain.id, ...data } : theaterChain
  );

  return merge(state, {
    theaterChains: updatedTheaterChains,
    isLoading: false,
    updateTheaterChainError: null
  });
};

const updateTheaterChainFailed = (state) => {
  return merge(state, {
    updateTheaterChainError: 'Error',
    isLoading: false
  });
};

const getListTheatersForTheaterChainRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListTheatersForTheaterChainSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    theaters: data
  });
};

const getListTheatersForTheaterChainFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    theaters: []
  });
};

export const HANDLERS = {
  [Types.GET_LIST_THEATER_CHAINS_REQUEST]: getListTheaterChainsRequest,
  [Types.GET_LIST_THEATER_CHAINS_SUCCESS]: getListTheaterChainsSuccess,
  [Types.GET_LIST_THEATER_CHAINS_FAILED]: getListTheaterChainsFailed,

  [Types.ADD_THEATER_CHAIN_REQUEST]: addTheaterChainRequest,
  [Types.ADD_THEATER_CHAIN_SUCCESS]: addTheaterChainSuccess,
  [Types.ADD_THEATER_CHAIN_FAILED]: addTheaterChainFailed,

  [Types.UPDATE_THEATER_CHAIN_REQUEST]: updateTheaterChainRequest,
  [Types.UPDATE_THEATER_CHAIN_SUCCESS]: updateTheaterChainSuccess,
  [Types.UPDATE_THEATER_CHAIN_FAILED]: updateTheaterChainFailed,

  [Types.GET_LIST_THEATERS_FOR_THEATER_CHAIN_REQUEST]: getListTheatersForTheaterChainRequest,
  [Types.GET_LIST_THEATERS_FOR_THEATER_CHAIN_SUCCESS]: getListTheatersForTheaterChainSuccess,
  [Types.GET_LIST_THEATERS_FOR_THEATER_CHAIN_FAILED]: getListTheatersForTheaterChainFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
