import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

export const getCardTransactions = async ({ id }) => {
  const response = await adminAPI.get(`${ENDPOINT.CARDS}/${id}${ENDPOINT.CARD_TRANSACTIONS}`, {
    headers: authHeader()
  });

  return response;
};

export const getCard = async ({ id }) => {
  const response = await adminAPI.get(`${ENDPOINT.CARDS}/${id}`, {
    headers: authHeader()
  });

  return response;
};

export const searchCardByProviderId = async ({ providerId }) => {
  const response = await adminAPI.get(`${ENDPOINT.CARDS}`, {
    params: { providerId },
    headers: authHeader()
  });

  return response;
};

export const changeCardStatus = async ({ id, statusValue, comment }) => {
  const response = await adminAPI.post(`${ENDPOINT.CARDS}/${id}${ENDPOINT.CHANGE_STATUS}`, {
    status: statusValue,
    comment
  }, {
    headers: authHeader()
  });

  return response;
};

export const reassociateCard = async ({ id, userId }) => {
  const response = await adminAPI.post(`${ENDPOINT.CARDS}/${id}${ENDPOINT.REASSOCIATE}`, {
    userId
  }, {
    headers: authHeader()
  });

  return response;
};
