import { put, call, takeLatest } from 'redux-saga/effects';
import { Types, Creators } from 'src/redux/actions/ticketTypesAction';

import {
  getListTicketTypes,
  updateTicketType,
  importTicketTypes,
  getListTicketTypesForProviderTheater,
} from 'src/api-client/ticketTypesApi';

function* getListTicketTypesRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getListTicketTypes, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getListTicketTypesSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListTicketTypesFailed(error || e));
  }
}

function* updateTicketTypeRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateTicketType, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.updateTicketTypeSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateTicketTypeFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* getListTicketTypesForProviderTheaterRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getListTicketTypesForProviderTheater, params);
    yield put(Creators.getListTicketTypesForProviderTheaterSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListTicketTypesForProviderTheaterFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* importTicketTypesRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(importTicketTypes, params);
    console.log(`response: ${JSON.stringify(response)}`);
    console.log(`response0: ${JSON.stringify(response.data)}`);
    if (!response || !response.data) {
      console.log('request failed');
      throw new Error('Request failed.');
    }

    yield put(Creators.importTicketTypesSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.importTicketTypesFailed(error || e));

    console.log(`error: ${error}`);

    if (callback) {
      callback(null, error);
    }
  }
}

export default function* watchTicketTypesRequest() {
  yield takeLatest(Types.GET_LIST_TICKET_TYPES_REQUEST, getListTicketTypesRequest);
  yield takeLatest(Types.UPDATE_TICKET_TYPE_REQUEST, updateTicketTypeRequest);
  yield takeLatest(Types.GET_LIST_TICKET_TYPES_FOR_PROVIDER_THEATER_REQUEST,
    getListTicketTypesForProviderTheaterRequest);
  yield takeLatest(Types.IMPORT_TICKET_TYPES_REQUEST, importTicketTypesRequest);
}
