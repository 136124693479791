import { put, call, takeLatest } from 'redux-saga/effects';
import { Types, Creators } from 'src/redux/actions/cardsAction';
import {
  getCardTransactions,
  getCard,
  searchCardByProviderId,
  changeCardStatus,
  reassociateCard,
} from 'src/api-client/cardsApi';

function* getListCardTransactionsRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getCardTransactions, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getListCardTransactionsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListCardTransactionsFailed(error || e));
  }
}

function* getCardRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getCard, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getCardSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getCardFailed(error || e));
  }
}

function* searchCardByProviderIdRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(searchCardByProviderId, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.searchCardByProviderIdSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.searchCardByProviderIdFailed(error || e));
  }
}

function* changeCardStatusRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(changeCardStatus, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.changeCardStatusSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.changeCardStatusFailed(error || e));

    if (callback) {
      callback(e.response.data, null);
    }
  }
}

function* reassociateCardRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(reassociateCard, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.reassociateCardSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.reassociateCardFailed(error || e));

    if (callback) {
      callback(e.response.data, null);
    }
  }
}

export default function* watchCardsRequest() {
  yield takeLatest(Types.GET_LIST_CARD_TRANSACTIONS_REQUEST, getListCardTransactionsRequest);
  yield takeLatest(Types.GET_CARD_REQUEST, getCardRequest);
  yield takeLatest(Types.SEARCH_CARD_BY_PROVIDER_ID_REQUEST, searchCardByProviderIdRequest);
  yield takeLatest(Types.CHANGE_CARD_STATUS_REQUEST, changeCardStatusRequest);
  yield takeLatest(Types.REASSOCIATE_CARD_REQUEST, reassociateCardRequest);
}
