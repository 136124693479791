// libs
import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getListSurveysRequest: ['params'],
  getListSurveysSuccess: ['data'],
  getListSurveysFailed: ['error'],

  getSurveyDetailsRequest: ['params'],
  getSurveyDetailsSuccess: ['data'],
  getSurveyDetailsFailed: ['error'],

  updateNameSurveyRequest: ['params'],
  updateNameSurveySuccess: ['data'],
  updateNameSurveyFailed: ['error']
});
