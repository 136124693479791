// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from '../actions/theatersAction';

export const INITIAL_STATE = Immutable({
  error: null,
  isLoading: false,
  theatersData: {
    total: 0,
    theaters: []
  },
  theaterDetail: {},
  updateTheaterError: null,
  updateTheatersListError: null,
  createTheaterError: null
});

const getListTheatersRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListTheatersSuccess = (state, action) => {
  const {
    data: { total, theaters }
  } = action;

  return merge(state, {
    isLoading: false,
    theatersData: {
      total,
      theaters
    },
    theaterDetail: {}
  });
};

const getListTheatersFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    theatersData: {
      total: 0,
      theaters: []
    },
    theaterDetail: {}
  });
};

const getTheaterDetailsRequest = (state) => {
  return merge(state, {
    isLoading: true,
    theaterDetail: {}
  });
};

const getTheaterDetailsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    theaterDetail: data
  });
};

const getTheaterDetailsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    theaterDetail: {}
  });
};

const updateTheaterRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const updateTheaterSuccess = (state, action) => {
  const { data } = action;
  const newTheaters = state.theatersData.theaters;
  const updatedTheaters = newTheaters.map((theater) =>
    theater.id === data.id ? { id: theater.id, ...data } : theater
  );

  return merge(state, {
    // TODO: mocking users list after update. Call getListTheaters when submit update
    theatersData: {
      total: updatedTheaters.size,
      theaters: updatedTheaters
    },
    isLoading: false,
    updateTheaterError: null
  });
};

const updateTheaterFailed = (state) => {
  return merge(state, {
    updateTheaterError: 'Error',
    isLoading: false
  });
};

/**
 * Handle request update theaters list
 * @param {*} state
 * @param {*} action
 * @returns
 */
const updateTheatersListRequest = (state) => {
  return merge(state, {
    isLoading: true,
    updateTheatersListError: null
  });
};

const updateTheatersListSuccess = (state, action) => {
  const { data } = action;

  console.log(`data: ${data}`);

  return merge(state, {
    isLoading: false,
    updateTheaterError: null
  });
};

const updateTheatersListFailed = (state) => {
  return merge(state, {
    updateTheatersListError: 'Error',
    isLoading: false
  });
};

const getServiceFeeForTheaterRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null,
    theaterDetail: {
      ...state.theaterDetail,
      serviceFee: 0
    }
  });
};

const getServiceFeeForTheaterSuccess = (state, action) => {
  const { data } = action;
  const serviceFee = data.serviceFee;

  return merge(state, {
    isLoading: false,
    theaterDetail: {
      ...state.theaterDetail,
      serviceFee
    }
  });
};

const getServiceFeeForTheaterFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    theaterDetail: {
      ...state.theaterDetail,
      serviceFee: 0
    }
  });
};

const createTheaterRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const createTheaterSuccess = (state) => {
  return merge(state, {
    isLoading: false,
    createTheaterError: null
  });
};

const createTheaterFailed = (state) => {
  return merge(state, {
    createTheaterError: 'Error',
    isLoading: false
  });
};

export const HANDLERS = {
  [Types.GET_LIST_THEATERS_REQUEST]: getListTheatersRequest,
  [Types.GET_LIST_THEATERS_SUCCESS]: getListTheatersSuccess,
  [Types.GET_LIST_THEATERS_FAILED]: getListTheatersFailed,

  [Types.GET_THEATER_DETAILS_REQUEST]: getTheaterDetailsRequest,
  [Types.GET_THEATER_DETAILS_SUCCESS]: getTheaterDetailsSuccess,
  [Types.GET_THEATER_DETAILS_FAILED]: getTheaterDetailsFailed,

  [Types.UPDATE_THEATER_REQUEST]: updateTheaterRequest,
  [Types.UPDATE_THEATER_SUCCESS]: updateTheaterSuccess,
  [Types.UPDATE_THEATER_FAILED]: updateTheaterFailed,

  [Types.UPDATE_THEATERS_LIST_REQUEST]: updateTheatersListRequest,
  [Types.UPDATE_THEATERS_LIST_SUCCESS]: updateTheatersListSuccess,
  [Types.UPDATE_THEATERS_LIST_FAILED]: updateTheatersListFailed,

  [Types.GET_SERVICE_FEE_FOR_THEATER_REQUEST]: getServiceFeeForTheaterRequest,
  [Types.GET_SERVICE_FEE_FOR_THEATER_SUCCESS]: getServiceFeeForTheaterSuccess,
  [Types.GET_SERVICE_FEE_FOR_THEATER_FAILED]: getServiceFeeForTheaterFailed,

  [Types.CREATE_THEATER_REQUEST]: createTheaterRequest,
  [Types.CREATE_THEATER_SUCCESS]: createTheaterSuccess,
  [Types.CREATE_THEATER_FAILED]: createTheaterFailed
};

export default createReducer(INITIAL_STATE, HANDLERS);
