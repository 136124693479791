// libs
import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getListMoviesRequest: ['params', 'callback'],
  getListMoviesSuccess: ['data'],
  getListMoviesFailed: ['error'],

  getMovieRequest: ['params', 'callback'],
  getMovieSuccess: ['data'],
  getMovieFailed: ['error'],

  updateMovieRequest: ['params', 'callback'],
  updateMovieSuccess: ['data'],
  updateMovieFailed: ['error'],

  getMoviesHomeListRequest: ['params', 'callback'],
  getMoviesHomeListSuccess: ['data'],
  getMoviesHomeListFailed: ['error'],

  addMoviesHomeListRequest: ['params', 'callback'],
  addMoviesHomeListSuccess: ['data'],
  addMoviesHomeListFailed: ['error'],

  addMoviePosterRequest: ['params', 'callback'],
  addMoviePosterSuccess: ['data'],
  addMoviePosterFailed: ['error'],

  getMoviesCarouselRequest: ['params', 'callback'],
  getMoviesCarouselSuccess: ['data'],
  getMoviesCarouselFailed: ['error'],

  addMoviesCarouselRequest: ['params', 'callback'],
  addMoviesCarouselSuccess: ['data'],
  addMoviesCarouselFailed: ['error'],

  updateMovieStatusRequest: ['params', 'callback'],
  updateMovieStatusSuccess: ['data'],
  updateMovieStatusFailed: ['error'],
});
