import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

/**
 * Handle request server to login with email and password
 *
 * @param {Objetc} param
 *
 * @returns
 */
export const login = async ({ email, password }) => {
  const response = await adminAPI.post(
    ENDPOINT.LOGIN,
    {
      email,
      password
    },
    { headers: authHeader() }
  );

  return response;
};

/**
 * Handle request server to request password code to email
 *
 * @param {Object} param
 *
 * @returns
 */
export const requestPasswordReset = async ({ email }) => {
  const response = await adminAPI.post(
    ENDPOINT.REQUEST_PASSWORD_RESET,
    {
      email
    },
    { headers: authHeader() }
  );

  return response;
};

/**
 * Handle request server to request password code to email
 *
 * @param {Object} param
 *
 * @returns
 */
export const submitPasswordReset = async ({ email, password, code }) => {
  const response = await adminAPI.post(
    ENDPOINT.SUBMIT_PASSWORD_RESET,
    {
      email,
      password,
      code
    },
    { headers: authHeader() }
  );

  return response;
};
