// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from '../actions/rewardsAction';

export const INITIAL_STATE = Immutable({
  error: null,
  isLoading: false,
  rewardsData: {
    total: 0,
    rewards: []
  }
});

const getListRewardsRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListRewardsSuccess = (state, action) => {
  const {
    data: { total, offerRewards }
  } = action;

  return merge(state, {
    isLoading: false,
    rewardsData: {
      total,
      rewards: offerRewards
    }
  });
};

const getListRewardsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    rewardsData: {
      total: 0,
      rewards: []
    }
  });
};

export const HANDLERS = {
  [Types.GET_LIST_REWARDS_REQUEST]: getListRewardsRequest,
  [Types.GET_LIST_REWARDS_SUCCESS]: getListRewardsSuccess,
  [Types.GET_LIST_REWARDS_FAILED]: getListRewardsFailed
};

export default createReducer(INITIAL_STATE, HANDLERS);
