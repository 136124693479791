import { put, call, takeLatest } from 'redux-saga/effects';

// reducers
import { Types, Creators } from 'src/redux/actions/postalCodesAction';

// apis
import { getListPostalCodes, getPostalCodeDetails, updatePostalCode } from 'src/api-client/postalCodesApi';

/**
 * Handle get list of Postal Codes
 * @param {*} action
 */
function* getListPostalCodesRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getListPostalCodes, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getListPostalCodesSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListPostalCodesFailed(error || e));
  }
}

/**
 * Handle get Postal Code details
 * @param {*} action
 */
function* getPostalCodeDetailsRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getPostalCodeDetails, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getPostalCodeDetailsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getPostalCodeDetailsFailed(error || e));
  }
}

/**
 * Handle update postal code
 */
function* updatePostalCodeRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updatePostalCode, params);
    yield put(Creators.updatePostalCodeSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updatePostalCodeFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

export default function* watchPostalCodesRequest() {
  yield takeLatest(Types.GET_LIST_POSTAL_CODES_REQUEST, getListPostalCodesRequest);
  yield takeLatest(Types.GET_POSTAL_CODE_DETAILS_REQUEST, getPostalCodeDetailsRequest);
  yield takeLatest(Types.UPDATE_POSTAL_CODE_REQUEST, updatePostalCodeRequest);
}
