import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getListCampaignsRequest: ['params', 'callback'],
  getListCampaignsSuccess: ['data'],
  getListCampaignsFailed: ['error'],

  getCampaignRequest: ['params', 'callback'],
  getCampaignSuccess: ['data'],
  getCampaignFailed: ['error'],

  createCampaignRequest: ['params', 'callback'],
  createCampaignSuccess: ['data'],
  createCampaignFailed: ['error'],

  updateCampaignRequest: ['params', 'callback'],
  updateCampaignSuccess: ['data'],
  updateCampaignFailed: ['error'],

  getListCampaignUsersRequest: ['params', 'callback'],
  getListCampaignUsersSuccess: ['data'],
  getListCampaignUsersFailed: ['error'],

  addUserToCampaignRequest: ['params', 'callback'],
  addUserToCampaignSuccess: ['data'],
  addUserToCampaignFailed: ['error'],

  removeUserFromCampaignRequest: ['params', 'callback'],
  removeUserFromCampaignSuccess: ['data'],
  removeUserFromCampaignFailed: ['error'],
});
