// libs
import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getReservationDetailsRequest: ['params', 'callback'],
  getReservationDetailsSuccess: ['data'],
  getReservationDetailsFailed: ['error'],
  createReservationRequest: ['params', 'callback'],
  createReservationSuccess: ['data'],
  createReservationFailed: ['error'],
  cancelReservationRequest: ['params', 'callback'],
  cancelReservationSuccess: ['data'],
  cancelReservationFailed: ['error'],
});
