// libs
import { all } from 'redux-saga/effects';

// sagas
import watchAdminUsersRequest from './adminUsersSaga';
import watchAdvertisingsRequest from './advertisingsSaga';
import watchAuthRequest from './authSaga';
import watchBrandsRequest from './brandsSaga';
import watchBlacklistsRequest from './blacklistsSaga';
import watchCampaignsRequest from './campaignsSaga';
import watchCardsRequest from './cardsSaga';
import watchCardTransactionsRequest from './cardTransactionsSaga';
import watchCheckInsRequest from './checkInsSaga';
import watchCorporatePartnersRequest from './corporatePartnersSaga';
import watchCreditBandsRequest from './creditBandsSaga';
import watchCreditOffsetsRequest from './creditOffsetsSaga';
import watchGiftsRequest from './giftsSaga';
import watchMoviesRequest from './moviesSaga';
import watchNetworksRequest from './networksSaga';
import watchOffersRequest from './offersSaga';
import watchOfferWallsRequest from './offerWallsSaga';
import watchPreshowsRequest from './preshowsSaga';
import watchPricesRequest from './pricesSaga';
import watchPostalCodesRequest from './postalCodesSaga';
import watchQualifiersRequest from './qualifiersSaga';
import watchReservationsRequest from './reservationsSaga';
import watchRewardsRequest from './rewardsSaga';
import watchServiceFeeOffsetsRequest from './serviceFeeOffsetsSaga';
import watchSpotsRequest from './spotsSaga';
import watchUsersRequest from './usersSaga';
import watchWaitlistUsersRequest from './waitlistUsersSaga';
import watchSurveysRequest from './surveysSaga';
import watchSpotActionsRequest from './spotActionsSaga';
import watchTheaterChainsRequest from './theaterChainsSaga';
import watchTheatersRequest from './theatersSaga';
import watchTicketTypesRequest from './ticketTypesSaga';
import watchItemsRequest from './itemsSaga';
import watchVipUsersRequest from './vipUsersSaga';
import watchPollsRequest from './pollsSaga';

// create root saga
function* rootSaga() {
  yield all([
    watchAdminUsersRequest(),
    watchAdvertisingsRequest(),
    watchAuthRequest(),
    watchBlacklistsRequest(),
    watchBrandsRequest(),
    watchCampaignsRequest(),
    watchCardsRequest(),
    watchCardTransactionsRequest(),
    watchCheckInsRequest(),
    watchCorporatePartnersRequest(),
    watchCreditBandsRequest(),
    watchCreditOffsetsRequest(),
    watchGiftsRequest(),
    watchMoviesRequest(),
    watchNetworksRequest(),
    watchOffersRequest(),
    watchOfferWallsRequest(),
    watchPreshowsRequest(),
    watchAdminUsersRequest(),
    watchPricesRequest(),
    watchPostalCodesRequest(),
    watchQualifiersRequest(),
    watchReservationsRequest(),
    watchRewardsRequest(),
    watchSpotsRequest(),
    watchUsersRequest(),
    watchWaitlistUsersRequest(),
    watchServiceFeeOffsetsRequest(),
    watchSurveysRequest(),
    watchSpotActionsRequest(),
    watchTheaterChainsRequest(),
    watchTheatersRequest(),
    watchTicketTypesRequest(),
    watchItemsRequest(),
    watchVipUsersRequest(),
    watchPollsRequest()
  ]);
}

export default rootSaga;
