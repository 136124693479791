import ENDPOINT from './ApiEndpoint';
import { baseAdminAPI, authHeader } from './apiBaseConfig';

export const getCampaigns = async () => {
  const response = await baseAdminAPI.get(`${ENDPOINT.V1}${ENDPOINT.CAMPAIGNS}`, {
    headers: authHeader()
  });

  return response;
};

export const getCampaign = async ({
  id,
}) => {
  const response = await baseAdminAPI.get(`${ENDPOINT.V1}${ENDPOINT.CAMPAIGNS}/${id}`, {
    headers: authHeader()
  });

  return response;
};

export const createCampaign = async ({
  name,
  startDate,
  endDate,
  maxDistance,
}) => {
  const response = await baseAdminAPI.post(`${ENDPOINT.V1}${ENDPOINT.CAMPAIGNS}`, {
    name,
    startDate,
    endDate,
    maxDistance,
  }, {
    headers: authHeader()
  });

  return response;
};

export const updateCampaign = async ({
  id,
  name,
  startDate,
  endDate,
  maxDistance,
}) => {
  const response = await baseAdminAPI.put(`${ENDPOINT.V1}${ENDPOINT.CAMPAIGNS}/${id}`, {
    name,
    startDate,
    endDate,
    maxDistance,
  }, {
    headers: authHeader()
  });

  return response;
};

export const getCampaignUsers = async ({
  id,
}) => {
  const response = await baseAdminAPI.get(`${ENDPOINT.V1}${ENDPOINT.CAMPAIGNS}/${id}${ENDPOINT.USERS}`, {
    headers: authHeader()
  });

  return response;
};

export const addCampaignUser = async ({
  campaignId,
  userId,
}) => {
  const response = await baseAdminAPI.post(`${ENDPOINT.V1}${ENDPOINT.CAMPAIGNS}/${campaignId}${ENDPOINT.USERS}/${userId}`, {
  }, {
    headers: authHeader()
  });

  return response;
};

export const removeCampaignUser = async ({
  campaignId,
  userId,
}) => {
  const response = await baseAdminAPI.delete(`${ENDPOINT.V1}${ENDPOINT.CAMPAIGNS}/${campaignId}${ENDPOINT.USERS}/${userId}`, {
    headers: authHeader()
  });

  return response;
};
