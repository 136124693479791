import { put, call, takeLatest } from 'redux-saga/effects';
import { Types, Creators } from 'src/redux/actions/waitlistUsersAction';

import {
  searchWaitlistUser,
  addWaitlistUser,
  getWaitlistUser,
  updateWaitlistUser,
  getWaitlistUsersToInvite,
  inviteWaitlistUsers,
  getWaitlistUsersInNetworkStats,
  inviteAllInNetwork,
} from 'src/api-client/waitlistUsersApi';

function* searchWaitlistUserRequest(action) {
  const { params } = action;

  try {
    const response = yield call(searchWaitlistUser, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.searchWaitlistUserSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.searchWaitlistUserFailed(error));
  }
}

function* addWaitlistUserRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(addWaitlistUser, params);

    if (response.status === 200) {
      yield put(Creators.addWaitlistUserSuccess(params));
    } else {
      yield put(Creators.addWaitlistUserFailed(response.data));
    }

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.addWaitlistUserFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* getWaitlistUserRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getWaitlistUser, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getWaitlistUserSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getWaitlistUserFailed(error));
  }
}

function* updateWaitlistUserRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateWaitlistUser, params);
    yield put(Creators.updateWaitlistUserSuccess(params));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateWaitlistUserFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* getWaitlistUsersToInviteRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getWaitlistUsersToInvite, params);

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getWaitlistUsersToInviteSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getWaitlistUsersToInviteFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* inviteWaitlistUsersRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(inviteWaitlistUsers, params);

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.inviteWaitlistUsersSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.inviteWaitlistUsersFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* getWaitlistUsersInNetworkStatsRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getWaitlistUsersInNetworkStats, params);

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getWaitlistUsersInNetworkStatsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getWaitlistUsersInNetworkStatsFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* inviteAllInNetworkRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(inviteAllInNetwork, params);

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.inviteAllInNetworkSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.inviteAllInNetworkFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

export default function* watchWaitlistUsersRequest() {
  yield takeLatest(Types.SEARCH_WAITLIST_USER_REQUEST, searchWaitlistUserRequest);
  yield takeLatest(Types.ADD_WAITLIST_USER_REQUEST, addWaitlistUserRequest);
  yield takeLatest(Types.GET_WAITLIST_USER_REQUEST, getWaitlistUserRequest);
  yield takeLatest(Types.UPDATE_WAITLIST_USER_REQUEST, updateWaitlistUserRequest);
  yield takeLatest(Types.GET_WAITLIST_USERS_TO_INVITE_REQUEST, getWaitlistUsersToInviteRequest);
  yield takeLatest(Types.INVITE_WAITLIST_USERS_REQUEST, inviteWaitlistUsersRequest);
  yield takeLatest(Types.GET_WAITLIST_USERS_IN_NETWORK_STATS_REQUEST,
    getWaitlistUsersInNetworkStatsRequest);
  yield takeLatest(Types.INVITE_ALL_IN_NETWORK_REQUEST, inviteAllInNetworkRequest);
}
