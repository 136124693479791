import { put, call, takeLatest } from 'redux-saga/effects';

// reducers
import { Types, Creators } from 'src/redux/actions/offersAction';

// apis
import {
  getListOffers,
  updateOffers,
  addPreshowToOffer,
  getOfferDetail
} from 'src/api-client/offersApi';

function* getListOffersRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getListOffers, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getListOffersSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListOffersFailed(error));
  }
}

function* updateOfferRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateOffers, params);
    yield put(Creators.updateOfferSuccess(response.data));
    // TODO: Just commented. Wait BE update data. Remove later
    // Call get list of offers when update success
    // yield* getListOffersRequest(action);

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateOfferFailed(error || e));
    if (callback) {
      callback(null, error || e);
    }
  }
}

function* addPreshowToOfferRequest(action) {
  const { params, callback } = action;
  try {
    const response = yield call(addPreshowToOffer, params);
    yield put(Creators.addPreshowToOfferSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.addPreshowToOfferFailed(error || e));

    if (callback) {
      callback(null, error || e);
    }
  }
}

function* getOfferDetailRequest(action) {
  const { params } = action;
  try {
    const response = yield call(getOfferDetail, params);
    yield put(Creators.getOfferDetailSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getOfferDetailFailed(error || e));
  }
}

export default function* watchOffersRequest() {
  yield takeLatest(Types.GET_LIST_OFFERS_REQUEST, getListOffersRequest);
  yield takeLatest(Types.UPDATE_OFFER_REQUEST, updateOfferRequest);
  yield takeLatest(
    Types.ADD_PRESHOW_TO_OFFER_REQUEST,
    addPreshowToOfferRequest
  );
  yield takeLatest(Types.GET_OFFER_DETAIL_REQUEST, getOfferDetailRequest);
}
