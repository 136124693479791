import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

import { Types } from '../actions/creditBandsAction';

export const INITIAL_STATE = Immutable({
  isLoading: false,
  creditBandsData: [],
  creditBandData: {},
  error: null,
});

const getListCreditBandsRequest = (state) => {
  return merge(state, {
    isLoading: true,
    creditBandsData: [],
    error: null
  });
};

const getListCreditBandsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    creditBandsData: data
  });
};

const getListCreditBandsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    creditBandsData: []
  });
};

const getCreditBandRequest = (state) => {
  return merge(state, {
    isLoading: true,
    creditBandData: {},
    error: null
  });
};

const getCreditBandSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    creditBandData: data
  });
};

const getCreditBandFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    creditBandData: {}
  });
};

const createCreditBandRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const createCreditBandSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    creditBandData: data
  });
};

const createCreditBandFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    creditBandData: {}
  });
};

export const HANDLERS = {
  [Types.GET_LIST_CREDIT_BANDS_REQUEST]: getListCreditBandsRequest,
  [Types.GET_LIST_CREDIT_BANDS_SUCCESS]: getListCreditBandsSuccess,
  [Types.GET_LIST_CREDIT_BANDS_FAILED]: getListCreditBandsFailed,

  [Types.GET_CREDIT_BAND_REQUEST]: getCreditBandRequest,
  [Types.GET_CREDIT_BAND_SUCCESS]: getCreditBandSuccess,
  [Types.GET_CREDIT_BAND_FAILED]: getCreditBandFailed,

  [Types.CREATE_CREDIT_BAND_REQUEST]: createCreditBandRequest,
  [Types.CREATE_CREDIT_BAND_SUCCESS]: createCreditBandSuccess,
  [Types.CREATE_CREDIT_BAND_FAILED]: createCreditBandFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
