import { put, call, takeLatest } from 'redux-saga/effects';
import { Types, Creators } from 'src/redux/actions/qualifiersAction';
import {
  getProviderQualifiers,
  getProviderQualifierById,
  createProviderQualifier,
  updateProviderQualifier,
  getUnifiedQualifiers,
  createUnifiedQualifier,
  updateUnifiedQualifier,
  getUnifiedQualifierById,
} from 'src/api-client/qualifiersApi';

export function* getListProviderQualifiersRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getProviderQualifiers, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getListProviderQualifiersSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListProviderQualifiersFailed(error || e));
  }
}

export function* getProviderQualifierRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getProviderQualifierById, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getProviderQualifierSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getProviderQualifierFailed(error || e));
  }
}

export function* createProviderQualifierRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(createProviderQualifier, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.createProviderQualifierSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.createProviderQualifierFailed(error || e));
  }
}

export function* updateProviderQualifierRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateProviderQualifier, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.updateProviderQualifierSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateProviderQualifierFailed(error || e));
  }
}

export function* getListUnifiedQualifiersRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getUnifiedQualifiers, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getListUnifiedQualifiersSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListUnifiedQualifiersFailed(error || e));
  }
}

export function* getUnifiedQualifierRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getUnifiedQualifierById, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getUnifiedQualifierSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getUnifiedQualifierFailed(error || e));
  }
}

export function* createUnifiedQualifierRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(createUnifiedQualifier, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.createUnifiedQualifierSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.createUnifiedQualifierFailed(error || e));
  }
}

export function* updateUnifiedQualifierRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateUnifiedQualifier, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.updateUnifiedQualifierSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.updateUnifiedQualifierFailed(error || e));
  }
}

export default function* watchQualifiersRequest() {
  yield takeLatest(Types.GET_LIST_PROVIDER_QUALIFIERS_REQUEST, getListProviderQualifiersRequest);
  yield takeLatest(Types.GET_PROVIDER_QUALIFIER_REQUEST, getProviderQualifierRequest);
  yield takeLatest(Types.CREATE_PROVIDER_QUALIFIER_REQUEST, createProviderQualifierRequest);
  yield takeLatest(Types.UPDATE_PROVIDER_QUALIFIER_REQUEST, updateProviderQualifierRequest);
  yield takeLatest(Types.GET_LIST_UNIFIED_QUALIFIERS_REQUEST, getListUnifiedQualifiersRequest);
  yield takeLatest(Types.GET_UNIFIED_QUALIFIER_REQUEST, getUnifiedQualifierRequest);
  yield takeLatest(Types.CREATE_UNIFIED_QUALIFIER_REQUEST, createUnifiedQualifierRequest);
  yield takeLatest(Types.UPDATE_UNIFIED_QUALIFIER_REQUEST, updateUnifiedQualifierRequest);
}
