import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

export const getListTheaters = async ({ key = '', offset = 0, limit = 10 }) => {
  const response = await adminAPI.get(ENDPOINT.THEATERS, {
    params: { key, offset, limit },
    headers: authHeader()
  });

  return response;
};

export const getTheaterDetails = async ({ theaterId }) => {
  const response = await adminAPI.get(`${ENDPOINT.THEATERS}/${theaterId}`, {
    headers: authHeader()
  });

  return response;
};

export const updateTheater = async ({
  theaterID,
  name,
  ticketPrice,
  theaterChainId,
  providerTheaterId,
  providerTheaterChainId,
  kindValue,
  statusValue,
  latitude,
  longitude
}) => {
  const response = await adminAPI.put(
    `${ENDPOINT.THEATERS}/${theaterID}`,
    {
      name,
      ticketPrice,
      kind: kindValue,
      status: statusValue,
      theaterChainId,
      providerTheaterId,
      providerTheaterChainId,
      latitude,
      longitude
    },
    { headers: authHeader() }
  );

  return response;
};

export const updateTheatersList = async ({ theaters }) => {
  const response = await adminAPI.post(
    `${ENDPOINT.THEATERS_FILE}`,
    {
      theaters
    },
    { headers: authHeader() }
  );

  return response;
};

export const createTheater = async (params) => {
  const response = await adminAPI.post(ENDPOINT.THEATERS, params, {
    headers: authHeader()
  });

  return response;
};
