import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from 'src/redux/actions/waitlistUsersAction';

export const INITIAL_STATE = Immutable({
  isLoading: false,
  waitlistUsers: [],
  waitlistUserDetails: {},
  networkStats: {},
  waitlistUsersToInvite: [],
  error: null,
  updateError: null,
  inviteWaitlistUsersError: null,
  failedInvitations: [],
});

const searchWaitlistUserRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const searchWaitlistUserSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    waitlistUsers: data,
    isLoading: false,
    error: null
  });
};

const searchWaitlistUserFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    waitlistUsers: [],
    error,
    isLoading: false
  });
};

const addWaitlistUserRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const addWaitlistUserSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    waitlistUserDetails: data,
    isLoading: false,
    error: null
  });
};

const addWaitlistUserFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    isLoading: false
  });
};

const getWaitlistUserRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getWaitlistUserSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    waitlistUserDetails: data,
    isLoading: false,
    error: null
  });
};

const getWaitlistUserFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    waitlistUserDetails: {},
    error,
    isLoading: false
  });
};

const updateWaitlistUserRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const updateWaitlistUserSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    waitlistUserDetails: data,
    isLoading: false,
    updateError: null
  });
};

const updateWaitlistUserFailed = (state) => {
  return merge(state, {
    updateError: 'Error',
    isLoading: false
  });
};

const getWaitlistUsersToInviteRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getWaitlistUsersToInviteSuccess = (state, action) => {
  const { data } = action;

  console.log(`waitlistUsersToInviteData: ${data}`);

  return merge(state, {
    waitlistUsersToInvite: data,
    isLoading: false,
    error: null
  });
};

const getWaitlistUsersToInviteFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    isLoading: false
  });
};

const inviteWaitlistUsersRequest = (state) => {
  return merge(state, {
    isLoading: true,
    failedInvitations: [],
    error: null
  });
};

const inviteWaitlistUsersSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    error: null,
    failedInvitations: [data.failed]
  });
};

const inviteWaitlistUsersFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    isLoading: false,
    failedInvitations: []
  });
};

const getWaitlistUsersInNetworkStatsRequest = (state) => {
  return merge(state, {
    isLoading: true,
    networkStats: {},
    error: null
  });
};

const getWaitlistUsersInNetworkStatsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    networkStats: data
  });
};

const getWaitlistUsersInNetworkStatsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    networkStats: {},
    isLoading: false,
    failedInvitations: [],
    error
  });
};

const inviteAllInNetworkRequest = (state) => {
  return merge(state, {
    isLoading: true,
    failedInvitations: []
  });
};

const inviteAllInNetworkSuccess = (state, action) => {
  const { data } = action;
  console.log(`data: ${data}`);

  return merge(state, {
    isLoading: false,
    error: null,
  });
};

const inviteAllInNetworkFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    isLoading: false
  });
};

export const HANDLERS = {
  [Types.SEARCH_WAITLIST_USER_REQUEST]: searchWaitlistUserRequest,
  [Types.SEARCH_WAITLIST_USER_SUCCESS]: searchWaitlistUserSuccess,
  [Types.SEARCH_WAITLIST_USER_FAILED]: searchWaitlistUserFailed,

  [Types.ADD_WAITLIST_USER_REQUEST]: addWaitlistUserRequest,
  [Types.ADD_WAITLIST_USER_SUCCESS]: addWaitlistUserSuccess,
  [Types.ADD_WAITLIST_USER_FAILED]: addWaitlistUserFailed,

  [Types.GET_WAITLIST_USER_REQUEST]: getWaitlistUserRequest,
  [Types.GET_WAITLIST_USER_SUCCESS]: getWaitlistUserSuccess,
  [Types.GET_WAITLIST_USER_FAILED]: getWaitlistUserFailed,

  [Types.UPDATE_WAITLIST_USER_REQUEST]: updateWaitlistUserRequest,
  [Types.UPDATE_WAITLIST_USER_SUCCESS]: updateWaitlistUserSuccess,
  [Types.UPDATE_WAITLIST_USER_FAILED]: updateWaitlistUserFailed,

  [Types.GET_WAITLIST_USERS_TO_INVITE_REQUEST]: getWaitlistUsersToInviteRequest,
  [Types.GET_WAITLIST_USERS_TO_INVITE_SUCCESS]: getWaitlistUsersToInviteSuccess,
  [Types.GET_WAITLIST_USERS_TO_INVITE_FAILED]: getWaitlistUsersToInviteFailed,

  [Types.INVITE_WAITLIST_USERS_REQUEST]: inviteWaitlistUsersRequest,
  [Types.INVITE_WAITLIST_USERS_SUCCESS]: inviteWaitlistUsersSuccess,
  [Types.INVITE_WAITLIST_USERS_FAILED]: inviteWaitlistUsersFailed,

  [Types.GET_WAITLIST_USERS_IN_NETWORK_STATS_REQUEST]: getWaitlistUsersInNetworkStatsRequest,
  [Types.GET_WAITLIST_USERS_IN_NETWORK_STATS_SUCCESS]: getWaitlistUsersInNetworkStatsSuccess,
  [Types.GET_WAITLIST_USERS_IN_NETWORK_STATS_FAILED]: getWaitlistUsersInNetworkStatsFailed,

  [Types.INVITE_ALL_IN_NETWORK_REQUEST]: inviteAllInNetworkRequest,
  [Types.INVITE_ALL_IN_NETWORK_SUCCESS]: inviteAllInNetworkSuccess,
  [Types.INVITE_ALL_IN_NETWORK_FAILED]: inviteAllInNetworkFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
