// libs
import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  // get list of admin users
  getListAdminUsersRequest: ['params'],
  getListAdminUsersSuccess: ['data'],
  getListAdminUsersFailed: ['error'],

  // get an admin user
  getAdminUserRequest: ['params'],
  getAdminUserSuccess: ['data'],
  getAdminUserFailed: ['error'],

  // create admin user
  createAdminUserRequest: ['params', 'callback'],
  createAdminUserSuccess: ['data'],
  createAdminUserFailed: ['error'],

  // update an admin user
  updateAdminProfileRequest: ['params', 'callback'],
  updateAdminProfileSuccess: ['data'],
  updateAdminProfileFailed: ['error'],

  // change the password of an admin user
  changeAdminPasswordRequest: ['params', 'callback'],
  changeAdminPasswordSuccess: ['data'],
  changeAdminPasswordFailed: ['error'],

  // reset the password of an admin user
  resetAdminPasswordRequest: ['params', 'callback'],
  resetAdminPasswordSuccess: ['data'],
  resetAdminPasswordFailed: ['error'],

  // activate an admin user
  activateAdminUserRequest: ['params', 'callback'],
  activateAdminUserSuccess: ['data'],
  activateAdminUserFailed: ['error'],

  // deactivate an admin user
  deactivateAdminUserRequest: ['params', 'callback'],
  deactivateAdminUserSuccess: ['data'],
  deactivateAdminUserFailed: ['error']
});
