import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

import { Types } from '../actions/giftsAction';

export const INITIAL_STATE = Immutable({
  isLoading: false,
  gifts: [],
  error: null,
});

const getUserGiftPurchasesRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getUserGiftPurchasesSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    gifts: data
  });
};

const getUserGiftPurchasesFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    gifts: []
  });
};

const sendGiftEmailRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const sendGiftEmailSuccess = (state) => {
  return merge(state, {
    isLoading: false,
  });
};

const sendGiftEmailFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
  });
};

const updateGiftEmailRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const updateGiftEmailSuccess = (state, action) => {
  const { data } = action;
  const updatedGift = data;

  const updatedGifts = state.gifts.map((gift) => {
    if (gift.id === updatedGift.id) {
      return updatedGift;
    }
    return gift;
  });

  return merge(state, {
    isLoading: false,
    gifts: updatedGifts
  });
};

const updateGiftEmailFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error
  });
};

export const HANDLERS = {
  [Types.GET_USER_GIFT_PURCHASES_REQUEST]: getUserGiftPurchasesRequest,
  [Types.GET_USER_GIFT_PURCHASES_SUCCESS]: getUserGiftPurchasesSuccess,
  [Types.GET_USER_GIFT_PURCHASES_FAILED]: getUserGiftPurchasesFailed,

  [Types.SEND_GIFT_EMAIL_REQUEST]: sendGiftEmailRequest,
  [Types.SEND_GIFT_EMAIL_SUCCESS]: sendGiftEmailSuccess,
  [Types.SEND_GIFT_EMAIL_FAILED]: sendGiftEmailFailed,

  [Types.UPDATE_GIFT_EMAIL_REQUEST]: updateGiftEmailRequest,
  [Types.UPDATE_GIFT_EMAIL_SUCCESS]: updateGiftEmailSuccess,
  [Types.UPDATE_GIFT_EMAIL_FAILED]: updateGiftEmailFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
