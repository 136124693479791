import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

// helpers
import cleanObject from '../helper/cleanObject';

/**
 * Handle request server to get list of items
 * @param {*} param
 * @returns response
 */
export const getListItems = async ({
  key = '',
  status = '',
  offset = 0,
  limit = 10
}) => {
  const params = { key, offset, limit, status };
  const newParams = cleanObject(params, 'status');
  const response = await adminAPI.get(ENDPOINT.ITEMS, {
    params: newParams,
    headers: authHeader()
  });

  return response;
};

/**
 * Handle get offers for item
 * @param {*} param0
 * @returns response
 */
export const getOffersForItem = async ({
  itemId,
  key = '',
  offset = 0,
  limit = 10
}) => {
  const response = await adminAPI.get(`${ENDPOINT.ITEMS}/${itemId}/offers`, {
    params: { key, offset, limit },
    headers: authHeader()
  });

  return response;
};

/**
 * Handle get item detail
 * @param {*} param0
 * @returns response
 */
export const getItemDetails = async ({ itemId }) => {
  const response = await adminAPI.get(`${ENDPOINT.ITEMS}/${itemId}`, {
    headers: authHeader()
  });

  return response;
};
