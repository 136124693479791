export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  REQUEST_PASSWORD_RESET: '/request_password_reset',
  SUBMIT_PASSWORD_RESET: '/submit_password_reset',
  NOTFOUND: '/404',
  ADVERTISINGS: '/advertisings',
  ADVERTISING_CREATE: '/advertising_create',
  ADVERTISING_PROVIDERS: '/advertising_providers',
  ADVERTISING_PROVIDERS_CREATE: '/advertising_providers_create',
  BLACKLISTS: '/blacklists',
  CAMPAIGNS: '/campaigns',
  CAMPAIGNS_CREATE: '/campaigns_create',
  CARD_TRANSACTIONS: '/card_transactions',
  CREDIT_BANDS: '/credit_bands',
  CREDIT_OFFSETS: '/credit_offsets',
  CREDIT_OFFSETS_CREATE: '/credit_offsets_create',
  CARDS: '/cards',
  CHECK_INS: '/check_ins',
  CORPORATE_PARTNERS: '/corporate_partners',
  CORPORATE_PARTNERS_CREATE: '/corporate_partners_create',
  GIFT_PURCHASES: '/gift_purchases',
  MCC: '/mcc',
  MERCHANT: '/merchants',
  NEW_MCC: '/new_mcc',
  NEW_MERCHANT: '/new_merchant',
  QUALIFIERS: '/qualifiers',
  QUALIFIERS_UNIFIED_CREATE: '/unified_qualifier_create',
  UNIFIED: '/unified',
  PROVIDER: '/provider',
  QUALIFIERS_PROVIDER_CREATE: '/provider_qualifier_create',
  SERVICE_FEE_OFFSETS: '/service_fee_offsets',
  SERVICE_FEE_OFFSETS_CREATE: '/service_fee_offsets_create',
  USERS: '/users',
  VIP_USERS: '/vip_users',
  VIP_USERS_CREATE: '/vip_users_create',
  WAITLIST_USERS: '/waitlist_users',
  WAITLIST_USERS_ADD: '/waitlist_users_add',
  OFFERS: '/offers',
  OFFER_WALLS: '/offerWalls',
  POLLS: '/polls',
  CREATE_POLLS: '/polls/create-poll',
  POSTAL_CODES: '/postal_codes',
  PRICES: '/prices',
  PRESHOWS: '/preshows',
  RESERVATIONS: '/reservations',
  REWARDS: '/rewards',
  SPOTS: '/spots',
  SPOT_ACTIONS: '/spot_actions',
  SURVEYS: '/surveys',
  ITEMS: '/items',
  BRANDS: '/brands',
  MOVIES: '/movies',
  NETWORKS: '/networks',
  THEATER_CHAINS: '/theater_chains',
  THEATER_CHAIN: '/theater_chain',
  THEATERS: '/theaters',
  TICKET_TYPES: '/ticket_types',
  ADMINISTRATORS: '/administrators',
  THEATERS_CREATE: '/theaters_create'
};

export const NAVBAR_ROUTES = [
  {
    title: 'Movies',
    icon: '/static/images/leftNav/spots.png',
    iconActive: '/static/images/leftNav/spots_selected.png',
    href: ROUTES.MOVIES
  },
  {
    title: 'Waitlist Users',
    icon: '/static/images/leftNav/users.png',
    iconActive: '/static/images/leftNav/users_selected.png',
    href: ROUTES.WAITLIST_USERS
  },
  {
    title: 'Users',
    icon: '/static/images/leftNav/users.png',
    iconActive: '/static/images/leftNav/users_selected.png',
    href: ROUTES.USERS
  },
  {
    title: 'Theaters',
    icon: '/static/images/leftNav/spots.png',
    iconActive: '/static/images/leftNav/spots_selected.png',
    href: ROUTES.THEATERS
  },
  {
    title: 'Theater Chains',
    icon: '/static/images/leftNav/spots.png',
    iconActive: '/static/images/leftNav/spots_selected.png',
    href: ROUTES.THEATER_CHAINS
  },
  {
    title: 'Ticket Types',
    icon: '/static/images/leftNav/spots.png',
    iconActive: '/static/images/leftNav/spots_selected.png',
    href: ROUTES.TICKET_TYPES
  },
  {
    title: 'Cards',
    icon: '/static/images/leftNav/spots.png',
    iconActive: '/static/images/leftNav/spots_selected.png',
    href: ROUTES.CARDS
  },
  {
    title: 'Gift Purchases',
    icon: 'static/images/leftNav/spots.png',
    iconActive: '/static/images/leftNav/spots_selected.png',
    href: ROUTES.GIFT_PURCHASES
  },
  {
    title: 'Advertising',
    icon: 'static/images/leftNav/spots.png',
    iconActive: '/static/images/leftNav/spots_selected.png',
    href: ROUTES.ADVERTISINGS
  },
  {
    title: 'Networks',
    icon: '/static/images/leftNav/spots.png',
    iconActive: '/static/images/leftNav/spots_selected.png',
    href: ROUTES.NETWORKS
  },
  {
    title: 'Postal Codes',
    icon: 'static/images/leftNav/spots.png',
    iconActive: '/static/images/leftNav/spots_selected.png',
    href: ROUTES.POSTAL_CODES
  },
  {
    title: 'Prices',
    icon: 'static/images/leftNav/spots.png',
    iconActive: '/static/images/leftNav/spots_selected.png',
    href: ROUTES.PRICES
  },
  {
    title: 'Blacklists',
    icon: 'static/images/leftNav/spots.png',
    iconActive: '/static/images/leftNav/spots_selected.png',
    href: ROUTES.BLACKLISTS
  },
  {
    title: 'Credit Schedule',
    icon: 'static/images/leftNav/spots.png',
    iconActive: '/static/images/leftNav/spots_selected.png',
    href: ROUTES.CREDIT_BANDS
  },
  {
    title: 'Credit Offsets',
    icon: 'static/images/leftNav/spots.png',
    iconActive: '/static/images/leftNav/spots_selected.png',
    href: ROUTES.CREDIT_OFFSETS
  },
  {
    title: 'Service Fee Offsets',
    icon: 'static/images/leftNav/spots.png',
    iconActive: '/static/images/leftNav/spots_selected.png',
    href: ROUTES.SERVICE_FEE_OFFSETS
  },
  {
    title: 'Qualifiers',
    icon: 'static/images/leftNav/spots.png',
    iconActive: '/static/images/leftNav/spots_selected.png',
    href: ROUTES.QUALIFIERS
  },
  {
    title: 'Corporate Partners',
    icon: 'static/images/leftNav/spots.png',
    iconActive: '/static/images/leftNav/spots_selected.png',
    href: ROUTES.CORPORATE_PARTNERS
  },
  {
    title: 'Campaigns',
    icon: 'static/images/leftNav/spots.png',
    iconActive: '/static/images/leftNav/spots_selected.png',
    href: ROUTES.CAMPAIGNS
  },
  {
    title: 'VIP Users',
    icon: '/static/images/leftNav/administrators.png',
    iconActive: '/static/images/leftNav/administrators_selected.png',
    href: ROUTES.VIP_USERS
  },
  {
    title: 'Administrators',
    icon: '/static/images/leftNav/administrators.png',
    iconActive: '/static/images/leftNav/administrators_selected.png',
    href: ROUTES.ADMINISTRATORS
  },
  {
    title: 'Polls',
    icon: '/static/images/leftNav/offers.png',
    iconActive: '/static/images/leftNav/offers_selected.png',
    href: ROUTES.POLLS
  }
];

export const NAME_PAGES = [
  { value: 'rewards', name: 'Rewards' },
  { value: 'spots', name: 'Spots' },
  { value: 'surveys', name: 'Surveys' },
  { value: 'items', name: 'Items' },
  { value: 'blacklists', name: 'Blacklists' },
  { value: 'brands', name: 'Brands' },
  { value: 'movies', name: 'Movies' },
  { value: 'postal_codes', name: 'Postal Codes' },
  { value: 'theaters', name: 'Theaters' },
  { value: 'administrators', name: 'Administrators' },
  { value: 'vip_users', name: 'VIP Users' }
];

export const PERSIST_KEY = 'preshow-admin';
