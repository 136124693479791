import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/core';
import theme from './theme';
import routes, { renderRoutes } from './routes';
import GlobalStyles from './components/GlobalStyles';

const history = createBrowserHistory();

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router history={history}>
        {renderRoutes(routes)}
      </Router>
    </ThemeProvider>
  );
};

export default App;
