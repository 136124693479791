// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from '../actions/networksAction';

export const INITIAL_STATE = Immutable({
  error: null,
  isLoading: false,
  networksData: {
    total: 0,
    networks: []
  },
  networkDetail: {},
  updateNetworkError: null,
  updateNetworksListError: null,
});

const getListNetworksRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListNetworksSuccess = (state, action) => {
  const {
    data: { total, networks }
  } = action;

  return merge(state, {
    isLoading: false,
    networksData: {
      total,
      networks
    }
  });
};

const getListNetworksFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    networksData: {
      total: 0,
      networks: []
    },
    networkDetail: {}
  });
};

const getNetworkDetailsRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getNetworkDetailsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    networkDetail: data
  });
};

const getNetworkDetailsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    networkDetail: {}
  });
};

const updateNetworkRequest = (state) => {
  return merge(state, {
    isLoading: true,
    updateNetworkError: null,
  });
};

const updateNetworkSuccess = (state, action) => {
  const { data } = action;
  const newNetworks = [...state.networksData.networks];
  const updatedNetwork = newNetworks.map((network) =>
    (network.id === data.id) ? data : network
  );

  return merge(state, {
    networksData: {
      networks: updatedNetwork,
    },
    isLoading: false,
    updateNetworkError: null
  });
};

const updateNetworkFailed = (state) => {
  return merge(state, {
    updateNetworkError: 'Error',
    isLoading: false
  });
};

export const HANDLERS = {
  [Types.GET_LIST_NETWORKS_REQUEST]: getListNetworksRequest,
  [Types.GET_LIST_NETWORKS_SUCCESS]: getListNetworksSuccess,
  [Types.GET_LIST_NETWORKS_FAILED]: getListNetworksFailed,

  [Types.GET_NETWORK_DETAILS_REQUEST]: getNetworkDetailsRequest,
  [Types.GET_NETWORK_DETAILS_SUCCESS]: getNetworkDetailsSuccess,
  [Types.GET_NETWORK_DETAILS_FAILED]: getNetworkDetailsFailed,

  [Types.UPDATE_NETWORK_REQUEST]: updateNetworkRequest,
  [Types.UPDATE_NETWORK_SUCCESS]: updateNetworkSuccess,
  [Types.UPDATE_NETWORK_FAILED]: updateNetworkFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
