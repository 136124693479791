import { put, call, takeLatest } from 'redux-saga/effects';
import { Types, Creators } from 'src/redux/actions/serviceFeeOffsetsAction';
import {
  getServiceFeeOffsets,
  getServiceFeeOffset,
  updateServiceFeeOffset,
  createServiceFeeOffset,
  removeServiceFeeOffset,
} from 'src/api-client/serviceFeeOffsetsApi';

function* getListServiceFeeOffsetsRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getServiceFeeOffsets, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getListServiceFeeOffsetsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListServiceFeeOffsetsFailed(error || e));
  }
}

function* getServiceFeeOffsetRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getServiceFeeOffset, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getServiceFeeOffsetSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getServiceFeeOffsetFailed(error || e));
  }
}

function* updateServiceFeeOffsetRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateServiceFeeOffset, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.updateServiceFeeOffsetSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateServiceFeeOffsetFailed(error || e));
  }
}

function* createServiceFeeOffsetRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(createServiceFeeOffset, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.createServiceFeeOffsetSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.createServiceFeeOffsetFailed(error || e));
  }
}

function* removeServiceFeeOffsetRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(removeServiceFeeOffset, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.removeServiceFeeOffsetSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.removeServiceFeeOffsetFailed(error || e));
  }
}

export default function* watchServiceFeeOffsetsRequest() {
  yield takeLatest(Types.GET_LIST_SERVICE_FEE_OFFSETS_REQUEST, getListServiceFeeOffsetsRequest);
  yield takeLatest(Types.GET_SERVICE_FEE_OFFSET_REQUEST, getServiceFeeOffsetRequest);
  yield takeLatest(Types.UPDATE_SERVICE_FEE_OFFSET_REQUEST, updateServiceFeeOffsetRequest);
  yield takeLatest(Types.CREATE_SERVICE_FEE_OFFSET_REQUEST, createServiceFeeOffsetRequest);
  yield takeLatest(Types.REMOVE_SERVICE_FEE_OFFSET_REQUEST, removeServiceFeeOffsetRequest);
}
