import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getListNetworksRequest: ['params'],
  getListNetworksSuccess: ['data'],
  getListNetworksFailed: ['error'],

  getNetworkDetailsRequest: ['params'],
  getNetworkDetailsSuccess: ['data'],
  getNetworkDetailsFailed: ['error'],

  updateNetworkRequest: ['params', 'callback'],
  updateNetworkSuccess: ['data'],
  updateNetworkFailed: ['error'],
});
