import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

export const getListTheaterChains = async () => {
  const response = await adminAPI.get(ENDPOINT.THEATER_CHAINS, {
    headers: authHeader()
  });

  return response;
};

export const addTheaterChain = async ({
  name,
  gracenoteTheaterChainId,
  eTicketTheaterChainId
}) => {
  const response = await adminAPI.post(`${ENDPOINT.THEATER_CHAINS}`, {
    name,
    gracenoteTheaterChainId,
    eTicketTheaterChainId
  },
  { headers: authHeader()
  });

  return response;
};

export const updateTheaterChain = async ({
  theaterChainID,
  name,
  eTicketTheaterChainId,
}) => {
  const response = await adminAPI.put(`${ENDPOINT.THEATER_CHAINS}/${theaterChainID}`, {
    name,
    eTicketTheaterChainId
  },
  { headers: authHeader()
  });

  return response;
};

export const getListTheatersForTheaterChain = async ({ theaterChainId }) => {
  const response = await adminAPI.get(
    `${ENDPOINT.THEATER_CHAINS}/${theaterChainId}/${ENDPOINT.THEATERS}`,
    { headers: authHeader() }
  );

  return response;
};
