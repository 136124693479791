import { put, call, takeLatest } from 'redux-saga/effects';

// reducers
import { Types, Creators } from 'src/redux/actions/spotActionsAction';

// apis
import getListSpotActions from 'src/api-client/spotActionsApi';

/**
 * Handle get list of Spot Actions
 * @param {*} action
 */
function* getListSpotActionsRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getListSpotActions, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getListSpotActionsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListSpotActionsFailed(error || e));
  }
}

export default function* watchSpotActionsRequest() {
  yield takeLatest(
    Types.GET_LIST_SPOT_ACTIONS_REQUEST,
    getListSpotActionsRequest
  );
}
