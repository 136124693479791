import { put, call, takeLatest } from 'redux-saga/effects';

// reducers
import { Types, Creators } from 'src/redux/actions/checkInsAction';

// apis
import {
  getCheckInDetails,
} from 'src/api-client/checkInsApi';

/**
 * Handle get Check In details
 * @param {*} action
 */
function* getCheckInDetailsRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getCheckInDetails, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getCheckInDetailsSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getCheckInDetailsFailed(error || e));
  }
}

export default function* watchCheckInsRequest() {
  yield takeLatest(Types.GET_CHECK_IN_DETAILS_REQUEST, getCheckInDetailsRequest);
}
