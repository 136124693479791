import { put, call, takeLatest } from 'redux-saga/effects';

// reducers
import { Types, Creators } from 'src/redux/actions/spotsAction';

// apis
import { getListSpots, getSpotDetails } from 'src/api-client/spotsApi';

/**
 * Handle get list of Spots
 * @param {*} action
 */
function* getListSpotsRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getListSpots, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getListSpotsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListSpotsFailed(error || e));
  }
}

/**
 * Handle get spot item details
 * @param {*} action
 */
function* getSpotDetailsRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getSpotDetails, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getSpotDetailsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getSpotDetailsFailed(error || e));
  }
}

export default function* watchSpotsRequest() {
  yield takeLatest(Types.GET_LIST_SPOTS_REQUEST, getListSpotsRequest);
  yield takeLatest(Types.GET_SPOT_DETAILS_REQUEST, getSpotDetailsRequest);
}
