import React from 'react';
import { parseCookies } from 'nookies';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ROUTES } from 'src/constants/routers';

const GuestGuard = ({ children }) => {
  const cookies = parseCookies();

  if (cookies.token !== undefined) {
    return <Redirect to={ROUTES.HOME} />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
