import { put, call, takeLatest } from 'redux-saga/effects';

// reducers
import { Types, Creators } from 'src/redux/actions/offerWallsAction';

// apis
import {
  getListOfferWalls,
  addOfferWallSection,
  refreshCategories,
  updateOfferWall,
  deleteSectionOfferWall,
  updateOfferWallSection
} from 'src/api-client/offerWallsApi';

function* getListOfferWallsRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getListOfferWalls, params);
    yield put(Creators.getListOfferWallsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListOfferWallsFailed(error || e));
  }
}

function* addSectionOfferWallRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(addOfferWallSection, params);
    yield put(Creators.addSectionOfferWallSuccess(response.data));
    // eslint-disable-next-line no-use-before-define
    yield* refreshCategoriesRequest();

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.addSectionOfferWallFailed(error));

    if (callback) {
      callback(null, error);
    }
  }
}

function* refreshCategoriesRequest() {
  try {
    const response = yield call(refreshCategories);
    yield put(Creators.refreshCategoriesSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.refreshCategoriesFailed(error || e));
  }
}

function* updateOfferWallRequest(action) {
  const { params } = action;
  try {
    const response = yield call(updateOfferWall, params);
    yield put(Creators.updateOfferWallSuccess(response.data));
    yield* refreshCategoriesRequest();
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateOfferWallFailed(error || e));
  }
}

/**
 * Delete section in Offer Wall request
 * @param {*} action
 */
function* deleteSectionOfferWallRequest(action) {
  const { params } = action;
  try {
    const response = yield call(deleteSectionOfferWall, params);
    yield put(Creators.deleteSectionOfferWallSuccess(response.data));
    yield* refreshCategoriesRequest();
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.deleteSectionOfferWallFailed(error || e));
  }
}

/**
 * Update section in Offer Wall request
 * @param {*} action
 */
function* updateSectionOfferWallRequest(action) {
  const { params } = action;
  try {
    const response = yield call(updateOfferWallSection, params);
    yield put(Creators.updateSectionOfferWallSuccess(response.data));
    yield* refreshCategoriesRequest();
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateSectionOfferWallFailed(error || e));
  }
}

export default function* watchOfferWallsRequest() {
  yield takeLatest(
    Types.GET_LIST_OFFER_WALLS_REQUEST,
    getListOfferWallsRequest
  );
  yield takeLatest(
    Types.ADD_SECTION_OFFER_WALL_REQUEST,
    addSectionOfferWallRequest
  );
  yield takeLatest(Types.REFRESH_CATEGORIES_REQUEST, refreshCategoriesRequest);
  yield takeLatest(Types.UPDATE_OFFER_WALL_REQUEST, updateOfferWallRequest);
  yield takeLatest(
    Types.DELETE_SECTION_OFFER_WALL_REQUEST,
    deleteSectionOfferWallRequest
  );
  yield takeLatest(
    Types.UPDATE_SECTION_OFFER_WALL_REQUEST,
    updateSectionOfferWallRequest
  );
}
