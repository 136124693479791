// libs
import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  insertBlacklistMCCRequest: ['params', 'callback'],
  insertBlacklistMCCSuccess: ['data'],
  insertBlacklistMCCFailed: ['error'],

  getListBlacklistMCCRequest: ['params', 'callback'],
  getListBlacklistMCCSuccess: ['data'],
  getListBlacklistMCCFailed: ['error'],

  getBlacklistMCCDetailsRequest: ['params'],
  getBlacklistMCCDetailsSuccess: ['data'],
  getBlacklistMCCDetailsFailed: ['error'],

  updateBlacklistMCCRequest: ['params', 'callback'],
  updateBlacklistMCCSuccess: ['data'],
  updateBlacklistMCCFailed: ['error'],

  insertBlacklistMerchantRequest: ['params', 'callback'],
  insertBlacklistMerchantSuccess: ['data'],
  insertBlacklistMerchantFailed: ['error'],

  getListBlacklistMerchantRequest: ['params', 'callback'],
  getListBlacklistMerchantSuccess: ['data'],
  getListBlacklistMerchantFailed: ['error'],

  getBlacklistMerchantDetailRequest: ['params'],
  getBlacklistMerchantDetailSuccess: ['data'],
  getBlacklistMerchantDetailFailed: ['error'],

  updateBlacklistMerchantRequest: ['params', 'callback'],
  updateBlacklistMerchantSuccess: ['data'],
  updateBlacklistMerchantFailed: ['error'],
});
