import ENDPOINT from './ApiEndpoint';
import { exhibServiceAdminAPI, authHeader } from './apiExhibitorAdminConfig';

export const getProviderQualifiers = async () => {
  const response = await exhibServiceAdminAPI.get(`${ENDPOINT.PROVIDER_QUALIFIERS}`, {
    headers: authHeader()
  });

  return response;
};

export const getProviderQualifierById = async ({
  id
}) => {
  const response = await exhibServiceAdminAPI.get(`${ENDPOINT.PROVIDER_QUALIFIERS}/${id}`, {
    headers: authHeader()
  });

  return response;
};

export const createProviderQualifier = async ({
  provider,
  providerKey,
  theaterChainId,
  unifiedQualifierId,
}) => {
  const response = await exhibServiceAdminAPI.post(
    `${ENDPOINT.PROVIDER_QUALIFIERS}`, {
      provider,
      providerKey,
      theaterChainId,
      unifiedQualifierId,
    },
    { headers: authHeader() }
  );

  return response;
};

export const updateProviderQualifier = async ({
  id,
  theaterChainId,
  providerQualifier,
  unifiedQualifierId,
}) => {
  const response = await exhibServiceAdminAPI.put(
    `${ENDPOINT.PROVIDER_QUALIFIERS}/${id}`, {
      theaterChainId,
      providerQualifier,
      unifiedQualifierId,
    },
    { headers: authHeader() }
  );

  return response;
};

export const getUnifiedQualifiers = async () => {
  const response = await exhibServiceAdminAPI.get(`${ENDPOINT.UNIFIED_QUALIFIERS}`, {
    headers: authHeader()
  });

  return response;
};

export const getUnifiedQualifierById = async ({
  id
}) => {
  const response = await exhibServiceAdminAPI.get(`${ENDPOINT.QUALIFIER}/${id}`, {
    headers: authHeader()
  });

  return response;
};

export const createUnifiedQualifier = async ({
  zoneCredits,
  priority,
  description,
  displayName,
  visible,
  disableScreening,
}) => {
  const updatedZoneCredits = zoneCredits.map((zc) => {
    return {
      zoneId: zc.zoneId,
      credits: parseInt(zc.credits, 10),
    };
  });

  const response = await exhibServiceAdminAPI.post(
    `${ENDPOINT.UNIFIED_QUALIFIERS}`, {
      zoneCredits: updatedZoneCredits,
      priority: parseInt(priority, 10),
      type: 'None', // Not Needed by the backend, aligned on sending dummy string
      description,
      displayName,
      visible,
      disableScreening,
    },
    { headers: authHeader() }
  );

  return response;
};

export const updateUnifiedQualifier = async ({
  id,
  zoneCredits,
  priority,
  type,
  description,
  displayName,
  visible,
  disableScreening,
}) => {
  const updatedZoneCredits = zoneCredits.map((zc) => {
    return {
      zoneId: zc.zoneId,
      credits: parseInt(zc.credits, 10),
    };
  });

  const response = await exhibServiceAdminAPI.put(
    `${ENDPOINT.UNIFIED_QUALIFIERS}/${id}`, {
      zoneCredits: updatedZoneCredits,
      priority: parseInt(priority, 10),
      type,
      description,
      displayName,
      visible,
      disableScreening,
    },
    { headers: authHeader() }
  );

  return response;
};
