import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getListCorporatePartnersRequest: ['params', 'callback'],
  getListCorporatePartnersSuccess: ['data'],
  getListCorporatePartnersFailed: ['error'],

  getCorporatePartnerRequest: ['params', 'callback'],
  getCorporatePartnerSuccess: ['data'],
  getCorporatePartnerFailed: ['error'],

  createCorporatePartnerRequest: ['params', 'callback'],
  createCorporatePartnerSuccess: ['data'],
  createCorporatePartnerFailed: ['error'],

  updateCorporatePartnerRequest: ['params', 'callback'],
  updateCorporatePartnerSuccess: ['data'],
  updateCorporatePartnerFailed: ['error'],

  getListCorporatePartnerUsersRequest: ['params', 'callback'],
  getListCorporatePartnerUsersSuccess: ['data'],
  getListCorporatePartnerUsersFailed: ['error'],

  getListCorporateCouponsRequest: ['params', 'callback'],
  getListCorporateCouponsSuccess: ['data'],
  getListCorporateCouponsFailed: ['error'],

  uploadCsvRequest: ['params', 'callback'],
  uploadCsvSuccess: ['data'],
  uploadCsvFailed: ['error'],

  getCSVUploadsRequest: ['params', 'callback'],
  getCSVUploadsSuccess: ['data'],
  getCSVUploadsFailed: ['error'],

  getCSVJobUploadStatusRequest: ['params', 'callback'],
  getCSVJobUploadStatusSuccess: ['data'],
  getCSVJobUploadStatusFailed: ['error'],
});
