import ENDPOINT from './ApiEndpoint';
import { baseAdminAPI, authHeader, authHeaderFile } from './apiBaseConfig';

export const getAdvertisings = async () => {
  const response = await baseAdminAPI.get(`${ENDPOINT.V1}${ENDPOINT.ADVERTISING}`, {
    headers: authHeader()
  });

  return response;
};

export const createAdvertising = async ({
  formData
}) => {
  const response = await baseAdminAPI.post(`${ENDPOINT.V1}${ENDPOINT.ADVERTISING}`, formData, {
    headers: authHeaderFile(),
    'Content-Type': 'multipart/form-data'
  });

  return response;
};

export const getAdvertising = async ({ id }) => {
  const response = await baseAdminAPI.get(`${ENDPOINT.V1}${ENDPOINT.ADVERTISING}/${id}`, {
    headers: authHeader()
  });

  return response;
};

export const updateAdvertising = async ({
  id,
  formData,
}) => {
  const response = await baseAdminAPI.put(`${ENDPOINT.V1}${ENDPOINT.ADVERTISING}/${id}`, formData, {
    headers: {
      ...authHeader(),
      'Content-Type': 'multipart/form-data',
    }
  });

  return response;
};

export const deleteAdvertising = async ({ id }) => {
  const response = await baseAdminAPI.delete(`${ENDPOINT.V1}${ENDPOINT.ADVERTISING}/${id}`, {
    headers: authHeader()
  });

  return response;
};

export const getAdvertisingProviders = async () => {
  const response = await baseAdminAPI.get(`${ENDPOINT.V1}${ENDPOINT.ADVERTISING}${ENDPOINT.PROVIDER}`, {
    headers: authHeader()
  });

  return response;
};

export const createAdvertisingProvider = async ({ name }) => {
  const response = await baseAdminAPI.post(`${ENDPOINT.V1}${ENDPOINT.ADVERTISING}${ENDPOINT.PROVIDER}`, {
    name,
  }, {
    headers: authHeader()
  });

  return response;
};

export const getAdvertisingProvider = async ({ id }) => {
  const response = await baseAdminAPI.get(`${ENDPOINT.V1}${ENDPOINT.ADVERTISING}${ENDPOINT.PROVIDER}/${id}`, {
    headers: authHeader()
  });

  return response;
};

export const updateAdvertisingProvider = async ({
  id,
  name,
}) => {
  const response = await baseAdminAPI.put(`${ENDPOINT.V1}${ENDPOINT.ADVERTISING}${ENDPOINT.PROVIDER}/${id}`, {
    name,
  }, {
    headers: authHeader()
  });

  return response;
};

export const deleteAdvertisingProvider = async ({ id }) => {
  const response = await baseAdminAPI.delete(`${ENDPOINT.V1}${ENDPOINT.ADVERTISING}${ENDPOINT.PROVIDER}/${id}`, {
    headers: authHeader()
  });

  return response;
};
