// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from '../actions/spotsAction';

export const INITIAL_STATE = Immutable({
  error: null,
  isLoading: false,
  spotsData: {
    total: 0,
    spots: []
  },
  spotDetail: {}
});

const getListSpotsRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListSpotsSuccess = (state, action) => {
  const {
    data: { total, spots }
  } = action;

  return merge(state, {
    isLoading: false,
    spotsData: {
      total,
      spots
    }
  });
};

const getListSpotsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    spotsData: {
      total: 0,
      spots: []
    }
  });
};

const getSpotDetailsRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getSpotDetailsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    spotDetail: data
  });
};

const getSpotDetailsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    spotDetail: {}
  });
};

export const HANDLERS = {
  [Types.GET_LIST_SPOTS_REQUEST]: getListSpotsRequest,
  [Types.GET_LIST_SPOTS_SUCCESS]: getListSpotsSuccess,
  [Types.GET_LIST_SPOTS_FAILED]: getListSpotsFailed,

  [Types.GET_SPOT_DETAILS_REQUEST]: getSpotDetailsRequest,
  [Types.GET_SPOT_DETAILS_SUCCESS]: getSpotDetailsSuccess,
  [Types.GET_SPOT_DETAILS_FAILED]: getSpotDetailsFailed
};

export default createReducer(INITIAL_STATE, HANDLERS);
