import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

import { Types } from '../actions/advertisingsAction';

export const INITIAL_STATE = Immutable({
  isLoading: false,
  advertising: {},
  advertisings: [],
  advertisingProvider: {},
  advertisingProviders: [],
  error: null,
});

const getListAdvertisingsRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListAdvertisingsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    advertisings: data
  });
};

const getListAdvertisingsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    advertisings: []
  });
};

const createAdvertisingRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const createAdvertisingSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    advertising: data
  });
};

const createAdvertisingFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
  });
};

const getAdvertisingRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getAdvertisingSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    advertising: data
  });
};

const getAdvertisingFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    advertising: {}
  });
};

const updateAdvertisingRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const updateAdvertisingSuccess = (state, action) => {
  const { data } = action;

  const updatedAdvertisings = state.advertisings.map((foundAdvertising) =>
    foundAdvertising.id === data.id ? data : foundAdvertising
  );

  return merge(state, {
    isLoading: false,
    advertising: data,
    advertisings: updatedAdvertisings
  });
};

const updateAdvertisingFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
  });
};

const deleteAdvertisingRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const deleteAdvertisingSuccess = (state, action) => {
  const { data } = action;

  const updatedAdvertisings = state.advertisings.filter((foundAdvertising) =>
    foundAdvertising.id !== data.id
  );

  return merge(state, {
    isLoading: false,
    advertising: {},
    advertisings: updatedAdvertisings
  });
};

const deleteAdvertisingFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
  });
};

const getListAdvertisingProvidersRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListAdvertisingProvidersSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    advertisingProviders: data
  });
};

const getListAdvertisingProvidersFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    advertisingProviders: []
  });
};

const createAdvertisingProviderRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const createAdvertisingProviderSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    advertisingProvider: data
  });
};

const createAdvertisingProviderFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
  });
};

const getAdvertisingProviderRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getAdvertisingProviderSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    advertisingProvider: data
  });
};

const getAdvertisingProviderFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    advertisingProvider: {}
  });
};

const updateAdvertisingProviderRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const updateAdvertisingProviderSuccess = (state, action) => {
  const { data } = action;

  const updatedAdvertisingProviders = state.advertisingProviders.map((advertisingProvider) =>
    advertisingProvider.id === data.id ? data : advertisingProvider
  );

  return merge(state, {
    isLoading: false,
    advertisingProvider: data,
    advertisingProviders: updatedAdvertisingProviders
  });
};

const updateAdvertisingProviderFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
  });
};

const deleteAdvertisingProviderRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const deleteAdvertisingProviderSuccess = (state, action) => {
  const { data } = action;

  const updatedAdvertisingProviders = state.advertisingProviders.filter((advertisingProvider) =>
    advertisingProvider.id !== data.id
  );

  return merge(state, {
    isLoading: false,
    advertisingProvider: {},
    advertisingProviders: updatedAdvertisingProviders
  });
};

const deleteAdvertisingProviderFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
  });
};

export const HANDLERS = {
  [Types.GET_LIST_ADVERTISINGS_REQUEST]: getListAdvertisingsRequest,
  [Types.GET_LIST_ADVERTISINGS_SUCCESS]: getListAdvertisingsSuccess,
  [Types.GET_LIST_ADVERTISINGS_FAILED]: getListAdvertisingsFailed,

  [Types.CREATE_ADVERTISING_REQUEST]: createAdvertisingRequest,
  [Types.CREATE_ADVERTISING_SUCCESS]: createAdvertisingSuccess,
  [Types.CREATE_ADVERTISING_FAILED]: createAdvertisingFailed,

  [Types.GET_ADVERTISING_REQUEST]: getAdvertisingRequest,
  [Types.GET_ADVERTISING_SUCCESS]: getAdvertisingSuccess,
  [Types.GET_ADVERTISING_FAILED]: getAdvertisingFailed,

  [Types.UPDATE_ADVERTISING_REQUEST]: updateAdvertisingRequest,
  [Types.UPDATE_ADVERTISING_SUCCESS]: updateAdvertisingSuccess,
  [Types.UPDATE_ADVERTISING_FAILED]: updateAdvertisingFailed,

  [Types.DELETE_ADVERTISING_REQUEST]: deleteAdvertisingRequest,
  [Types.DELETE_ADVERTISING_SUCCESS]: deleteAdvertisingSuccess,
  [Types.DELETE_ADVERTISING_FAILED]: deleteAdvertisingFailed,

  [Types.GET_LIST_ADVERTISING_PROVIDERS_REQUEST]: getListAdvertisingProvidersRequest,
  [Types.GET_LIST_ADVERTISING_PROVIDERS_SUCCESS]: getListAdvertisingProvidersSuccess,
  [Types.GET_LIST_ADVERTISING_PROVIDERS_FAILED]: getListAdvertisingProvidersFailed,

  [Types.CREATE_ADVERTISING_PROVIDER_REQUEST]: createAdvertisingProviderRequest,
  [Types.CREATE_ADVERTISING_PROVIDER_SUCCESS]: createAdvertisingProviderSuccess,
  [Types.CREATE_ADVERTISING_PROVIDER_FAILED]: createAdvertisingProviderFailed,

  [Types.GET_ADVERTISING_PROVIDER_REQUEST]: getAdvertisingProviderRequest,
  [Types.GET_ADVERTISING_PROVIDER_SUCCESS]: getAdvertisingProviderSuccess,
  [Types.GET_ADVERTISING_PROVIDER_FAILED]: getAdvertisingProviderFailed,

  [Types.UPDATE_ADVERTISING_PROVIDER_REQUEST]: updateAdvertisingProviderRequest,
  [Types.UPDATE_ADVERTISING_PROVIDER_SUCCESS]: updateAdvertisingProviderSuccess,
  [Types.UPDATE_ADVERTISING_PROVIDER_FAILED]: updateAdvertisingProviderFailed,

  [Types.DELETE_ADVERTISING_PROVIDER_REQUEST]: deleteAdvertisingProviderRequest,
  [Types.DELETE_ADVERTISING_PROVIDER_SUCCESS]: deleteAdvertisingProviderSuccess,
  [Types.DELETE_ADVERTISING_PROVIDER_FAILED]: deleteAdvertisingProviderFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
