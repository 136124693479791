import { put, call, takeLatest } from 'redux-saga/effects';
import { Types, Creators } from 'src/redux/actions/cardTransactionsAction';
import {
  getCardTransaction,
} from 'src/api-client/cardTransactionsApi';

function* getCardTransactionRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getCardTransaction, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getCardTransactionSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getCardTransactionFailed(error || e));
  }
}

export default function* watchCardTransactionsRequest() {
  yield takeLatest(Types.GET_CARD_TRANSACTION_REQUEST, getCardTransactionRequest);
}
