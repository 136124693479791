// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from '../actions/postalCodesAction';

export const INITIAL_STATE = Immutable({
  error: null,
  isLoading: false,
  postalCodesData: {
    total: 0,
    postalCodes: []
  },
  postalCodeDetail: {},
  updatePostalCodeError: null
});

const getListPostalCodesRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListPostalCodesSuccess = (state, action) => {
  const {
    data: { total, postalCodes }
  } = action;

  return merge(state, {
    isLoading: false,
    postalCodesData: {
      total,
      postalCodes
    }
  });
};

const getListPostalCodesFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    postalCodesData: {
      total: 0,
      postalCodes: []
    },
    postalCodeDetail: {}
  });
};

const getPostalCodeDetailsRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getPostalCodeDetailsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    postalCodeDetail: data
  });
};

const getPostalCodeDetailsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    postalCodeDetail: {}
  });
};

/**
 * Handle request update theater
 * @param {*} state
 * @param {*} action
 * @returns
 */
const updatePostalCodeRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const updatePostalCodeSuccess = (state, action) => {
  const { data } = action;
  const newPostalCodes = [...state.postalCodesData.postalCodes];

  return merge(state, {
    // TODO: mocking users list after update. Call getListTheaters when submit update
    postalCodesData: {
      ...state.postalCodesData,
      postalCodes: newPostalCodes.map((postalCode) =>
        postalCode.id === data.postalCodeId ? { id: postalCode.id, ...data } : postalCode
      )
    },
    isLoading: false,
    updatePostalCodeError: null
  });
};

const updatePostalCodeFailed = (state) => {
  return merge(state, {
    updatePostalCodeError: 'Error',
    isLoading: false
  });
};

export const HANDLERS = {
  [Types.GET_LIST_POSTAL_CODES_REQUEST]: getListPostalCodesRequest,
  [Types.GET_LIST_POSTAL_CODES_SUCCESS]: getListPostalCodesSuccess,
  [Types.GET_LIST_POSTAL_CODES_FAILED]: getListPostalCodesFailed,

  [Types.GET_POSTAL_CODE_DETAILS_REQUEST]: getPostalCodeDetailsRequest,
  [Types.GET_POSTAL_CODE_DETAILS_SUCCESS]: getPostalCodeDetailsSuccess,
  [Types.GET_POSTAL_CODE_DETAILS_FAILED]: getPostalCodeDetailsFailed,

  [Types.UPDATE_POSTAL_CODE_REQUEST]: updatePostalCodeRequest,
  [Types.UPDATE_POSTAL_CODE_SUCCESS]: updatePostalCodeSuccess,
  [Types.UPDATE_POSTAL_CODE_FAILED]: updatePostalCodeFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
