import { put, call, takeLatest } from 'redux-saga/effects';

// reducers
import { Types, Creators } from 'src/redux/actions/theatersAction';

// apis
import {
  createTheater,
  getListTheaters,
  getTheaterDetails,
  updateTheater,
  updateTheatersList
} from 'src/api-client/theatersApi';

import { getServiceFeeForTheater } from 'src/api-client/serviceFeeOffsetsApi';

/**
 * Handle get list of Theaters
 * @param {*} action
 */
function* getListTheatersRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getListTheaters, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getListTheatersSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListTheatersFailed(error || e));
  }
}

/**
 * Handle get Theater details
 * @param {*} action
 */
function* getTheaterDetailsRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getTheaterDetails, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getTheaterDetailsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getTheaterDetailsFailed(error || e));
  }
}

/**
 * Handle update theater
 */
function* updateTheaterRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateTheater, params);
    yield put(Creators.updateTheaterSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateTheaterFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

/**
 * Handle update theater list
 */
function* updateTheatersListRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateTheatersList, params);
    yield put(Creators.updateTheatersListSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateTheatersListFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* getServiceFeeForTheaterRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getServiceFeeForTheater, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getServiceFeeForTheaterSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.getServiceFeeForTheaterFailed(error || e));
  }
}

/**
 * Handle create theater
 */
function* createTheaterRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(createTheater, params);
    yield put(Creators.createTheaterSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.createTheaterFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

export default function* watchTheatersRequest() {
  yield takeLatest(Types.GET_LIST_THEATERS_REQUEST, getListTheatersRequest);
  yield takeLatest(Types.GET_THEATER_DETAILS_REQUEST, getTheaterDetailsRequest);
  yield takeLatest(Types.UPDATE_THEATER_REQUEST, updateTheaterRequest);
  yield takeLatest(
    Types.UPDATE_THEATERS_LIST_REQUEST,
    updateTheatersListRequest
  );
  yield takeLatest(
    Types.GET_SERVICE_FEE_FOR_THEATER_REQUEST,
    getServiceFeeForTheaterRequest
  );
  yield takeLatest(Types.CREATE_THEATER_REQUEST, createTheaterRequest);
}
