import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

import { Types } from '../actions/cardTransactionsAction';

export const INITIAL_STATE = Immutable({
  isLoading: false,
  cardTransactionData: {},
  errorRetrieveCardTransaction: null,
});

const getCardTransactionRequest = (state) => {
  return merge(state, {
    isLoading: true,
    errorRetrieveCardTransaction: null
  });
};

const getCardTransactionSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    cardTransactionData: data
  });
};

const getCardTransactionFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    errorRetrieveCardTransaction: error,
    cardTransactionData: {}
  });
};

export const HANDLERS = {
  [Types.GET_CARD_TRANSACTION_REQUEST]: getCardTransactionRequest,
  [Types.GET_CARD_TRANSACTION_SUCCESS]: getCardTransactionSuccess,
  [Types.GET_CARD_TRANSACTION_FAILED]: getCardTransactionFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
