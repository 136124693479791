import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getUserGiftPurchasesRequest: ['params'],
  getUserGiftPurchasesSuccess: ['data'],
  getUserGiftPurchasesFailed: ['error'],

  sendGiftEmailRequest: ['params', 'callback'],
  sendGiftEmailSuccess: ['data'],
  sendGiftEmailFailed: ['error'],

  updateGiftEmailRequest: ['params', 'callback'],
  updateGiftEmailSuccess: ['data'],
  updateGiftEmailFailed: ['error']
});
