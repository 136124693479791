import { put, call, takeLatest } from 'redux-saga/effects';
import { Types, Creators } from 'src/redux/actions/creditOffsetsAction';
import {
  getCreditOffsets,
  getCreditOffset,
  updateCreditOffset,
  createCreditOffset,
} from 'src/api-client/creditOffsetsApi';

function* getListCreditOffsetsRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getCreditOffsets, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getListCreditOffsetsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListCreditOffsetsFailed(error || e));
  }
}

function* getCreditOffsetRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getCreditOffset, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getCreditOffsetSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getCreditOffsetFailed(error || e));
  }
}

function* updateCreditOffsetRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateCreditOffset, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.updateCreditOffsetSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateCreditOffsetFailed(error || e));
  }
}

function* createCreditOffsetRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(createCreditOffset, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.createCreditOffsetSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.createCreditOffsetFailed(error || e));
  }
}

export default function* watchCreditOffsetsRequest() {
  yield takeLatest(Types.GET_LIST_CREDIT_OFFSETS_REQUEST, getListCreditOffsetsRequest);
  yield takeLatest(Types.GET_CREDIT_OFFSET_REQUEST, getCreditOffsetRequest);
  yield takeLatest(Types.UPDATE_CREDIT_OFFSET_REQUEST, updateCreditOffsetRequest);
  yield takeLatest(Types.CREATE_CREDIT_OFFSET_REQUEST, createCreditOffsetRequest);
}
