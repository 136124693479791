// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from 'src/redux/actions/brandsAction';

export const INITIAL_STATE = Immutable({
  brandsData: {
    total: 0,
    brands: []
  },
  isLoading: false,
  brandDetails: {
  },
  error: null,
  updateError: null,
});

/**
 * Handle get request list user
 * @param {*} state
 * @returns
 */
const getListBrandsRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getListBrandsSuccess = (state, action) => {
  const {
    data: { total, brands }
  } = action;

  return merge(state, {
    brandsData: {
      total,
      brands
    },
    isLoading: false,
    error: {}
  });
};

const getListBrandsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    brandsData: {
      total: 0,
      brands: []
    },
    error,
    isLoading: false
  });
};

/**
 * Create new user
 * @param {*} state
 * @returns
 */
const createBrandRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const createBrandSuccess = (state, action) => {
  const { data } = action;
  const {
    brandsData: { brands, total }
  } = state;

  return merge(state, {
    brandsData: {
      ...state.brandsData,
      total: total + 1,
      brands: [data, ...brands]
    },
    isLoading: false
  });
};

const createBrandFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    isLoading: false
  });
};

/**
 * Handle request update profile
 * @param {*} state
 * @param {*} action
 * @returns
 */
const updateBrandRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const updateBrandSuccess = (state, action) => {
  const { data } = action;
  const newBrand = [...state.brandsData.brands];

  return merge(state, {
    // TODO: mocking users list after update. Call getListUsers when submit update
    brandsData: {
      ...state.brandsData,
      brands: newBrand.map((brand) =>
        brand.id === data.ID ? { id: brand.id, ...data } : brand
      )
    },
    isLoading: false,
    updateError: null
  });
};

const updateBrandFailed = (state) => {
  return merge(state, {
    updateError: 'Error',
    isLoading: false
  });
};

export const HANDLERS = {
  [Types.GET_LIST_BRANDS_REQUEST]: getListBrandsRequest,
  [Types.GET_LIST_BRANDS_SUCCESS]: getListBrandsSuccess,
  [Types.GET_LIST_BRANDS_FAILED]: getListBrandsFailed,

  [Types.CREATE_BRAND_REQUEST]: createBrandRequest,
  [Types.CREATE_BRAND_SUCCESS]: createBrandSuccess,
  [Types.CREATE_BRAND_FAILED]: createBrandFailed,

  [Types.UPDATE_BRAND_REQUEST]: updateBrandRequest,
  [Types.UPDATE_BRAND_SUCCESS]: updateBrandSuccess,
  [Types.UPDATE_BRAND_FAILED]: updateBrandFailed
};

export default createReducer(INITIAL_STATE, HANDLERS);
