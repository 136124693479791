import { put, call, takeLatest } from 'redux-saga/effects';

// reducers
import { Types, Creators } from 'src/redux/actions/moviesAction';

// apis
import {
  getListMovies,
  getMovie,
  updateMovie,
  getMoviesHomeList,
  addMoviesHomeList,
  addMoviesPoster,
  getMoviesCarousel,
  addMoviesCarousel,
  updateMovieStatus,
} from 'src/api-client/moviesApi';

/**
 * Handle get list of Movies
 * @param {*} action
 */
function* getListMoviesRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getListMovies, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getListMoviesSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListMoviesFailed(error || e));
  }
}

function* getMovieRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getMovie, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getMovieSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }
    yield put(Creators.getMovieFailed(error || e));
  }
}

function* updateMovieRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateMovie, params);
    yield put(Creators.updateMovieSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateMovieFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* getMoviesHomeListRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getMoviesHomeList, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getMoviesHomeListSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getMoviesHomeListFailed(error || e));
  }
}

function* addMoviesHomeListRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(addMoviesHomeList, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.addMoviesHomeListSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.addMoviesHomeListFailed(error || e));
  }
}

function* addMoviePosterRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(addMoviesPoster, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.addMoviePosterSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.addMoviePosterFailed(error || e));
    if (callback) {
      callback(null, error || e);
    }
  }
}

function* getMoviesCarouselRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getMoviesCarousel, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getMoviesCarouselSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getMoviesCarouselFailed(error || e));
  }
}

function* addMoviesCarouselRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(addMoviesCarousel, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.addMoviesCarouselSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.addMoviesCarouselFailed(error || e));
  }
}

function* updateMovieStatusRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateMovieStatus, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.updateMovieStatusSuccess(params));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.updateMovieStatusFailed(error || e));
  }
}

export default function* watchMoviesRequest() {
  yield takeLatest(Types.GET_LIST_MOVIES_REQUEST, getListMoviesRequest);
  yield takeLatest(Types.GET_MOVIE_REQUEST, getMovieRequest);
  yield takeLatest(Types.UPDATE_MOVIE_REQUEST, updateMovieRequest);
  yield takeLatest(Types.GET_MOVIES_HOME_LIST_REQUEST, getMoviesHomeListRequest);
  yield takeLatest(Types.ADD_MOVIES_HOME_LIST_REQUEST, addMoviesHomeListRequest);
  yield takeLatest(Types.ADD_MOVIE_POSTER_REQUEST, addMoviePosterRequest);
  yield takeLatest(Types.GET_MOVIES_CAROUSEL_REQUEST, getMoviesCarouselRequest);
  yield takeLatest(Types.ADD_MOVIES_CAROUSEL_REQUEST, addMoviesCarouselRequest);
  yield takeLatest(Types.UPDATE_MOVIE_STATUS_REQUEST, updateMovieStatusRequest);
}
