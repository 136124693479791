import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getListCreditOffsetsRequest: ['params', 'callback'],
  getListCreditOffsetsSuccess: ['data'],
  getListCreditOffsetsFailed: ['error'],

  getCreditOffsetRequest: ['params', 'callback'],
  getCreditOffsetSuccess: ['data'],
  getCreditOffsetFailed: ['error'],

  updateCreditOffsetRequest: ['params', 'callback'],
  updateCreditOffsetSuccess: ['data'],
  updateCreditOffsetFailed: ['error'],

  createCreditOffsetRequest: ['params', 'callback'],
  createCreditOffsetSuccess: ['data'],
  createCreditOffsetFailed: ['error'],
});
