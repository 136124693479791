// libs
import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getListPostalCodesRequest: ['params'],
  getListPostalCodesSuccess: ['data'],
  getListPostalCodesFailed: ['error'],

  getPostalCodeDetailsRequest: ['params'],
  getPostalCodeDetailsSuccess: ['data'],
  getPostalCodeDetailsFailed: ['error'],

  updatePostalCodeRequest: ['params', 'callback'],
  updatePostalCodeSuccess: ['data'],
  updatePostalCodeFailed: ['error']
});
