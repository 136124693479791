import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  small: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
    '& div': {
      width: 18,
      height: 18,
      backgroundColor: 'gray',
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: theme.spacing(1)
    }
  },
  medium: {
    display: 'flex',
    alignItems: 'center',
    fontSize: (props) => (props.size === 'lg' ? 16 : 14),
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: theme.spacing(1.3)
    }
  }
}));

const Icon = ({ url, label, size, width, active }) => {
  const classes = useStyles({ size, active });
  return (
    <div className={size === 'sm' ? classes.small : classes.medium}>
      <div>
        <img width={width} src={url} alt="icon" />
      </div>
      <span>{label}</span>
    </div>
  );
};

Icon.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.number,
  active: PropTypes.bool
};

Icon.defaultProps = {
  url: '',
  label: '',
  size: '',
  width: 12,
  active: false
};

export default memo(Icon);
