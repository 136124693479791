import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

import { Types } from 'src/redux/actions/vipUsersAction';

export const INITIAL_STATE = Immutable({
  vipUser: {},
  isLoading: false,
  error: null,
});

const createVipUserRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const createVipUserSuccess = (state) => {
  return merge(state, {
    isLoading: false,
    error: null
  });
};

const createVipUserFailed = (state, action) => {
  const { error } = action;
  return merge(state, {
    isLoading: false,
    error
  });
};

export const HANDLERS = {
  [Types.CREATE_VIP_USER_REQUEST]: createVipUserRequest,
  [Types.CREATE_VIP_USER_SUCCESS]: createVipUserSuccess,
  [Types.CREATE_VIP_USER_FAILED]: createVipUserFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
