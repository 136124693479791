import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

import cleanObject from '../helper/cleanObject';

/**
 * Handle request server to get list of user
 * @param {*} param
 * @returns response
 */
export const getListBrands = async ({ key = '', status = '', offset = 0, limit = 10 }) => {
  const params = { key, offset, limit, status };
  const newParams = cleanObject(params, 'status');
  const response = await adminAPI.get(ENDPOINT.BRANDS, {
    params: newParams,
    headers: authHeader()
  });

  return response;
};

/**
 * Handle request server to create new user
 * @param {*} param0
 * @returns
 */
export const createBrand = async ({
  name,
  info,
  email,
  kind
}) => {
  const response = await adminAPI.post(
    ENDPOINT.BRANDS,
    {
      name,
      info,
      email,
      kind,
    },
    { headers: authHeader() }
  );

  return response;
};

/**
 * Handle request server update profile for user
 * @param {*} param0
 * @returns
 */
export const updateBrand = async ({
  ID,
  name,
  info,
  email,
  kind,
}) => {
  const response = await adminAPI.put(
    `${ENDPOINT.BRANDS}/${ID}`,
    {
      name,
      info,
      email,
      kind,
    },
    { headers: authHeader() }
  );

  return response;
};
