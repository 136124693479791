import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getListTheaterChainsRequest: ['params'],
  getListTheaterChainsSuccess: ['data'],
  getListTheaterChainsFailed: ['error'],

  addTheaterChainRequest: ['params', 'callback'],
  addTheaterChainSuccess: ['data'],
  addTheaterChainFailed: ['error'],

  updateTheaterChainRequest: ['params', 'callback'],
  updateTheaterChainSuccess: ['data'],
  updateTheaterChainFailed: ['error'],

  getListTheatersForTheaterChainRequest: ['params'],
  getListTheatersForTheaterChainSuccess: ['data'],
  getListTheatersForTheaterChainFailed: ['error'],
});
