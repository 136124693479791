import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

export const getListNetworks = async () => {
  const response = await adminAPI.get(`${ENDPOINT.NETWORKS}/all`, {
    headers: authHeader()
  });

  return response;
};

export const getNetworkDetails = async ({ networkId }) => {
  const response = await adminAPI.get(`${ENDPOINT.NETWORKS}/${networkId}`, {
    headers: authHeader()
  });

  return response;
};

export const updateNetwork = async ({
  networkId,
  name,
  statusValue,
}) => {
  const response = await adminAPI.put(`${ENDPOINT.NETWORKS}/${networkId}`, {
    name,
    status: statusValue,
  }, {
    headers: authHeader()
  });

  return response;
};
