import { put, call, takeLatest } from 'redux-saga/effects';

// reducers
import { Types, Creators } from 'src/redux/actions/itemsAction';

// apis
import {
  getListItems,
  getOffersForItem,
  getItemDetails
} from 'src/api-client/itemsApi';

/**
 * Handle get list of items
 * @param {*} action
 */
function* getListItemsRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getListItems, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getListItemsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListItemsFailed(error || e));
  }
}

/**
 * Handle get offers for item
 * @param {*} action
 */
function* getOffersForItemRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getOffersForItem, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getOffersForItemSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getOffersForItemFailed(error || e));
  }
}

/**
 * Handle get item detail request
 * @param {*} action
 */
function* getItemDetailsRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getItemDetails, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getItemDetailsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getItemDetailsFailed(error || e));
  }
}

export default function* watchItemsRequest() {
  yield takeLatest(Types.GET_LIST_ITEMS_REQUEST, getListItemsRequest);
  yield takeLatest(Types.GET_OFFERS_FOR_ITEM_REQUEST, getOffersForItemRequest);
  yield takeLatest(Types.GET_ITEM_DETAILS_REQUEST, getItemDetailsRequest);
}
