// libs
import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getPollRequest: ['params', 'callback'],
  getPollSuccess: ['data'],
  getPollFailed: ['error'],

  addPollRequest: ['params', 'callback'],
  addPollSuccess: ['data'],
  addPollFailed: ['error'],

  updatePollRequest: ['params', 'callback'],
  updatePollSuccess: ['data'],
  updatePollFailed: ['error']
});
