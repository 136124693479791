import { put, call, takeLatest } from 'redux-saga/effects';

// reducers
import { Types, Creators } from 'src/redux/actions/blacklistAction';

// apis
import {
  insertBlacklistMCC,
  getListBlacklistMCCs,
  getBlacklistMCCDetails,
  updateBlacklistMCC,
  insertBlacklistMerchant,
  getListBlacklistMerchants,
  getBlacklistMerchantDetails,
  updateBlacklistMerchant,
} from 'src/api-client/blacklistsApi';

function* insertBlacklistMCCRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(insertBlacklistMCC, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.insertBlacklistMCCSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.insertBlacklistMCCFailed(error || e));
  }
}

function* getListBlacklistMCCRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getListBlacklistMCCs, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getListBlacklistMCCSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListBlacklistMCCFailed(error || e));
  }
}

function* getBlacklistMCCDetailsRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getBlacklistMCCDetails, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getBlacklistMCCDetailsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getBlacklistMCCDetailsFailed(error || e));
  }
}

function* updateBlacklistMCCRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateBlacklistMCC, params);
    yield put(Creators.updateBlacklistMCCSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateBlacklistMCCFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

function* insertBlacklistMerchantRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(insertBlacklistMerchant, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.insertBlacklistMerchantSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.insertBlacklistMerchantFailed(error || e));
  }
}

function* getListBlacklistMerchantRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getListBlacklistMerchants, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getListBlacklistMerchantSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListBlacklistMerchantFailed(error || e));
  }
}

function* getBlacklistMerchantDetailRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getBlacklistMerchantDetails, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getBlacklistMerchantDetailSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getBlacklistMerchantDetailFailed(error || e));
  }
}

function* updateBlacklistMerchantRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateBlacklistMerchant, params);
    yield put(Creators.updateBlacklistMerchantSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateBlacklistMerchantFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

export default function* watchBlacklistsRequest() {
  yield takeLatest(Types.INSERT_BLACKLIST_MCC_REQUEST, insertBlacklistMCCRequest);
  yield takeLatest(Types.GET_LIST_BLACKLIST_MCC_REQUEST, getListBlacklistMCCRequest);
  yield takeLatest(Types.GET_BLACKLIST_MCC_DETAILS_REQUEST, getBlacklistMCCDetailsRequest);
  yield takeLatest(Types.UPDATE_BLACKLIST_MCC_REQUEST, updateBlacklistMCCRequest);
  yield takeLatest(Types.INSERT_BLACKLIST_MERCHANT_REQUEST, insertBlacklistMerchantRequest);
  yield takeLatest(Types.GET_LIST_BLACKLIST_MERCHANT_REQUEST, getListBlacklistMerchantRequest);
  yield takeLatest(Types.GET_BLACKLIST_MERCHANT_DETAIL_REQUEST, getBlacklistMerchantDetailRequest);
  yield takeLatest(Types.UPDATE_BLACKLIST_MERCHANT_REQUEST, updateBlacklistMerchantRequest);
}
