import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getListServiceFeeOffsetsRequest: ['params', 'callback'],
  getListServiceFeeOffsetsSuccess: ['data'],
  getListServiceFeeOffsetsFailed: ['error'],

  getServiceFeeOffsetRequest: ['params', 'callback'],
  getServiceFeeOffsetSuccess: ['data'],
  getServiceFeeOffsetFailed: ['error'],

  updateServiceFeeOffsetRequest: ['params', 'callback'],
  updateServiceFeeOffsetSuccess: ['data'],
  updateServiceFeeOffsetFailed: ['error'],

  createServiceFeeOffsetRequest: ['params', 'callback'],
  createServiceFeeOffsetSuccess: ['data'],
  createServiceFeeOffsetFailed: ['error'],

  removeServiceFeeOffsetRequest: ['params', 'callback'],
  removeServiceFeeOffsetSuccess: ['data'],
  removeServiceFeeOffsetFailed: ['error'],
});
