import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

export const getUserGiftPurchases = async ({ email }) => {
  const urlEncodedEmail = encodeURIComponent(email);
  const response = await adminAPI.get(`${ENDPOINT.GIFTS}?email=${urlEncodedEmail}`, {
    headers: authHeader()
  });

  return response;
};

export const sendGiftEmail = async ({ giftId }) => {
  const response = await adminAPI.post(`${ENDPOINT.GIFTS}/${giftId}${ENDPOINT.NOTIFICATIONS}`, {}, {
    headers: authHeader()
  });

  return response;
};

export const updateGiftEmail = async ({ giftId, recipientEmail }) => {
  const response = await adminAPI.put(`${ENDPOINT.GIFTS}/${giftId}`, {
    recipientEmail
  }, {
    headers: authHeader()
  });

  return response;
};
