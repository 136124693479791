import { put, call, takeLatest } from 'redux-saga/effects';

// reducers
import { Types, Creators } from 'src/redux/actions/reservationsAction';

// apis
import {
  getReservationDetails,
  createReservation,
  cancelReservation,
} from 'src/api-client/reservationsApi';

/**
 * Handle get Check In details
 * @param {*} action
 */
function* getReservationDetailsRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getReservationDetails, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getReservationDetailsSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getReservationDetailsFailed(error || e));
  }
}

function* createReservationRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(createReservation, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.createReservationSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.createReservationFailed(error || e));

    if (callback) {
      callback(null, error || e);
    }
  }
}

function* cancelReservationRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(cancelReservation, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.cancelReservationSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.cancelReservationFailed(error || e));

    if (callback) {
      callback(null, error || e);
    }
  }
}

export default function* watchReservationsRequest() {
  yield takeLatest(Types.GET_RESERVATION_DETAILS_REQUEST, getReservationDetailsRequest);
  yield takeLatest(Types.CREATE_RESERVATION_REQUEST, createReservationRequest);
  yield takeLatest(Types.CANCEL_RESERVATION_REQUEST, cancelReservationRequest);
}
