import { put, call, takeLatest } from 'redux-saga/effects';

import { Types, Creators } from 'src/redux/actions/vipUsersAction';

import {
  createVipUser,
} from 'src/api-client/vipUsersApi';

function* createVipUserRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(createVipUser, params);
    yield put(Creators.createVipUserSuccess(params));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.createVipUserFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

export default function* watchUsersRequest() {
  yield takeLatest(Types.CREATE_VIP_USER_REQUEST, createVipUserRequest);
}
