import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

import { Types } from '../actions/corporatePartnersAction';

export const INITIAL_STATE = Immutable({
  isLoading: false,
  isGetCorporatePartnerLoading: false,
  isgetCSVUploadsLoading: false,
  corporatePartners: [],
  corporatePartner: {},
  corporateCoupons: [],
  corporatePartnerUsers: [],
  csvUploads: [],
  csvUploadLogs: [],
  csvUpload: {},
  error: null,
});

const getListCorporatePartnersRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListCorporatePartnersSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    corporatePartners: data
  });
};

const getListCorporatePartnersFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    corporatePartners: []
  });
};

const getCorporatePartnerRequest = (state) => {
  return merge(state, {
    isGetCorporatePartnerLoading: true,
    error: null,
    corporatePartner: {},
    csvUploads: [],
  });
};

const getCorporatePartnerSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isGetCorporatePartnerLoading: false,
    corporatePartner: data
  });
};

const getCorporatePartnerFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isGetCorporatePartnerLoading: false,
    error,
    corporatePartner: {}
  });
};

const createCorporatePartnerRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null,
    corporatePartner: {},
  });
};

const createCorporatePartnerSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    corporatePartner: data,
    corporatePartners: state.corporatePartners.concat(data)
  });
};

const createCorporatePartnerFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    corporatePartner: {}
  });
};

const updateCorporatePartnerRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null,
  });
};

const updateCorporatePartnerSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    corporatePartner: data,
    corporatePartners: state.corporatePartners.map((corporatePartner) => {
      if (corporatePartner.id === data.id) {
        return data;
      }
      return corporatePartner;
    })
  });
};

const updateCorporatePartnerFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
  });
};

const getListCorporatePartnerUsersRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null,
    corporatePartnerUsers: [],
  });
};

const getListCorporatePartnerUsersSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    corporatePartnerUsers: data
  });
};

const getListCorporatePartnerUsersFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    corporatePartnerUsers: []
  });
};

const getListCorporateCouponsRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListCorporateCouponsSuccess = (state, action) => {
  const { data } = action;
  const updatedCoupons = [...state.corporateCoupons];

  data.forEach((newCoupon) => {
    const index = updatedCoupons.findIndex((existingCoupon) =>
      existingCoupon.couponId === newCoupon.couponId);

    if (index >= 0) {
      updatedCoupons[index] = newCoupon;
    } else {
      updatedCoupons.push(newCoupon);
    }
  });

  return merge(state, {
    isLoading: false,
    corporateCoupons: updatedCoupons,
  });
};

const getListCorporateCouponsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    corporateCoupons: []
  });
};

const uploadCsvRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const uploadCsvSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    csvUpload: data,
  });
};

const uploadCsvFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    csvUpload: {}
  });
};

const getCSVUploadsRequest = (state) => {
  return merge(state, {
    isgetCSVUploadsLoading: true,
    error: null,
    csvUploads: [],
  });
};

const getCSVUploadsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isgetCSVUploadsLoading: false,
    csvUploads: data,
  });
};

const getCSVUploadsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isgetCSVUploadsLoading: false,
    error,
    csvUploads: [],
  });
};

const getCSVJobUploadStatusRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null,
    csvUploadLogs: [],
  });
};

const getCSVJobUploadStatusSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    csvUploadLogs: data,
  });
};

const getCSVJobUploadStatusFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    csvUploadLogs: {}
  });
};

export const HANDLERS = {
  [Types.GET_LIST_CORPORATE_PARTNERS_REQUEST]: getListCorporatePartnersRequest,
  [Types.GET_LIST_CORPORATE_PARTNERS_SUCCESS]: getListCorporatePartnersSuccess,
  [Types.GET_LIST_CORPORATE_PARTNERS_FAILED]: getListCorporatePartnersFailed,

  [Types.GET_CORPORATE_PARTNER_REQUEST]: getCorporatePartnerRequest,
  [Types.GET_CORPORATE_PARTNER_SUCCESS]: getCorporatePartnerSuccess,
  [Types.GET_CORPORATE_PARTNER_FAILED]: getCorporatePartnerFailed,

  [Types.CREATE_CORPORATE_PARTNER_REQUEST]: createCorporatePartnerRequest,
  [Types.CREATE_CORPORATE_PARTNER_SUCCESS]: createCorporatePartnerSuccess,
  [Types.CREATE_CORPORATE_PARTNER_FAILED]: createCorporatePartnerFailed,

  [Types.UPDATE_CORPORATE_PARTNER_REQUEST]: updateCorporatePartnerRequest,
  [Types.UPDATE_CORPORATE_PARTNER_SUCCESS]: updateCorporatePartnerSuccess,
  [Types.UPDATE_CORPORATE_PARTNER_FAILED]: updateCorporatePartnerFailed,

  [Types.GET_LIST_CORPORATE_PARTNER_USERS_REQUEST]: getListCorporatePartnerUsersRequest,
  [Types.GET_LIST_CORPORATE_PARTNER_USERS_SUCCESS]: getListCorporatePartnerUsersSuccess,
  [Types.GET_LIST_CORPORATE_PARTNER_USERS_FAILED]: getListCorporatePartnerUsersFailed,

  [Types.GET_LIST_CORPORATE_COUPONS_REQUEST]: getListCorporateCouponsRequest,
  [Types.GET_LIST_CORPORATE_COUPONS_SUCCESS]: getListCorporateCouponsSuccess,
  [Types.GET_LIST_CORPORATE_COUPONS_FAILED]: getListCorporateCouponsFailed,

  [Types.UPLOAD_CSV_REQUEST]: uploadCsvRequest,
  [Types.UPLOAD_CSV_SUCCESS]: uploadCsvSuccess,
  [Types.UPLOAD_CSV_FAILED]: uploadCsvFailed,

  [Types.GET_CSV_UPLOADS_REQUEST]: getCSVUploadsRequest,
  [Types.GET_CSV_UPLOADS_SUCCESS]: getCSVUploadsSuccess,
  [Types.GET_CSV_UPLOADS_FAILED]: getCSVUploadsFailed,

  [Types.GET_CSV_JOB_UPLOAD_STATUS_REQUEST]: getCSVJobUploadStatusRequest,
  [Types.GET_CSV_JOB_UPLOAD_STATUS_SUCCESS]: getCSVJobUploadStatusSuccess,
  [Types.GET_CSV_JOB_UPLOAD_STATUS_FAILED]: getCSVJobUploadStatusFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
