import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getListCreditBandsRequest: ['params', 'callback'],
  getListCreditBandsSuccess: ['data'],
  getListCreditBandsFailed: ['error'],

  getCreditBandRequest: ['params', 'callback'],
  getCreditBandSuccess: ['data'],
  getCreditBandFailed: ['error'],

  createCreditBandRequest: ['params', 'callback'],
  createCreditBandSuccess: ['data'],
  createCreditBandFailed: ['error'],
});
