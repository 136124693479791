import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

import { Types } from '../actions/qualifiersAction';

export const INITIAL_STATE = Immutable({
  isLoading: false,
  providerQualifier: {},
  providerQualifiers: [],
  unifiedQualifier: {},
  unifiedQualifiers: [],
  error: null,
});

const getListProviderQualifiersRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListProviderQualifiersSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    providerQualifiers: data
  });
};

const getListProviderQualifiersFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    providerQualifiers: []
  });
};

const getProviderQualifierRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getProviderQualifierSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    providerQualifier: data
  });
};

const getProviderQualifierFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    providerQualifier: {}
  });
};

const createProviderQualifierRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const createProviderQualifierSuccess = (state) => {
  return merge(state, {
    isLoading: false,
  });
};

const createProviderQualifierFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error
  });
};

const updateProviderQualifierRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const updateProviderQualifierSuccess = (state, action) => {
  const { data } = action;
  const newProviderQualifiers = [...state.providerQualifiers];

  return merge(state, {
    isLoading: false,
    providerQualifiers: newProviderQualifiers.map((providerQualifier) =>
      providerQualifier.id === data.id ? { id: providerQualifier.id, ...data } : providerQualifier
    )
  });
};

const updateProviderQualifierFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    providerQualifiers: []
  });
};

const getListUnifiedQualifiersRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListUnifiedQualifiersSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    unifiedQualifiers: data
  });
};

const getListUnifiedQualifiersFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    unifiedQualifiers: []
  });
};

const getUnifiedQualifierRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getUnifiedQualifierSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    unifiedQualifier: data
  });
};

const getUnifiedQualifierFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    unifiedQualifier: {}
  });
};

const createUnifiedQualifierRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const createUnifiedQualifierSuccess = (state, action) => {
  const { data } = action;
  const newUnifiedQualifiers = [...state.unifiedQualifiers];

  return merge(state, {
    isLoading: false,
    unifiedQualifiers: newUnifiedQualifiers.map((unifiedQualifier) =>
      unifiedQualifier.id === data.id ? { id: unifiedQualifier.id, ...data } : unifiedQualifier
    )
  });
};

const createUnifiedQualifierFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
  });
};

const updateUnifiedQualifierRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const updateUnifiedQualifierSuccess = (state, action) => {
  const { data } = action;
  const newUnifiedQualifiers = [...state.unifiedQualifiers];

  return merge(state, {
    isLoading: false,
    unifiedQualifiers: newUnifiedQualifiers.map((unifiedQualifier) =>
      unifiedQualifier.id === data.id ? { id: unifiedQualifier.id, ...data } : unifiedQualifier
    )
  });
};

const updateUnifiedQualifierFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error
  });
};

export const HANDLERS = {
  [Types.GET_LIST_PROVIDER_QUALIFIERS_REQUEST]: getListProviderQualifiersRequest,
  [Types.GET_LIST_PROVIDER_QUALIFIERS_SUCCESS]: getListProviderQualifiersSuccess,
  [Types.GET_LIST_PROVIDER_QUALIFIERS_FAILED]: getListProviderQualifiersFailed,

  [Types.GET_PROVIDER_QUALIFIER_REQUEST]: getProviderQualifierRequest,
  [Types.GET_PROVIDER_QUALIFIER_SUCCESS]: getProviderQualifierSuccess,
  [Types.GET_PROVIDER_QUALIFIER_FAILED]: getProviderQualifierFailed,

  [Types.CREATE_PROVIDER_QUALIFIER_REQUEST]: createProviderQualifierRequest,
  [Types.CREATE_PROVIDER_QUALIFIER_SUCCESS]: createProviderQualifierSuccess,
  [Types.CREATE_PROVIDER_QUALIFIER_FAILED]: createProviderQualifierFailed,

  [Types.UPDATE_PROVIDER_QUALIFIER_REQUEST]: updateProviderQualifierRequest,
  [Types.UPDATE_PROVIDER_QUALIFIER_SUCCESS]: updateProviderQualifierSuccess,
  [Types.UPDATE_PROVIDER_QUALIFIER_FAILED]: updateProviderQualifierFailed,

  [Types.GET_LIST_UNIFIED_QUALIFIERS_REQUEST]: getListUnifiedQualifiersRequest,
  [Types.GET_LIST_UNIFIED_QUALIFIERS_SUCCESS]: getListUnifiedQualifiersSuccess,
  [Types.GET_LIST_UNIFIED_QUALIFIERS_FAILED]: getListUnifiedQualifiersFailed,

  [Types.GET_UNIFIED_QUALIFIER_REQUEST]: getUnifiedQualifierRequest,
  [Types.GET_UNIFIED_QUALIFIER_SUCCESS]: getUnifiedQualifierSuccess,
  [Types.GET_UNIFIED_QUALIFIER_FAILED]: getUnifiedQualifierFailed,

  [Types.CREATE_UNIFIED_QUALIFIER_REQUEST]: createUnifiedQualifierRequest,
  [Types.CREATE_UNIFIED_QUALIFIER_SUCCESS]: createUnifiedQualifierSuccess,
  [Types.CREATE_UNIFIED_QUALIFIER_FAILED]: createUnifiedQualifierFailed,

  [Types.UPDATE_UNIFIED_QUALIFIER_REQUEST]: updateUnifiedQualifierRequest,
  [Types.UPDATE_UNIFIED_QUALIFIER_SUCCESS]: updateUnifiedQualifierSuccess,
  [Types.UPDATE_UNIFIED_QUALIFIER_FAILED]: updateUnifiedQualifierFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
