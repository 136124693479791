import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

export const getReservationDetails = async ({ id }) => {
  const response = await adminAPI.get(`${ENDPOINT.RESERVATIONS}/${id}`, {
    headers: authHeader()
  });

  return response;
};

export const createReservation = async ({
  theaterId,
  movieId,
  credits,
  selectedDate,
  providerTheaterId,
  providerMovieId,
  format,
  userID,
  serviceFeeCredits,
  isOnline,
}) => {
  const response = await adminAPI.post(
    `${ENDPOINT.USERS}/${userID}/reservations`,
    {
      performanceInfo: {
        dateTime: selectedDate.toISOString(),
        startTime: selectedDate.toLocaleTimeString(),
        format,
        providerTheaterId,
        providerMovieId,
        movieId,
        credits: Number(credits),
        theaterId,
        isOnline,
        serviceFeeCredits: Number(serviceFeeCredits),
      },
      latitude: 0,
      longitude: 0
    },
    { headers: authHeader() }
  );

  return response;
};

export const cancelReservation = async ({
  id,
}) => {
  const response = await adminAPI.post(
    `${ENDPOINT.RESERVATIONS}/${id}${ENDPOINT.CANCEL}`,
    {},
    { headers: authHeader() }
  );

  return response;
};

export default getReservationDetails;
