// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from '../actions/blacklistAction';

export const INITIAL_STATE = Immutable({
  errorBlacklistMCCs: null,
  isLoading: false,
  blacklistMCCData: [],
  blacklistMerchantData: [],
  blacklistMCCDetail: {},
  blacklistMerchantDetail: {},
  retrieveBlacklistMCCDetailError: null,
  errorBlacklistMerchants: null,
  updateBlacklistMCCError: null,
  insertBlacklistMerchantError: null,
  retrieveBlacklistMerchantDetailError: null,
  updateBlacklistMerchantError: null,
});

const insertBlacklistMCCRequest = (state) => {
  return merge(state, {
    isLoading: true,
    insertBlacklistMCCError: null
  });
};

const insertBlacklistMCCSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    blacklistMCCData: [...state.blacklistMCCData, data]
  });
};

const insertBlacklistMCCFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    insertBlacklistMCCError: error,
    blacklistMCCData: [...state.blacklistMCCData],
    blacklistMCCDetail: {},
  });
};

const getListBlacklistMCCRequest = (state) => {
  return merge(state, {
    isLoading: true,
    errorBlacklistMCCs: null
  });
};

const getListBlacklistMCCSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    blacklistMCCData: data
  });
};

const getListBlacklistMCCFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    errorBlacklistMCCs: error,
    blacklistMCCData: [],
    blacklistMCCDetail: {}
  });
};

const getBlacklistMCCDetailsRequest = (state) => {
  return merge(state, {
    isLoading: true,
    retrieveBlacklistMCCDetailError: null
  });
};

const getBlacklistMCCDetailsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    blacklistMCCDetail: data
  });
};

const getBlacklistMCCDetailsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    retrieveBlacklistMCCDetailError: error,
    blacklistMCCDetail: {}
  });
};

/**
 * Handle request update blacklistMCC
 * @param {*} state
 * @param {*} action
 * @returns
 */
const updateBlacklistMCCRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const updateBlacklistMCCSuccess = (state, action) => {
  const { data } = action;
  const newBlacklistMCCs = [...state.blacklistMCCData];

  return merge(state, {
    // TODO: mocking users list after update. Call getListBlacklistMCCs when submit update
    blacklistMCCData: {
      ...state.blacklistMCCData,
      blacklistMCCs: newBlacklistMCCs.map((blacklistMCC) =>
        blacklistMCC.id === data.id ? { id: blacklistMCC.id, ...data } : blacklistMCC
      )
    },
    isLoading: false,
    updateBlacklistMCCError: null
  });
};

const updateBlacklistMCCFailed = (state) => {
  return merge(state, {
    updateBlacklistMCCError: 'Error',
    isLoading: false
  });
};

const insertBlacklistMerchantRequest = (state) => {
  return merge(state, {
    isLoading: true,
    insertBlacklistMerchantError: null
  });
};

const insertBlacklistMerchantSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    blacklistMerchantData: [...state.blacklistMerchantData, data]
  });
};

const insertBlacklistMerchantFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    insertBlacklistMerchantError: error,
    blacklistMerchantData: [...state.blacklistMerchantData],
    blacklistMerchantDetail: {},
  });
};

const getListBlacklistMerchantRequest = (state) => {
  return merge(state, {
    isLoading: true,
    errorBlacklistMerchants: null
  });
};

const getListBlacklistMerchantSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    blacklistMerchantData: data
  });
};

const getListBlacklistMerchantFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    errorBlacklistMerchants: error,
    blacklistMerchantData: [],
    blacklistMerchantDetail: {}
  });
};

const getBlacklistMerchantDetailRequest = (state) => {
  return merge(state, {
    isLoading: true,
    retrieveBlacklistMerchantDetailError: null
  });
};

const getBlacklistMerchantDetailSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    blacklistMerchantDetail: data
  });
};

const getBlacklistMerchantDetailFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    retrieveBlacklistMerchantDetailError: error,
    blacklistMerchantDetail: {}
  });
};

const updateBlacklistMerchantRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const updateBlacklistMerchantSuccess = (state, action) => {
  const { data } = action;
  const newBlacklistMerchants = [...state.blacklistMerchantData];

  return merge(state, {
    blacklistMerchantData: {
      ...state.blacklistMerchantData,
      blacklistMerchants: newBlacklistMerchants.map((blacklistMerchant) =>
        blacklistMerchant.id === data.id ? { id: blacklistMerchant.id, ...data } : blacklistMerchant
      )
    },
    isLoading: false,
    updateBlacklistMerchantError: null
  });
};

const updateBlacklistMerchantFailed = (state) => {
  return merge(state, {
    updateBlacklistMerchantError: 'Error',
    isLoading: false
  });
};

export const HANDLERS = {
  [Types.INSERT_BLACKLIST_MCC_REQUEST]: insertBlacklistMCCRequest,
  [Types.INSERT_BLACKLIST_MCC_SUCCESS]: insertBlacklistMCCSuccess,
  [Types.INSERT_BLACKLIST_MCC_FAILED]: insertBlacklistMCCFailed,

  [Types.GET_LIST_BLACKLIST_MCC_REQUEST]: getListBlacklistMCCRequest,
  [Types.GET_LIST_BLACKLIST_MCC_SUCCESS]: getListBlacklistMCCSuccess,
  [Types.GET_LIST_BLACKLIST_MCC_FAILED]: getListBlacklistMCCFailed,

  [Types.GET_BLACKLIST_MCC_DETAILS_REQUEST]: getBlacklistMCCDetailsRequest,
  [Types.GET_BLACKLIST_MCC_DETAILS_SUCCESS]: getBlacklistMCCDetailsSuccess,
  [Types.GET_BLACKLIST_MCC_DETAILS_FAILED]: getBlacklistMCCDetailsFailed,

  [Types.UPDATE_BLACKLIST_MCC_REQUEST]: updateBlacklistMCCRequest,
  [Types.UPDATE_BLACKLIST_MCC_SUCCESS]: updateBlacklistMCCSuccess,
  [Types.UPDATE_BLACKLIST_MCC_FAILED]: updateBlacklistMCCFailed,

  [Types.INSERT_BLACKLIST_MERCHANT_REQUEST]: insertBlacklistMerchantRequest,
  [Types.INSERT_BLACKLIST_MERCHANT_SUCCESS]: insertBlacklistMerchantSuccess,
  [Types.INSERT_BLACKLIST_MERCHANT_FAILED]: insertBlacklistMerchantFailed,

  [Types.GET_LIST_BLACKLIST_MERCHANT_REQUEST]: getListBlacklistMerchantRequest,
  [Types.GET_LIST_BLACKLIST_MERCHANT_SUCCESS]: getListBlacklistMerchantSuccess,
  [Types.GET_LIST_BLACKLIST_MERCHANT_FAILED]: getListBlacklistMerchantFailed,

  [Types.GET_BLACKLIST_MERCHANT_DETAIL_REQUEST]: getBlacklistMerchantDetailRequest,
  [Types.GET_BLACKLIST_MERCHANT_DETAIL_SUCCESS]: getBlacklistMerchantDetailSuccess,
  [Types.GET_BLACKLIST_MERCHANT_DETAIL_FAILED]: getBlacklistMerchantDetailFailed,

  [Types.UPDATE_BLACKLIST_MERCHANT_REQUEST]: updateBlacklistMerchantRequest,
  [Types.UPDATE_BLACKLIST_MERCHANT_SUCCESS]: updateBlacklistMerchantSuccess,
  [Types.UPDATE_BLACKLIST_MERCHANT_FAILED]: updateBlacklistMerchantFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
