// libs
import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  searchWaitlistUserRequest: ['params'],
  searchWaitlistUserSuccess: ['data'],
  searchWaitlistUserFailed: ['error'],

  addWaitlistUserRequest: ['params', 'callback'],
  addWaitlistUserSuccess: ['data'],
  addWaitlistUserFailed: ['error'],

  getWaitlistUserRequest: ['params'],
  getWaitlistUserSuccess: ['data'],
  getWaitlistUserFailed: ['error'],

  updateWaitlistUserRequest: ['params', 'callback'],
  updateWaitlistUserSuccess: ['data'],
  updateWaitlistUserFailed: ['error'],

  getWaitlistUsersToInviteRequest: ['params', 'callback'],
  getWaitlistUsersToInviteSuccess: ['data'],
  getWaitlistUsersToInviteFailed: ['error'],

  inviteWaitlistUsersRequest: ['params', 'callback'],
  inviteWaitlistUsersSuccess: ['data'],
  inviteWaitlistUsersFailed: ['error'],

  getWaitlistUsersInNetworkStatsRequest: ['params', 'callback'],
  getWaitlistUsersInNetworkStatsSuccess: ['data'],
  getWaitlistUsersInNetworkStatsFailed: ['error'],

  inviteAllInNetworkRequest: ['params', 'callback'],
  inviteAllInNetworkSuccess: ['data'],
  inviteAllInNetworkFailed: ['error'],
});
