// libs
import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getListPreshowsRequest: ['params'],
  getListPreshowsSuccess: ['data'],
  getListPreshowsFailed: ['error'],

  getPreshowWithSpotsRequest: ['params'],
  getPreshowWithSpotsSuccess: ['data'],
  getPreshowWithSpotsFailed: ['error']
});
