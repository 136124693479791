import ENDPOINT from './ApiEndpoint';
import {
  adminAPI,
  authHeader,
  authHeaderFile
} from './config';
import { exhibServiceAdminAPI } from './apiExhibitorAdminConfig';

export const getListMovies = async () => {
  const response = await adminAPI.get(ENDPOINT.MOVIES, {
    headers: authHeader()
  });

  return response;
};

export const getMovie = async ({ movieId }) => {
  const response = await exhibServiceAdminAPI.get(`v1${ENDPOINT.MOVIE}/${movieId}`, {
    headers: authHeader()
  });

  return response;
};

export const updateMovie = async ({
  movieId,
  rating,
  releaseDate,
  runningTime,
  synopsis,
  title,
  cast,
  directors,
  genres,
  trailer
}) => {
  const response = await exhibServiceAdminAPI.put(
    `v1${ENDPOINT.MOVIE}/${movieId}`,
    {
      rating,
      releaseDate,
      runningTime,
      synopsis,
      title,
      cast,
      directors,
      genres,
      trailer
    },
    { headers: authHeader() }
  );

  return response;
};

export const getMoviesHomeList = async () => {
  const response = await adminAPI.get(`${ENDPOINT.MOVIES_FILE}`, {
    headers: authHeader()
  });

  return response;
};

export const addMoviesHomeList = async ({
  newReleases,
  topBoxOffice,
  comingSoon,
}) => {
  const response = await adminAPI.post(
    `${ENDPOINT.MOVIES_FILE}`,
    {
      newReleases,
      topBoxOffice,
      comingSoon,
    },
    { headers: authHeader() }
  );

  return response;
};

export const addMoviesPoster = async ({
  poster,
  path
}) => {
  const response = await adminAPI.post(
    `${ENDPOINT.MOVIES_POSTER}/${path}`,
    poster,
    { headers: authHeaderFile() }
  );

  return response;
};

export const getMoviesCarousel = async () => {
  const response = await adminAPI.get(`${ENDPOINT.MOVIES}${ENDPOINT.FEATURED}`, {
    headers: authHeader()
  });

  return response;
};

export const addMoviesCarousel = async ({
  movieIds,
}) => {
  const response = await adminAPI.post(`${ENDPOINT.MOVIES}${ENDPOINT.FEATURED}`, {
    movieIds
  },
  { headers: authHeader() }
  );

  return response;
};

export const updateMovieStatus = async ({
  movieId,
  status,
}) => {
  const response = await exhibServiceAdminAPI.put(`v1${ENDPOINT.MOVIE}/${movieId}${ENDPOINT.STATUS}`, {
    status
  },
  { headers: authHeader() }
  );

  return response;
};
