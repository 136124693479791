import { put, call, takeLatest } from 'redux-saga/effects';
import { Types, Creators } from 'src/redux/actions/advertisingsAction';
import {
  getAdvertisings,
  createAdvertising,
  getAdvertising,
  updateAdvertising,
  deleteAdvertising,
  getAdvertisingProviders,
  createAdvertisingProvider,
  getAdvertisingProvider,
  updateAdvertisingProvider,
  deleteAdvertisingProvider,
} from 'src/api-client/advertisingsApi';

export function* getListAdvertisingsRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getAdvertisings, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getListAdvertisingsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListAdvertisingsFailed(error || e));
  }
}

export function* createAdvertisingRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(createAdvertising, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.createAdvertisingSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.createAdvertisingFailed(error || e));
  }
}

export function* getAdvertisingRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getAdvertising, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getAdvertisingSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getAdvertisingFailed(error || e));
  }
}

export function* updateAdvertisingRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateAdvertising, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.updateAdvertisingSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.updateAdvertisingFailed(error || e));
  }
}

export function* deleteAdvertisingRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(deleteAdvertising, params);

    if (response.status !== 200) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(params, null);
    }

    yield put(Creators.deleteAdvertisingSuccess(params));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.deleteAdvertisingFailed(error || e));
  }
}

export function* getListAdvertisingProvidersRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getAdvertisingProviders, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getListAdvertisingProvidersSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListAdvertisingProvidersFailed(error || e));
  }
}

export function* createAdvertisingProviderRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(createAdvertisingProvider, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.createAdvertisingProviderSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.createAdvertisingProviderFailed(error || e));
  }
}

export function* getAdvertisingProviderRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getAdvertisingProvider, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getAdvertisingProviderSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getAdvertisingProviderFailed(error || e));
  }
}

export function* updateAdvertisingProviderRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateAdvertisingProvider, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.updateAdvertisingProviderSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.updateAdvertisingProviderFailed(error || e));
  }
}

export function* deleteAdvertisingProviderRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(deleteAdvertisingProvider, params);

    if (response.status !== 200) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(params, null);
    }

    yield put(Creators.deleteAdvertisingProviderSuccess(params));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.deleteAdvertisingProviderFailed(error || e));
  }
}

export default function* watchAdvertisingsRequest() {
  yield takeLatest(Types.GET_LIST_ADVERTISINGS_REQUEST, getListAdvertisingsRequest);
  yield takeLatest(Types.CREATE_ADVERTISING_REQUEST, createAdvertisingRequest);
  yield takeLatest(Types.GET_ADVERTISING_REQUEST, getAdvertisingRequest);
  yield takeLatest(Types.UPDATE_ADVERTISING_REQUEST, updateAdvertisingRequest);
  yield takeLatest(Types.DELETE_ADVERTISING_REQUEST, deleteAdvertisingRequest);
  yield takeLatest(Types.GET_LIST_ADVERTISING_PROVIDERS_REQUEST,
    getListAdvertisingProvidersRequest);
  yield takeLatest(Types.CREATE_ADVERTISING_PROVIDER_REQUEST, createAdvertisingProviderRequest);
  yield takeLatest(Types.GET_ADVERTISING_PROVIDER_REQUEST, getAdvertisingProviderRequest);
  yield takeLatest(Types.UPDATE_ADVERTISING_PROVIDER_REQUEST, updateAdvertisingProviderRequest);
  yield takeLatest(Types.DELETE_ADVERTISING_PROVIDER_REQUEST, deleteAdvertisingProviderRequest);
}
