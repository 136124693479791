export default {
  '*': {
    fontFamily: 'Helvetica Neue'
  },
  h1: {
    fontSize: 36
  },
  h2: {
    fontSize: 24
  },
  h3: {
    fontSize: 18
  },
  h4: {
    fontSize: 16
  },
  h5: {
    fontSize: 14
  },
  h6: {
    fontSize: 13
  },
  body1: {
    fontSize: 12
  }
};
