import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

export const searchUser = async ({ key = '' }) => {
  const response = await adminAPI.get(ENDPOINT.USERS, {
    params: { key },
    headers: authHeader()
  });

  return response;
};

export const changePlanForUser = async ({ userId, stripeSubscriptionId, newStripePriceId }) => {
  const response = await adminAPI.post(`${ENDPOINT.USERS}/${userId}/change_plan`, {
    stripeSubscriptionId, newStripePriceId,
  }, {
    headers: authHeader()
  });

  return response;
};

export const getUser = async ({ id }) => {
  const response = await adminAPI.get(`${ENDPOINT.USERS}/${id}`, {
    headers: authHeader()
  });

  return response;
};

export const getCreditsForUser = async ({ id }) => {
  const response = await adminAPI.get(`${ENDPOINT.CREDITS}/${id}`, {
    headers: authHeader()
  });

  return response;
};

export const getCreditBalanceAdjustments = async ({ userId }) => {
  const response = await adminAPI.get(`${ENDPOINT.USERS}/${userId}${ENDPOINT.CREDIT_BALANCE_ADJUSTMENTS}`, {
    headers: authHeader()
  });

  return response;
};

// Action: INCREMENT, DECREMENT, EXPIRE
// Reason: REDEEMED_RESERVATION, SUBSCRIPTION_REPLENISH, PURCHASE, EXPIRATION, ADMIN
export const updateAdjustCredits = async ({ userId, action, credits, comment }) => {
  const response = await adminAPI.put(`${ENDPOINT.USERS}/${userId}${ENDPOINT.ADJUST_CREDITS}`, {
    action, credits, comment
  }, {
    headers: authHeader()
  });

  return response;
};

export const getCheckIns = async ({ userID }) => {
  const response = await adminAPI.get(`users/${userID}/check_ins`, {
    headers: authHeader()
  });

  return response;
};

export const getReservations = async ({ userID } = {}) => {
  const response = await adminAPI.get(`users/${userID}/reservations`, {
    headers: authHeader(),
    timeout: 80000
  });

  return response;
};

export const getSubscriptions = async ({ userID } = {}) => {
  const response = await adminAPI.get(`users/${userID}/subscriptions`, {
    headers: authHeader()
  });

  return response;
};

export const getCardDetails = async ({ userID }) => {
  const response = await adminAPI.get(`users/${userID}/card`, {
    headers: authHeader()
  });

  return response;
};

export const getCardsForUser = async ({ userID }) => {
  const response = await adminAPI.get(`users/${userID}/cards`, {
    headers: authHeader()
  });

  return response;
};

export const getActivePlanForUser = async ({ userID }) => {
  const response = await adminAPI.get(`users/${userID}/plan`, {
    headers: authHeader()
  });

  return response;
};

export const getPlansForUser = async ({ userID }) => {
  const response = await adminAPI.get(`users/${userID}/plans`, {
    headers: authHeader()
  });

  return response;
};

export const updateProfileUser = async ({
  userID,
  userName,
  firstName,
  lastName,
  email,
  dateOfBirth,
  gender,
  statusValue
}) => {
  const response = await adminAPI.put(
    `${ENDPOINT.USERS}/${userID}`,
    {
      userName,
      firstName,
      lastName,
      email,
      dateOfBirth,
      gender,
      status: statusValue,
    },
    { headers: authHeader() }
  );

  return response;
};

export const changePassword = async ({ userId, password }) => {
  const response = await adminAPI.post(
    `${ENDPOINT.USERS}/${userId}${ENDPOINT.CHANGE_PASSWORD}`,
    { password },
    { headers: authHeader() }
  );

  return response;
};

export const resetPassword = async ({ email }) => {
  const response = await adminAPI.post(
    ENDPOINT.RESET_PASSWORD,
    { email },
    { headers: authHeader() }
  );

  return response;
};

export const cancelUser = async ({ userId }) => {
  const response = await adminAPI.post(
    `${ENDPOINT.SUBSCRIPTIONS}${ENDPOINT.CANCEL}`,
    { userId },
    { headers: authHeader() }
  );

  return response;
};

export const backdateCancelUser = async ({ userId, numberOfMonths }) => {
  const response = await adminAPI.post(
    `${ENDPOINT.SUBSCRIPTIONS}${ENDPOINT.BACKDATE}`,
    {
      userId,
      numberOfMonths,
    },
    { headers: authHeader() }
  );

  return response;
};

export const removeCancellation = async ({ userId }) => {
  const response = await adminAPI.post(
    `${ENDPOINT.SUBSCRIPTIONS}${ENDPOINT.CANCEL}`,
    { userId },
    { headers: authHeader() }
  );

  return response;
};

export const getActiveUserAddress = async ({ userID }) => {
  const response = await adminAPI.get(`${ENDPOINT.USERS}/${userID}/address`, {
    headers: authHeader()
  });

  return response;
};

export const updateActiveUserAddress = async ({
  userID,
  street,
  street2 = '',
  city,
  state,
  postalCode
}) => {
  const response = await adminAPI.post(`${ENDPOINT.USERS}/${userID}/address`,
    {
      street,
      street2,
      city,
      state,
      postalCode,
      kind: 'ShippingAddress'
    },
    { headers: authHeader() }
  );

  return response;
};

export const createCardForUser = async ({
  userID,
  isVirtual
}) => {
  const response = await adminAPI.post(`users/${userID}/card?isVirtual=${isVirtual}`,
    { userID },
    { headers: authHeader() });

  return response;
};

export const activateUser = async ({ userID }) => {
  const response = await adminAPI.post(`users/${userID}/activate`,
    { userID },
    { headers: authHeader() });

  return response;
};

export const getSubscriptionsForUser = async ({ userID }) => {
  const response = await adminAPI.get(`users/${userID}/subscriptions`,
    { userID },
    { headers: authHeader() });

  return response;
};

export const retryPaymentForUser = async ({ userID }) => {
  const response = await adminAPI.post(`users/${userID}/retry_payment`,
    { userID },
    { headers: authHeader() });

  return response;
};

export const getFriendInvitations = async ({ userID }) => {
  const response = await adminAPI.get(`users/${userID}/invitations`, {
    headers: authHeader()
  });

  return response;
};

export const getFriendConversions = async ({ userID }) => {
  const response = await adminAPI.get(`users/${userID}/conversions`, {
    headers: authHeader()
  });

  return response;
};

export const startSubscriptionEarlyForFreeTrialUser = async ({
  subscriptionId,
  userId,
}) => {
  const response = await adminAPI.post('subscriptions/freetrial/deactivate',
    {
      subscriptionId,
      userId
    },
    { headers: authHeader() });

  return response;
};

export const getUserBenefitHistory = async ({
  userID,
}) => {
  const response = await adminAPI.get(`userbenefits/${userID}`,
    { headers: authHeader() });

  return response;
};
