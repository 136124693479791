// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from '../actions/pricesAction';

export const INITIAL_STATE = Immutable({
  error: null,
  isLoading: false,
  pricesData: {
    total: 0,
    prices: []
  },
  priceDetail: {},
  updatePriceError: null
});

const getListPricesRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListPricesSuccess = (state, action) => {
  const {
    data: { total, prices }
  } = action;

  return merge(state, {
    isLoading: false,
    pricesData: {
      total,
      prices
    }
  });
};

const getListPricesFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    pricesData: {
      total: 0,
      prices: []
    },
    priceDetail: {}
  });
};

const getPriceDetailsRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getPriceDetailsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    priceDetail: data
  });
};

const getPriceDetailsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    priceDetail: {}
  });
};

/**
 * Handle request update theater
 * @param {*} state
 * @param {*} action
 * @returns
 */
const updatePriceRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const updatePriceSuccess = (state, action) => {
  const { data } = action;
  const newPrices = [...state.pricesData.prices];

  return merge(state, {
    // TODO: mocking users list after update. Call getListTheaters when submit update
    pricesData: {
      ...state.pricesData,
      prices: newPrices.map((price) =>
        price.id === data.priceId ? { id: price.id, ...data } : price
      )
    },
    isLoading: false,
    updatePriceError: null
  });
};

const updatePriceFailed = (state) => {
  return merge(state, {
    updatePriceError: 'Error',
    isLoading: false
  });
};

export const HANDLERS = {
  [Types.GET_LIST_PRICES_REQUEST]: getListPricesRequest,
  [Types.GET_LIST_PRICES_SUCCESS]: getListPricesSuccess,
  [Types.GET_LIST_PRICES_FAILED]: getListPricesFailed,

  [Types.GET_PRICE_DETAILS_REQUEST]: getPriceDetailsRequest,
  [Types.GET_PRICE_DETAILS_SUCCESS]: getPriceDetailsSuccess,
  [Types.GET_PRICE_DETAILS_FAILED]: getPriceDetailsFailed,

  [Types.UPDATE_PRICE_REQUEST]: updatePriceRequest,
  [Types.UPDATE_PRICE_SUCCESS]: updatePriceSuccess,
  [Types.UPDATE_PRICE_FAILED]: updatePriceFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
