export const MENU = [
  { id: 'offer', name: 'Offer' },
  { id: 'preshow', name: 'Preshow' },
  { id: 'spot', name: 'Spot' },
  { id: 'survey', name: 'Survey' }
];

export const NESTED_MENU = [
  { id: 'user', name: 'User' },
  { id: 'brand', name: 'Brand' },
  { id: 'item', name: 'Item' }
];
