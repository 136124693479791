import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';
import cleanObject from '../helper/cleanObject';

/**
 * Handle request server to get list of offer rewards
 * @param {*} param
 * @returns response
 */
const getListRewards = async ({
  key = '',
  status = '',
  offset = 0,
  limit = 10
}) => {
  const params = { key, offset, limit, status };
  const newParams = cleanObject(params, 'status');
  const response = await adminAPI.get(ENDPOINT.OFFER_REWARDS, {
    params: newParams,
    headers: authHeader()
  });

  return response;
};

export default getListRewards;
