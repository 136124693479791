import { put, call, takeLatest } from 'redux-saga/effects';

// reducers
import { Types, Creators } from 'src/redux/actions/brandsAction';

// apis
import {
  getListBrands,
  createBrand,
  updateBrand,
} from 'src/api-client/brandsApi';

// get data authentication for request API

function* getListBrandsRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getListBrands, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getListBrandsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListBrandsFailed(error));
  }
}

/**
 * Handle request creat new user
 * @param {*} action
 */
function* createBrandRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(createBrand, params);
    yield put(Creators.createBrandSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.createBrandFailed(error));

    if (callback) {
      callback(null, error);
    }
  }
}

/**
 * Handle update profile for user
 */
function* updateBrandRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateBrand, params);
    yield put(Creators.updateBrandSuccess(params));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateBrandFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

export default function* watchBrandsRequest() {
  yield takeLatest(Types.GET_LIST_BRANDS_REQUEST, getListBrandsRequest);
  yield takeLatest(Types.CREATE_BRAND_REQUEST, createBrandRequest);
  yield takeLatest(Types.UPDATE_BRAND_REQUEST, updateBrandRequest);
}
