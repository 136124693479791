// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from '../actions/itemsAction';

export const INITIAL_STATE = Immutable({
  error: null,
  isLoading: false,
  itemsData: {
    total: 0,
    items: []
  },
  offersForItem: {},
  itemDetail: {}
});

const getListItemsRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListItemsSuccess = (state, action) => {
  const {
    data: { total, items }
  } = action;

  return merge(state, {
    isLoading: false,
    itemsData: {
      total,
      items
    }
  });
};

const getListItemsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    itemsData: {
      total: 0,
      items: []
    }
  });
};

const getOffersForItemRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getOffersForItemSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    offersForItem: data
  });
};

const getOffersForItemFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    offersForItem: {}
  });
};

const getItemDetailsRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getItemDetailsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    itemDetail: data
  });
};

const getItemDetailsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    itemDetail: {}
  });
};

export const HANDLERS = {
  [Types.GET_LIST_ITEMS_REQUEST]: getListItemsRequest,
  [Types.GET_LIST_ITEMS_SUCCESS]: getListItemsSuccess,
  [Types.GET_LIST_ITEMS_FAILED]: getListItemsFailed,

  [Types.GET_OFFERS_FOR_ITEM_REQUEST]: getOffersForItemRequest,
  [Types.GET_OFFERS_FOR_ITEM_SUCCESS]: getOffersForItemSuccess,
  [Types.GET_OFFERS_FOR_ITEM_FAILED]: getOffersForItemFailed,

  [Types.GET_ITEM_DETAILS_REQUEST]: getItemDetailsRequest,
  [Types.GET_ITEM_DETAILS_SUCCESS]: getItemDetailsSuccess,
  [Types.GET_ITEM_DETAILS_FAILED]: getItemDetailsFailed
};

export default createReducer(INITIAL_STATE, HANDLERS);
