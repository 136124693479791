// libs
import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  // login
  loginRequest: ['params', 'callback'],
  loginSuccess: ['data', 'email'],
  loginFailed: ['error'],

  // request password reset
  requestPasswordResetRequest: ['params', 'callback'],
  requestPasswordResetSuccess: ['data'],
  requestPasswordResetFailed: ['error'],

  // submit password reset
  submitPasswordResetRequest: ['params', 'callback'],
  submitPasswordResetSuccess: ['data'],
  submitPasswordResetFailed: ['error'],

  // logout
  logoutRequest: []
});
