import ENDPOINT from './ApiEndpoint';
import { baseAdminAPI, authHeader } from './apiBaseConfig';

export const getCorporatePartners = async () => {
  const response = await baseAdminAPI.get(`${ENDPOINT.V1}${ENDPOINT.CORPORATE_PARTNER}${ENDPOINT.ALL}`, {
    headers: authHeader()
  });

  return response;
};

export const getCorporatePartner = async ({
  id
}) => {
  const response = await baseAdminAPI.get(`${ENDPOINT.V1}${ENDPOINT.CORPORATE_PARTNER}/${id}`, {
    headers: authHeader()
  });

  return response;
};

export const createCorporatePartner = async ({
  id,
  corporateName,
  status,
  couponId,
  startDate,
  endDate,
}) => {
  const response = await baseAdminAPI.post(`${ENDPOINT.V1}${ENDPOINT.CORPORATE_PARTNER}${ENDPOINT.CREATE}`, {
    id,
    corporateName,
    status,
    couponId,
    startDate,
    endDate,
  }, {
    headers: authHeader()
  });

  return response;
};

export const updateCorporatePartner = async ({
  id,
  corporateName,
  status,
  couponId,
  startDate,
  endDate,
}) => {
  const response = await baseAdminAPI.put(`${ENDPOINT.V1}${ENDPOINT.CORPORATE_PARTNER}/${id}`, {
    corporateName,
    status,
    couponId,
    startDate,
    endDate,
  }, {
    headers: authHeader()
  });

  return response;
};

export const getCorporatePartnerUsers = async ({
  id,
}) => {
  const response = await baseAdminAPI.get(`${ENDPOINT.V1}${ENDPOINT.CORPORATE_PARTNER}/${id}${ENDPOINT.USERS}`, {
    headers: authHeader()
  });

  return response;
};

export const getCorporateCoupons = async ({
  isAvailable,
}) => {
  const response = await baseAdminAPI.get(`${ENDPOINT.V1}${ENDPOINT.CORPORATE_COUPONS}?isAvailable=${isAvailable}`, {
    headers: authHeader()
  });

  return response;
};

export const uploadCSV = async ({
  id,
  csv
}) => {
  const response = await baseAdminAPI.post(`${ENDPOINT.V1}${ENDPOINT.CORPORATE_PARTNER}${ENDPOINT.CSV_UPLOAD}/${id}`, csv, {
    headers: {
      ...authHeader(),
      'Content-Type': 'multipart/form-data'
    }
  });

  return response;
};

export const getCSVUploads = async ({
  id,
}) => {
  const response = await baseAdminAPI.get(`${ENDPOINT.V1}${ENDPOINT.CORPORATE_PARTNER}/${id}${ENDPOINT.CSV_UPLOADS}`, {
    headers: authHeader(),
  });

  return response;
};

export const getCSVJobUploadStatus = async ({
  id,
  jobId,
}) => {
  const response = await baseAdminAPI.get(`${ENDPOINT.V1}${ENDPOINT.CORPORATE_PARTNER}/${id}${ENDPOINT.CSV_UPLOADS}/${jobId}`, {
    headers: authHeader(),
  });

  return response;
};
