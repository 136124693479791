import { put, call, takeLatest } from 'redux-saga/effects';

// reducers
import { Types, Creators } from 'src/redux/actions/rewardsAction';

// apis
import getListRewards from 'src/api-client/rewardsApi';

function* getListRewardsRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getListRewards, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getListRewardsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListRewardsFailed(error || e));
  }
}
export default function* watchRewardsRequest() {
  yield takeLatest(Types.GET_LIST_REWARDS_REQUEST, getListRewardsRequest);
}
