// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from '../actions/pollsAction';

export const INITIAL_STATE = Immutable({
  error: null,
  isLoading: false,
  pollDetails: []
});

const getPollRequest = (state) => {
  return merge(state, {
    isLoading: true,
    pollDetails: []
  });
};

const getPollSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    pollDetails: data
  });
};

const getPollFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    pollDetails: []
  });
};

const updatePollRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const updatePollSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    pollDetails: data
  });
};

const updatePollFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error
  });
};

export const HANDLERS = {
  [Types.GET_POLL_REQUEST]: getPollRequest,
  [Types.GET_POLL_SUCCESS]: getPollSuccess,
  [Types.GET_POLL_FAILED]: getPollFailed,
  [Types.UPDATE_POLL_REQUEST]: updatePollRequest,
  [Types.UPDATE_POLL_SUCCESS]: updatePollSuccess,
  [Types.UPDATE_POLL_FAILED]: updatePollFailed
};

export default createReducer(INITIAL_STATE, HANDLERS);
