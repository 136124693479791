// libs
import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getListTheatersRequest: ['params', 'callback'],
  getListTheatersSuccess: ['data'],
  getListTheatersFailed: ['error'],

  getTheaterDetailsRequest: ['params'],
  getTheaterDetailsSuccess: ['data'],
  getTheaterDetailsFailed: ['error'],

  updateTheaterRequest: ['params', 'callback'],
  updateTheaterSuccess: ['data'],
  updateTheaterFailed: ['error'],

  updateTheatersListRequest: ['params', 'callback'],
  updateTheatersListSuccess: ['data'],
  updateTheatersListFailed: ['error'],

  getServiceFeeForTheaterRequest: ['params', 'callback'],
  getServiceFeeForTheaterSuccess: ['data'],
  getServiceFeeForTheaterFailed: ['error'],

  createTheaterRequest: ['params', 'callback'],
  createTheaterSuccess: ['data'],
  createTheaterFailed: ['error']
});
