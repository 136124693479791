import { put, call, takeLatest } from 'redux-saga/effects';

import { Types, Creators } from 'src/redux/actions/giftsAction';

import {
  getUserGiftPurchases,
  sendGiftEmail,
  updateGiftEmail
} from 'src/api-client/giftsApi';

function* getUserGiftPurchasesRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getUserGiftPurchases, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getUserGiftPurchasesSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getUserGiftPurchasesFailed(error || e));
  }
}

function* sendGiftEmailRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(sendGiftEmail, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.sendGiftEmailSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.sendGiftEmailFailed(error || e));
  }
}

function* updateGiftEmailRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateGiftEmail, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.updateGiftEmailSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.updateGiftEmailFailed(error || e));
  }
}

export default function* watchGiftsRequest() {
  yield takeLatest(Types.GET_USER_GIFT_PURCHASES_REQUEST, getUserGiftPurchasesRequest);
  yield takeLatest(Types.SEND_GIFT_EMAIL_REQUEST, sendGiftEmailRequest);
  yield takeLatest(Types.UPDATE_GIFT_EMAIL_REQUEST, updateGiftEmailRequest);
}
