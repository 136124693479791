import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

export const insertBlacklistMCC = async ({ mcc, comment }) => {
  const response = await adminAPI.post(`${ENDPOINT.BLACKLISTS}${ENDPOINT.MCC}`, {
    mcc, comment
  }, {
    headers: authHeader()
  });

  return response;
};

export const getListBlacklistMCCs = async () => {
  const response = await adminAPI.get(`${ENDPOINT.BLACKLISTS}${ENDPOINT.MCC}`, {
    headers: authHeader()
  });

  return response;
};

export const getBlacklistMCCDetails = async ({ id }) => {
  const response = await adminAPI.get(`${ENDPOINT.BLACKLISTS}${ENDPOINT.MCC}/${id}`, {
    headers: authHeader()
  });

  return response;
};

export const updateBlacklistMCC = async ({
  id,
  comment,
  statusValue
}) => {
  const response = await adminAPI.put(
    `${ENDPOINT.BLACKLISTS}${ENDPOINT.MCC}/${id}`,
    {
      comment,
      status: statusValue,
    },
    { headers: authHeader() }
  );

  return response;
};

export const insertBlacklistMerchant = async ({ merchantId, merchant, comment }) => {
  const response = await adminAPI.post(`${ENDPOINT.BLACKLISTS}${ENDPOINT.MERCHANT}`, {
    merchantId, merchant, comment
  }, {
    headers: authHeader()
  });

  return response;
};

export const getListBlacklistMerchants = async () => {
  const response = await adminAPI.get(`${ENDPOINT.BLACKLISTS}${ENDPOINT.MERCHANT}`, {
    headers: authHeader()
  });

  return response;
};

export const getBlacklistMerchantDetails = async ({ id }) => {
  const response = await adminAPI.get(`${ENDPOINT.BLACKLISTS}${ENDPOINT.MERCHANT}/${id}`, {
    headers: authHeader()
  });

  return response;
};

export const updateBlacklistMerchant = async ({
  id,
  comment,
  statusValue
}) => {
  const response = await adminAPI.put(
    `${ENDPOINT.BLACKLISTS}${ENDPOINT.MERCHANT}/${id}`,
    {
      comment,
      status: statusValue,
    },
    { headers: authHeader() }
  );

  return response;
};
