import { put, call, takeLatest } from 'redux-saga/effects';

// reducers
import { Types, Creators } from 'src/redux/actions/surveysAction';

// apis
import {
  getListSurveys,
  getSurveyDetails,
  updateNameSurvey
} from 'src/api-client/surveysApi';

/**
 * Handle get list of surveys
 * @param {*} action
 */
function* getListSurveysRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getListSurveys, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getListSurveysSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListSurveysFailed(error || e));
  }
}

/**
 * Handle get survey details
 * @param {*} action
 */
function* getSurveyDetailsRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getSurveyDetails, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getSurveyDetailsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getSurveyDetailsFailed(error || e));
  }
}

/**
 * Update name of survey
 * @param {*} action
 */
function* updateNameSurveyRequest(action) {
  const { params } = action;

  try {
    const response = yield call(updateNameSurvey, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.updateNameSurveySuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateNameSurveyFailed(error || e));
  }
}

export default function* watchSurveysRequest() {
  yield takeLatest(Types.GET_LIST_SURVEYS_REQUEST, getListSurveysRequest);
  yield takeLatest(Types.GET_SURVEY_DETAILS_REQUEST, getSurveyDetailsRequest);
  yield takeLatest(Types.UPDATE_NAME_SURVEY_REQUEST, updateNameSurveyRequest);
}
