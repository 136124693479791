import { put, call, takeLatest } from 'redux-saga/effects';

// reducers
import { Types, Creators } from 'src/redux/actions/preshowsAction';

// apis
import {
  getListPreshows,
  getPreshowWithSpots
} from 'src/api-client/preshowsApi';

function* getListPreshowsRequest(action) {
  const { params } = action;
  try {
    const response = yield call(getListPreshows, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getListPreshowsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListPreshowsFailed(error));
  }
}

/**
 * Get preshow details with list of spots request
 * @param {*} action
 */
function* getPreshowWithSpotsRequest(action) {
  const { params } = action;
  try {
    const response = yield call(getPreshowWithSpots, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getPreshowWithSpotsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getPreshowWithSpotsFailed(error || e));
  }
}

export default function* watchPreshowsRequest() {
  yield takeLatest(Types.GET_LIST_PRESHOWS_REQUEST, getListPreshowsRequest);
  yield takeLatest(
    Types.GET_PRESHOW_WITH_SPOTS_REQUEST,
    getPreshowWithSpotsRequest
  );
}
