import React from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiListItem from '@material-ui/core/ListItem';
import Icon from 'src/components/icon/Icon';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.active.main,
    fontWeight: 'bold',
    fontSize: 16,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: theme.spacing(1.8, 1.6),
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    padding: theme.spacing(1.2, 0),
    background: theme.palette.active.background,
    '& .MuiButton-text': {
      color: theme.palette.active.selected,
      padding: theme.spacing(1.8, 1.6)
    }
  }
}));

// handle selected item styles of left nav panel
const ListItem = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0)
  }
}))(MuiListItem);

const NavItem = ({
  className,
  href,
  icon,
  title,
  selected,
  onClickItem,
  ...rest
}) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const isActive = pathname.includes(href);

  return (
    <ListItem
      disableGutters
      to={href}
      component={RouterLink}
      activeClassName={classes.active}
      {...rest}
    >
      <Button className={classes.button}>
        <Icon active={isActive} label={title} url={icon} size="lg" width={27} />
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  selected: PropTypes.bool,
  onClickItem: PropTypes.func
};

NavItem.defaultProps = {
  className: '',
  href: '',
  icon: '',
  title: '',
  selected: false,
  onClickItem: () => {}
};

export default NavItem;
