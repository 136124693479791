import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

/**
 * Handle request server to get list of spots
 * @param {*} param
 * @returns response
 */
export const getListSpots = async ({ key = '', offset = 0, limit = 10 }) => {
  const response = await adminAPI.get(ENDPOINT.SPOTS, {
    params: { key, offset, limit },
    headers: authHeader()
  });

  return response;
};

export const getSpotDetails = async ({ spotId }) => {
  const response = await adminAPI.get(`${ENDPOINT.SPOTS}/${spotId}`, {
    headers: authHeader()
  });

  return response;
};
