// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from 'src/redux/actions/usersAction';

export const INITIAL_STATE = Immutable({
  usersData: {
    total: 0,
    users: []
  },
  isLoading: false,
  isGetReservationsLoading: true,
  isSubscriptionsLoading: true,
  userDetails: {
    info: {},
    credits: 0,
    creditBalanceAdjustments: [],
    reservations: [],
    checkIns: [],
    cardInfo: {},
    cards: [],
    address: {},
    activePlan: {},
    availablePlans: {},
    subscriptions: [],
    friendInvitations: [],
    friendConversions: [],
    userBenefitHistory: []
  },
  offerOptions: [],
  error: null,
  isLoadingIssuePoints: false,
  isLoadingAdjustCredits: false,
});

/**
 * Handle get user request
 * @param {*} state
 * @returns
 */
const searchUserRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const searchUserSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    userDetails: {
      info: data
    },
    isLoading: false,
    error: null
  });
};

const searchUserFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    userDetails: {
      info: {}
    },
    error,
    isLoading: false
  });
};

const changePlanForUserRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null,
  });
};

const changePlanForUserSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    userDetails: {
      ...state.userDetails,
      activePlan: data
    },
    isLoading: false,
    error: null
  });
};

const changePlanForUserFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    userDetails: {
      ...state.userDetails,
      activePlan: {}
    },
    error,
    isLoading: false
  });
};

const getUserRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getUserSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    userDetails: {
      ...state.userDetails,
      info: data
    },
    isLoading: false,
    error: null
  });
};

const getUserFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    userDetails: {
      info: {},
      credits: 0,
      creditBalanceAdjustments: [],
      rewardsData: {},
      checkIns: [],
      cardInfo: {}
    },
    error,
    isLoading: false
  });
};

/**
 * Handle get user request
 * @param {*} state
 * @returns
 */
const getCreditsRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null,
  });
};

const getCreditsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    userDetails: {
      ...state.userDetails,
      credits: data.credits
    },
    isLoading: false,
    error: null
  });
};

const getCreditsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    userDetails: {
      ...state.userDetails,
      credits: 0
    },
    error,
    isLoading: false
  });
};

const getCreditBalanceAdjustmentsRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getCreditBalanceAdjustmentsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    userDetails: {
      ...state.userDetails,
      creditBalanceAdjustments: data
    },
    isLoading: false,
    error: null
  });
};

const getCreditBalanceAdjustmentsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    userDetails: {
      ...state.userDetails,
      creditBalanceAdjustments: []
    },
    error,
    isLoading: false
  });
};

const changeUserPasswordRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const changeUserPasswordSuccess = (state) => {
  return merge(state, {
    isLoading: false,
    error: null
  });
};

const changeUserPasswordFailed = (state, action) => {
  const { error } = action;
  return merge(state, {
    isLoading: false,
    error
  });
};

const getCheckInsRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getCheckInsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    userDetails: {
      ...state.userDetails,
      checkIns: data
    },
    isLoading: false,
    error: null
  });
};

const getCheckInsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    userDetails: {
      ...state.userDetails,
      checkIns: []
    },
    isLoading: false
  });
};

const getReservationsRequest = (state) => {
  return merge(state, {
    isGetReservationsLoading: true
  });
};

const getReservationsSuccess = (state, action) => {
  const { data } = action;

  const sortedReservations = data.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);

    return dateB - dateA;
  });

  return merge(state, {
    userDetails: {
      ...state.userDetails,
      reservations: sortedReservations
    },
    isGetReservationsLoading: false,
    error: null
  });
};

const getReservationsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    userDetails: {
      ...state.userDetails,
      reservations: []
    },
    isGetReservationsLoading: false
  });
};

const getSubscriptionsRequest = (state) => {
  return merge(state, {
    isSubscriptionsLoading: true
  });
};

const getSubscriptionsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    userDetails: {
      ...state.userDetails,
      subscriptions: data
    },
    isSubscriptionsLoading: false,
    error: null
  });
};

const getSubscriptionsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    userDetails: {
      ...state.userDetails,
      subscriptions: []
    },
    isSubscriptionsLoading: false
  });
};

const getCardDetailsRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getCardDetailsSuccess = (state, action) => {
  return merge(state, {
    isLoading: false,
    userDetails: {
      ...state.userDetails,
      cardInfo: action.data
    },
    error: null
  });
};

const getCardDetailsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    userDetails: {
      ...state.userDetails,
      cardInfo: {}
    },
    isLoading: false
  });
};

const getCardsForUserRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getCardsForUserSuccess = (state, action) => {
  return merge(state, {
    isLoading: false,
    userDetails: {
      ...state.userDetails,
      cards: action.data
    },
    error: {}
  });
};

const getCardsForUserFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    userDetails: {
      ...state.userDetails,
      cards: []
    },
    isLoading: false
  });
};

const getActivePlanForUserRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getActivePlanForUserSuccess = (state, action) => {
  return merge(state, {
    isLoading: false,
    userDetails: {
      ...state.userDetails,
      activePlan: action.data
    },
    error: null
  });
};

const getActivePlanForUserFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    userDetails: {
      ...state.userDetails,
      activePlan: {}
    },
    isLoading: false
  });
};

const getPlansForUserRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getPlansForUserSuccess = (state, action) => {
  return merge(state, {
    isLoading: false,
    userDetails: {
      ...state.userDetails,
      availablePlans: action.data
    },
    error: null
  });
};

const getPlansForUserFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    userDetails: {
      ...state.userDetails,
      availablePlans: {}
    },
    isLoading: false
  });
};

const updateProfileRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const updateProfileSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    userDetails: {
      ...state.userDetails,
      info: data
    },
    isLoading: false,
    error: null
  });
};

const updateProfileFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    isLoading: false
  });
};

const updateAdjustCreditsRequest = (state) => {
  return merge(state, {
    isLoadingAdjustCredits: true,
    error: null
  });
};

const updateAdjustCreditsSuccess = (state, action) => {
  const { data } = action.data;

  return merge(state, {
    userDetails: {
      ...state.userDetails,
      credits: data.credits
    },
    isLoadingAdjustCredits: false,
    error: null
  });
};

const updateAdjustCreditsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoadingAdjustCredits: false,
    error
  });
};

const resetPasswordRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const resetPasswordSuccess = (state) => {
  return merge(state, {
    isLoading: false
  });
};

const resetPasswordFailed = (state) => {
  return merge(state, {
    updateError: null,
    error: null,
    isLoading: false
  });
};

const cancelUserRequest = (state) => {
  return merge(state, {
    error: null,
    isLoading: true
  });
};

const cancelUserSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    userDetails: {
      info: data
    },
    isLoading: false,
    error: null,
  });
};

const cancelUserFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    isLoading: false
  });
};

const backdateCancelUserRequest = (state) => {
  return merge(state, {
    error: null,
    isLoading: true
  });
};

const backdateCancelUserSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    userDetails: {
      info: data
    },
    isLoading: false
  });
};

const backdateCancelUserFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    isLoading: false
  });
};

const removeCancellationRequest = (state) => {
  return merge(state, {
    error: null,
    isLoading: true
  });
};

const removeCancellationSuccess = (state) => {
  return merge(state, {
    isLoading: false
  });
};

const removeCancellationFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    isLoading: false
  });
};

const getActiveAddressDetailsRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getActiveAddressDetailsSuccess = (state, action) => {
  return merge(state, {
    isLoading: false,
    userDetails: {
      ...state.userDetails,
      address: action.data
    },
    error: null,
  });
};

const getActiveAddressDetailsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    userDetails: {
      ...state.userDetails,
      address: {}
    },
    isLoading: false
  });
};

const updateActiveUserAddressRequest = (state) => {
  return merge(state, {
    error: null,
    isLoading: true
  });
};

const updateActiveUserAddressSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    userDetails: {
      ...state.userDetails,
      address: data
    },
    isLoading: false,
    error: null,
  });
};

const updateActiveUserAddressFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    isLoading: false
  });
};

const createCardForUserRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const createCardForUserSuccess = (state) => {
  return merge(state, {
    isLoading: false,
    error: null,
  });
};

const createCardForUserFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    isLoading: false
  });
};

const activateUserRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const activateUserSuccess = (state) => {
  return merge(state, {
    isLoading: false,
    error: null,
  });
};

const activateUserFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    isLoading: false
  });
};

const retryPaymentForUserRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const retryPaymentForUserSuccess = (state) => {
  return merge(state, {
    isLoading: false,
    error: null,
  });
};

const retryPaymentForUserFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    isLoading: false
  });
};

const getFriendInvitationsRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getFriendInvitationsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    userDetails: {
      ...state.userDetails,
      friendInvitations: data
    },
    isLoading: false,
    error: null
  });
};

const getFriendInvitationsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    userDetails: {
      ...state.userDetails,
      friendInvitations: []
    },
    error,
    isLoading: false
  });
};

const getFriendConversionsRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getFriendConversionsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    userDetails: {
      ...state.userDetails,
      friendConversions: data
    },
    isLoading: false,
    error: null
  });
};

const getFriendConversionsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    userDetails: {
      ...state.userDetails,
      friendConversions: []
    },
    error,
    isLoading: false
  });
};

const startSubscriptionEarlyForFreeTrialUserRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const startSubscriptionEarlyForFreeTrialUserSuccess = (state) => {
  return merge(state, {
    isLoading: false,
    error: null
  });
};

const startSubscriptionEarlyForFreeTrialUserFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    isLoading: false
  });
};

const getUserBenefitHistoryRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getUserBenefitHistorySuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    error: null,
    userDetails: {
      ...state.userDetails,
      userBenefitHistory: data
    },
  });
};

const getUserBenefitHistoryFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    error,
    isLoading: false
  });
};

export const HANDLERS = {
  [Types.SEARCH_USER_REQUEST]: searchUserRequest,
  [Types.SEARCH_USER_SUCCESS]: searchUserSuccess,
  [Types.SEARCH_USER_FAILED]: searchUserFailed,

  [Types.CHANGE_PLAN_FOR_USER_REQUEST]: changePlanForUserRequest,
  [Types.CHANGE_PLAN_FOR_USER_SUCCESS]: changePlanForUserSuccess,
  [Types.CHANGE_PLAN_FOR_USER_FAILED]: changePlanForUserFailed,

  [Types.SEARCH_USER_REQUEST]: searchUserRequest,
  [Types.SEARCH_USER_SUCCESS]: searchUserSuccess,
  [Types.SEARCH_USER_FAILED]: searchUserFailed,

  [Types.GET_USER_REQUEST]: getUserRequest,
  [Types.GET_USER_SUCCESS]: getUserSuccess,
  [Types.GET_USER_FAILED]: getUserFailed,

  [Types.GET_CREDITS_REQUEST]: getCreditsRequest,
  [Types.GET_CREDITS_SUCCESS]: getCreditsSuccess,
  [Types.GET_CREDITS_FAILED]: getCreditsFailed,

  [Types.GET_CREDIT_BALANCE_ADJUSTMENTS_REQUEST]: getCreditBalanceAdjustmentsRequest,
  [Types.GET_CREDIT_BALANCE_ADJUSTMENTS_SUCCESS]: getCreditBalanceAdjustmentsSuccess,
  [Types.GET_CREDIT_BALANCE_ADJUSTMENTS_FAILED]: getCreditBalanceAdjustmentsFailed,

  [Types.GET_CHECK_INS_REQUEST]: getCheckInsRequest,
  [Types.GET_CHECK_INS_SUCCESS]: getCheckInsSuccess,
  [Types.GET_CHECK_INS_FAILED]: getCheckInsFailed,

  [Types.CHANGE_USER_PASSWORD_REQUEST]: changeUserPasswordRequest,
  [Types.CHANGE_USER_PASSWORD_SUCCESS]: changeUserPasswordSuccess,
  [Types.CHANGE_USER_PASSWORD_FAILED]: changeUserPasswordFailed,

  [Types.GET_RESERVATIONS_REQUEST]: getReservationsRequest,
  [Types.GET_RESERVATIONS_SUCCESS]: getReservationsSuccess,
  [Types.GET_RESERVATIONS_FAILED]: getReservationsFailed,

  [Types.GET_SUBSCRIPTIONS_REQUEST]: getSubscriptionsRequest,
  [Types.GET_SUBSCRIPTIONS_SUCCESS]: getSubscriptionsSuccess,
  [Types.GET_SUBSCRIPTIONS_FAILED]: getSubscriptionsFailed,

  [Types.GET_CARD_DETAILS_REQUEST]: getCardDetailsRequest,
  [Types.GET_CARD_DETAILS_SUCCESS]: getCardDetailsSuccess,
  [Types.GET_CARD_DETAILS_FAILED]: getCardDetailsFailed,

  [Types.GET_CARDS_FOR_USER_REQUEST]: getCardsForUserRequest,
  [Types.GET_CARDS_FOR_USER_SUCCESS]: getCardsForUserSuccess,
  [Types.GET_CARDS_FOR_USER_FAILED]: getCardsForUserFailed,

  [Types.GET_ACTIVE_PLAN_FOR_USER_REQUEST]: getActivePlanForUserRequest,
  [Types.GET_ACTIVE_PLAN_FOR_USER_SUCCESS]: getActivePlanForUserSuccess,
  [Types.GET_ACTIVE_PLAN_FOR_USER_FAILED]: getActivePlanForUserFailed,

  [Types.GET_PLANS_FOR_USER_REQUEST]: getPlansForUserRequest,
  [Types.GET_PLANS_FOR_USER_SUCCESS]: getPlansForUserSuccess,
  [Types.GET_PLANS_FOR_USER_FAILED]: getPlansForUserFailed,

  [Types.UPDATE_PROFILE_REQUEST]: updateProfileRequest,
  [Types.UPDATE_PROFILE_SUCCESS]: updateProfileSuccess,
  [Types.UPDATE_PROFILE_FAILED]: updateProfileFailed,

  [Types.UPDATE_ADJUST_CREDITS_REQUEST]: updateAdjustCreditsRequest,
  [Types.UPDATE_ADJUST_CREDITS_SUCCESS]: updateAdjustCreditsSuccess,
  [Types.UPDATE_ADJUST_CREDITS_FAILED]: updateAdjustCreditsFailed,

  [Types.RESET_PASSWORD_REQUEST]: resetPasswordRequest,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.RESET_PASSWORD_FAILED]: resetPasswordFailed,

  [Types.CANCEL_USER_REQUEST]: cancelUserRequest,
  [Types.CANCEL_USER_SUCCESS]: cancelUserSuccess,
  [Types.CANCEL_USER_FAILED]: cancelUserFailed,

  [Types.BACKDATE_CANCEL_USER_REQUEST]: backdateCancelUserRequest,
  [Types.BACKDATE_CANCEL_USER_SUCCESS]: backdateCancelUserSuccess,
  [Types.BACKDATE_CANCEL_USER_FAILED]: backdateCancelUserFailed,

  [Types.REMOVE_CANCELLATION_REQUEST]: removeCancellationRequest,
  [Types.REMOVE_CANCELLATION_SUCCESS]: removeCancellationSuccess,
  [Types.REMOVE_CANCELLATION_FAILED]: removeCancellationFailed,

  [Types.GET_ACTIVE_ADDRESS_DETAILS_REQUEST]: getActiveAddressDetailsRequest,
  [Types.GET_ACTIVE_ADDRESS_DETAILS_SUCCESS]: getActiveAddressDetailsSuccess,
  [Types.GET_ACTIVE_ADDRESS_DETAILS_FAILED]: getActiveAddressDetailsFailed,

  [Types.UPDATE_ACTIVE_USER_ADDRESS_REQUEST]: updateActiveUserAddressRequest,
  [Types.UPDATE_ACTIVE_USER_ADDRESS_SUCCESS]: updateActiveUserAddressSuccess,
  [Types.UPDATE_ACTIVE_USER_ADDRESS_FAILED]: updateActiveUserAddressFailed,

  [Types.CREATE_CARD_FOR_USER_REQUEST]: createCardForUserRequest,
  [Types.CREATE_CARD_FOR_USER_SUCCESS]: createCardForUserSuccess,
  [Types.CREATE_CARD_FOR_USER_FAILED]: createCardForUserFailed,

  [Types.ACTIVATE_USER_REQUEST]: activateUserRequest,
  [Types.ACTIVATE_USER_SUCCESS]: activateUserSuccess,
  [Types.ACTIVATE_USER_FAILED]: activateUserFailed,

  [Types.RETRY_PAYMENT_FOR_USER_REQUEST]: retryPaymentForUserRequest,
  [Types.RETRY_PAYMENT_FOR_USER_SUCCESS]: retryPaymentForUserSuccess,
  [Types.RETRY_PAYMENT_FOR_USER_FAILED]: retryPaymentForUserFailed,

  [Types.GET_FRIEND_INVITATIONS_REQUEST]: getFriendInvitationsRequest,
  [Types.GET_FRIEND_INVITATIONS_SUCCESS]: getFriendInvitationsSuccess,
  [Types.GET_FRIEND_INVITATIONS_FAILED]: getFriendInvitationsFailed,

  [Types.GET_FRIEND_CONVERSIONS_REQUEST]: getFriendConversionsRequest,
  [Types.GET_FRIEND_CONVERSIONS_SUCCESS]: getFriendConversionsSuccess,
  [Types.GET_FRIEND_CONVERSIONS_FAILED]: getFriendConversionsFailed,

  [Types.START_SUBSCRIPTION_EARLY_FOR_FREE_TRIAL_USER_REQUEST]:
    startSubscriptionEarlyForFreeTrialUserRequest,
  [Types.START_SUBSCRIPTION_EARLY_FOR_FREE_TRIAL_USER_SUCCESS]:
    startSubscriptionEarlyForFreeTrialUserSuccess,
  [Types.START_SUBSCRIPTION_EARLY_FOR_FREE_TRIAL_USER_FAILED]:
    startSubscriptionEarlyForFreeTrialUserFailed,

  [Types.GET_USER_BENEFIT_HISTORY_REQUEST]: getUserBenefitHistoryRequest,
  [Types.GET_USER_BENEFIT_HISTORY_SUCCESS]: getUserBenefitHistorySuccess,
  [Types.GET_USER_BENEFIT_HISTORY_FAILED]: getUserBenefitHistoryFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
