import ENDPOINT from './ApiEndpoint';
import { exhibServiceAdminAPI, authHeader } from './apiExhibitorAdminConfig';

export const getCreditOffsets = async () => {
  const response = await exhibServiceAdminAPI.get(`v1${ENDPOINT.CREDITS}${ENDPOINT.OFFSETS}`, {
    headers: authHeader()
  });

  return response;
};

export const getCreditOffset = async ({ id }) => {
  const response = await exhibServiceAdminAPI.get(`v1${ENDPOINT.CREDITS}${ENDPOINT.OFFSETS}/${id}`, {
    headers: authHeader()
  });

  return response;
};

export const updateCreditOffset = async ({
  id,
  externalId,
  type,
  startDate,
  endDate,
  offset,
}) => {
  const response = await exhibServiceAdminAPI.put(
    `v1${ENDPOINT.CREDITS}${ENDPOINT.OFFSETS}/${id}`, {
      externalId,
      type,
      startDate,
      endDate,
      offset,
    },
    { headers: authHeader() }
  );

  return response;
};

export const createCreditOffset = async ({
  externalId,
  type,
  startDate,
  endDate,
  offset,
}) => {
  const response = await exhibServiceAdminAPI.post(
    `v1${ENDPOINT.CREDITS}${ENDPOINT.OFFSETS}`, {
      externalId,
      type,
      startDate,
      endDate,
      offset,
    },
    { headers: authHeader() }
  );

  return response;
};
