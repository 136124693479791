// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from '../actions/preshowsAction';

export const INITIAL_STATE = Immutable({
  error: null,
  isLoading: false,
  preshowsData: {
    total: 0,
    preshows: []
  },
  preshowWithSpots: {
    spotCount: 0,
    spots: [],
    totalLength: 0
  }
});

const getListPreshowsRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListPreshowsSuccess = (state, action) => {
  const {
    data: { data, metadata }
  } = action;

  return merge(state, {
    isLoading: false,
    preshowsData: {
      total: metadata.count,
      preshows: data
    }
  });
};

const getListPreshowsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    preshowsData: {
      total: 0,
      preshows: []
    }
  });
};

/**
 * Get preshow details with list of spots
 * @param {*} state
 * @returns state
 */
const getPreshowWithSpotsRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getPreshowWithSpotsSuccess = (state, action) => {
  const {
    /* eslint-disable camelcase */
    data: { spot_count, spots, total_length }
  } = action;

  return merge(state, {
    isLoading: false,
    preshowWithSpots: {
      spots,
      spotCount: spot_count,
      totalLength: total_length
    }
  });
};

const getPreshowWithSpotsFailed = (state, action) => {
  const { error } = action;
  return merge(state, {
    isLoading: false,
    error,
    preshowWithSpots: {
      spots: [],
      spotCount: 0,
      totalLength: 0
    }
  });
};

export const HANDLERS = {
  [Types.GET_LIST_PRESHOWS_REQUEST]: getListPreshowsRequest,
  [Types.GET_LIST_PRESHOWS_SUCCESS]: getListPreshowsSuccess,
  [Types.GET_LIST_PRESHOWS_FAILED]: getListPreshowsFailed,

  [Types.GET_PRESHOW_WITH_SPOTS_REQUEST]: getPreshowWithSpotsRequest,
  [Types.GET_PRESHOW_WITH_SPOTS_SUCCESS]: getPreshowWithSpotsSuccess,
  [Types.GET_PRESHOW_WITH_SPOTS_FAILED]: getPreshowWithSpotsFailed
};

export default createReducer(INITIAL_STATE, HANDLERS);
