// libs
import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

// actions
import { Types } from '../actions/reservationsAction';

export const INITIAL_STATE = Immutable({
  error: null,
  isLoading: false,
  reservationDetail: {},
});

const getReservationDetailsRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const getReservationDetailsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    reservationDetail: data
  });
};

const getReservationDetailsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    reservationDetail: {}
  });
};

const createReservationRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const createReservationSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    createReservationResponse: data
  });
};

const createReservationFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    createReservationResponse: {}
  });
};

const cancelReservationRequest = (state) => {
  return merge(state, {
    isLoading: true
  });
};

const cancelReservationSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    reservationDetail: data
  });
};

const cancelReservationFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error
  });
};

export const HANDLERS = {
  [Types.GET_RESERVATION_DETAILS_REQUEST]: getReservationDetailsRequest,
  [Types.GET_RESERVATION_DETAILS_SUCCESS]: getReservationDetailsSuccess,
  [Types.GET_RESERVATION_DETAILS_FAILED]: getReservationDetailsFailed,
  [Types.CREATE_RESERVATION_REQUEST]: createReservationRequest,
  [Types.CREATE_RESERVATION_SUCCESS]: createReservationSuccess,
  [Types.CREATE_RESERVATION_FAILED]: createReservationFailed,
  [Types.CANCEL_RESERVATION_REQUEST]: cancelReservationRequest,
  [Types.CANCEL_RESERVATION_SUCCESS]: cancelReservationSuccess,
  [Types.CANCEL_RESERVATION_FAILED]: cancelReservationFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
