import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';
import cleanObject from '../helper/cleanObject';

/**
 * Handle request server to get list of offer walls
 * @param {*} param
 * @returns response
 */
export const getListOfferWalls = async ({
  key = '',
  status = '',
  offset = 0,
  limit = 10
}) => {
  const params = { key, offset, limit, status };
  const newParams = cleanObject(params, 'status');
  const response = await adminAPI.get(ENDPOINT.OFFER_WALLS, {
    params: newParams,
    headers: authHeader()
  });

  return response;
};

export const addOfferInSection = async ({
  offerWallName,
  offerWallId,
  sectionName,
  offer
}) => {
  const response = await adminAPI.post(
    ENDPOINT.OFFER_WALL_ELEMENTS,
    {
      offerWallName,
      offerWallId,
      section: sectionName,
      offerId: offer.id,
      status: 'ACTIVE'
    },
    { headers: authHeader() }
  );

  return response;
};

/**
 * Refresh categories
 * @returns response
 */
export const refreshCategories = async () => {
  const response = await adminAPI.get(ENDPOINT.REFRESH_CATEGORIES, {
    headers: authHeader()
  });

  return response;
};

/**
 * Update offer wall info
 * @returns
 */
export const updateOfferWall = async ({ id, status, name }) => {
  const response = await adminAPI.put(
    `${ENDPOINT.OFFER_WALLS}/${id}`,
    {
      status,
      name
    },
    { headers: authHeader() }
  );

  return response;
};

/**
 * Delete Section in Offer Wall
 * @returns
 */
export const deleteSectionOfferWall = async ({ id, nameSection }) => {
  const response = await adminAPI.delete(
    `${ENDPOINT.OFFER_WALL_SECTION}/${id}/${nameSection}`,
    { headers: authHeader() }
  );

  return response;
};

/**
 * Add offer wall section
 * @param {*}
 * @returns response
 */
export const addOfferWallSection = async ({
  offerWallName,
  offerWallId,
  sectionName,
  offerIds
}) => {
  const response = await adminAPI.post(
    ENDPOINT.OFFER_WALL_SECTION,
    {
      offerWallName,
      offerWallId,
      section: sectionName,
      offerIds,
      status: 'INACTIVE'
    },
    { headers: authHeader() }
  );

  return response;
};

/**
 * Update offer wall section
 * @param {*}
 * @returns response
 */
export const updateOfferWallSection = async ({
  offerWallName,
  offerWallId,
  sectionName,
  offerIds,
  section
}) => {
  const response = await adminAPI.put(
    `${ENDPOINT.OFFER_WALL_SECTION}/${offerWallId}/${section}`,
    {
      offerWallName,
      offerWallId,
      section: sectionName,
      offerIds,
      status: 'INACTIVE'
    },
    { headers: authHeader() }
  );

  return response;
};
