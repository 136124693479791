// libs
import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getListPricesRequest: ['params'],
  getListPricesSuccess: ['data'],
  getListPricesFailed: ['error'],

  getPriceDetailsRequest: ['params'],
  getPriceDetailsSuccess: ['data'],
  getPriceDetailsFailed: ['error'],

  updatePriceRequest: ['params', 'callback'],
  updatePriceSuccess: ['data'],
  updatePriceFailed: ['error']
});
