import { put, call, takeLatest } from 'redux-saga/effects';

// reducers
import { Types, Creators } from 'src/redux/actions/networksAction';

// apis
import {
  getListNetworks,
  getNetworkDetails,
  updateNetwork,
} from 'src/api-client/networksApi';

function* getListNetworksRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getListNetworks, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getListNetworksSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListNetworksFailed(error || e));
  }
}

function* getNetworkDetailsRequest(action) {
  const { params } = action;

  try {
    const response = yield call(getNetworkDetails, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.getNetworkDetailsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getNetworkDetailsFailed(error || e));
  }
}

function* updateNetworkRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(updateNetwork, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    yield put(Creators.updateNetworkSuccess(response.data));

    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.updateNetworkFailed(error || e));

    if (callback) {
      callback(null, error);
    }
  }
}

export default function* watchNetworksRequest() {
  yield takeLatest(Types.GET_LIST_NETWORKS_REQUEST, getListNetworksRequest);
  yield takeLatest(Types.GET_NETWORK_DETAILS_REQUEST, getNetworkDetailsRequest);
  yield takeLatest(Types.UPDATE_NETWORK_REQUEST, updateNetworkRequest);
}
