import { put, call, takeLatest } from 'redux-saga/effects';
import { Types, Creators } from 'src/redux/actions/creditBandsAction';
import {
  getCreditBands,
  createCreditBand,
} from 'src/api-client/creditBandsApi';

function* getListCreditBandsRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getCreditBands, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getListCreditBandsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(Creators.getListCreditBandsFailed(error || e));
  }
}

function* createCreditBandRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(createCreditBand, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.createCreditBandSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error);
    }

    yield put(Creators.createCreditBandFailed(error || e));
  }
}

export default function* watchCreditBandsRequest() {
  yield takeLatest(Types.GET_LIST_CREDIT_BANDS_REQUEST, getListCreditBandsRequest);
  yield takeLatest(Types.CREATE_CREDIT_BAND_REQUEST, createCreditBandRequest);
}
