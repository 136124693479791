import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';
import { exhibServiceAPI } from './apiExhibitorConfig';
import { moviesServiceAPI } from './apiMoviesConfig';

export const getListTicketTypes = async () => {
  const response = await adminAPI.get(ENDPOINT.TICKET_TYPES, {
    headers: authHeader()
  });

  return response;
};

export const updateTicketType = async ({
  ticketTypeID,
  providerTheaterChainId,
  providerTheaterId,
  price,
  credits,
  statusValue,
  ticketBucketValue,
}) => {
  // TODO : Add these to the request later
  const response = await exhibServiceAPI.put(`${ENDPOINT.TICKET_TYPES_BLACK}/${ticketTypeID}/update`, {
    providerTheaterChainId,
    providerTheaterId,
    price,
    credits,
    status: statusValue,
    ticketBucket: ticketBucketValue
  },
  { headers: authHeader()
  });

  return response;
};

export const getListTicketTypesForProviderTheater = async ({ providerTheaterId }) => {
  const response = await adminAPI.get(
    `${ENDPOINT.TICKET_TYPES}/${encodeURIComponent(providerTheaterId)}${ENDPOINT.THEATER_CHAIN}`,
    { headers: authHeader() }
  );

  return response;
};

export const importTicketTypes = async ({ cinemaChainId, siteIds }) => {
  const response = await moviesServiceAPI.post(
    `${ENDPOINT.TICKET_TYPE}`,
    { cinemaChainId, siteIds },
    { headers: authHeader() }
  );

  return response;
};
