import { createReducer } from 'reduxsauce';
import Immutable, { merge } from 'seamless-immutable';

import { Types } from '../actions/campaignsAction';

export const INITIAL_STATE = Immutable({
  isLoading: false,
  isGetCampaignLoading: false,
  isGetCampaignUsersLoading: false,
  isAddUserToCampaignLoading: false,
  isRemoveUserFromCampaignLoading: false,
  campaigns: [],
  campaign: {},
  campaignUsers: [],
  error: null,
});

const getListCampaignsRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null
  });
};

const getListCampaignsSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    campaigns: data
  });
};

const getListCampaignsFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    campaigns: []
  });
};

const getCampaignRequest = (state) => {
  return merge(state, {
    isGetCampaignLoading: true,
    error: null,
    campaign: {},
  });
};

const getCampaignSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isGetCampaignLoading: false,
    campaign: data
  });
};

const getCampaignFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isGetCampaignLoading: false,
    error,
    campaign: {}
  });
};

const createCampaignRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null,
    campaign: {},
  });
};

const createCampaignSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    campaign: data
  });
};

const createCampaignFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
    campaign: {}
  });
};

const updateCampaignRequest = (state) => {
  return merge(state, {
    isLoading: true,
    error: null,
    campaign: {},
  });
};

const updateCampaignSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isLoading: false,
    campaign: data
  });
};

const updateCampaignFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isLoading: false,
    error,
  });
};

const getListCampaignUsersRequest = (state) => {
  return merge(state, {
    isGetCampaignUsersLoading: true,
    error: null,
    campaignUsers: [],
  });
};

const getListCampaignUsersSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isGetCampaignUsersLoading: false,
    campaignUsers: data
  });
};

const getListCampaignUsersFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isGetCampaignUsersLoading: false,
    error,
  });
};

const addUserToCampaignRequest = (state) => {
  return merge(state, {
    isAddUserToCampaignLoading: true,
    error: null,
  });
};

const addUserToCampaignSuccess = (state) => {
  return merge(state, {
    isAddUserToCampaignLoading: false,
  });
};

const addUserToCampaignFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isAddUserToCampaignLoading: false,
    error,
  });
};

const removeUserFromCampaignRequest = (state) => {
  return merge(state, {
    isRemoveUserFromCampaignLoading: true,
    error: null,
  });
};

const removeUserFromCampaignSuccess = (state, action) => {
  const { data } = action;

  return merge(state, {
    isRemoveUserFromCampaignLoading: false,
    campaignUsers: state.campaignUsers.filter((user) => user.id !== data.id)
  });
};

const removeUserFromCampaignFailed = (state, action) => {
  const { error } = action;

  return merge(state, {
    isRemoveUserFromCampaignLoading: false,
    error,
  });
};

export const HANDLERS = {
  [Types.GET_LIST_CAMPAIGNS_REQUEST]: getListCampaignsRequest,
  [Types.GET_LIST_CAMPAIGNS_SUCCESS]: getListCampaignsSuccess,
  [Types.GET_LIST_CAMPAIGNS_FAILED]: getListCampaignsFailed,

  [Types.GET_CAMPAIGN_REQUEST]: getCampaignRequest,
  [Types.GET_CAMPAIGN_SUCCESS]: getCampaignSuccess,
  [Types.GET_CAMPAIGN_FAILED]: getCampaignFailed,

  [Types.CREATE_CAMPAIGN_REQUEST]: createCampaignRequest,
  [Types.CREATE_CAMPAIGN_SUCCESS]: createCampaignSuccess,
  [Types.CREATE_CAMPAIGN_FAILED]: createCampaignFailed,

  [Types.UPDATE_CAMPAIGN_REQUEST]: updateCampaignRequest,
  [Types.UPDATE_CAMPAIGN_SUCCESS]: updateCampaignSuccess,
  [Types.UPDATE_CAMPAIGN_FAILED]: updateCampaignFailed,

  [Types.GET_LIST_CAMPAIGN_USERS_REQUEST]: getListCampaignUsersRequest,
  [Types.GET_LIST_CAMPAIGN_USERS_SUCCESS]: getListCampaignUsersSuccess,
  [Types.GET_LIST_CAMPAIGN_USERS_FAILED]: getListCampaignUsersFailed,

  [Types.ADD_USER_TO_CAMPAIGN_REQUEST]: addUserToCampaignRequest,
  [Types.ADD_USER_TO_CAMPAIGN_SUCCESS]: addUserToCampaignSuccess,
  [Types.ADD_USER_TO_CAMPAIGN_FAILED]: addUserToCampaignFailed,

  [Types.REMOVE_USER_FROM_CAMPAIGN_REQUEST]: removeUserFromCampaignRequest,
  [Types.REMOVE_USER_FROM_CAMPAIGN_SUCCESS]: removeUserFromCampaignSuccess,
  [Types.REMOVE_USER_FROM_CAMPAIGN_FAILED]: removeUserFromCampaignFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
